import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  

const AddNote = ({ onClose, open, reloadNotes=null, currentFolder, classId,openedFolderId }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [noteName, setNoteName] = React.useState('');
  const [noteFileId, setNoteFileId] = React.useState('');
  const [noteFileURL, setNoteFileURL] = React.useState('');
  const [documentType, setDocumentType] = React.useState("url");

  const handleRadioChange = (event) => {
    setNoteFileId('');
    setNoteFileURL('');
    setDocumentType(event.target.value);
  };

  const changeRadioValue = (val) => {
    if(val=="url"){
      setNoteFileId('');
    }else{
      setNoteFileURL('');
    }
    setDocumentType(val);
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
     
      setErrors({});
      let formData=new FormData();
      let fileURLType="";
      let fileURL="";
      if(documentType=="local"){
        formData=new FormData(e.target);
      }else{
        let regExpVideo = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        let regExpPdf = /^.*\.([pP][dD][fF])$/;
        let matchVideo = noteFileURL.match(regExpVideo);
        let matchPdf = noteFileURL.match(regExpPdf);
        if(matchVideo){
          fileURLType="VIDEO";
          fileURL='https://www.youtube.com/embed/' + matchVideo[1] + '?autoplay=0&enablejsapi=1';
        }else if(matchPdf){
          fileURLType="DOCUMENT";
          fileURL = noteFileURL.indexOf('http:') == 0 ? noteFileURL.replace('http', 'https') : noteFileURL;
        }else{
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_INCORRECT_LINK_ERROR'));
          return false;
        }
      }

      let folderIdData;
      if(openedFolderId){
        folderIdData=currentFolder.id;
      }
      if(!openedFolderId){
        folderIdData='';        
      }

      const headers = {
        headers: {
          "jot-token": user.token,
          "noteName": noteName,
          "noteType": fileURLType,
          "fileName": noteFileId.replace(/^.*[\\\/]/, ''),
          "fileUrl": fileURL,
          "classId": classId,
          "folderId": folderIdData,
          'content-type': 'multipart/form-data'
        },
        timeout: 20000,
        validateStatus: (status) => status >= 200 && status < 300
      }
      axios.post(API_URL+'note/file', formData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setNoteName('');
        setNoteFileId('');
        setNoteFileURL('');
        // setClassId('');
        if(openedFolderId){
          reloadNotes(currentFolder.id,null);
        }
        if(!openedFolderId){
          reloadNotes(null,currentFolder.id);
        }
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        if(error.message.includes("507")){
          setServerError(t('NOTE_ADD_ERROR_DISK_QUOTA_1' + " " + t('NOTE_ADD_ERROR_DISK_QUOTA_2')));
        }else if(error.message.includes("415")){
          setServerError(t('NOTE_ADD_INCORRECT_FORMAT'));
        }else if(error.message.includes("503")){
          setServerError(t('NOTE_ADD_SERVICE_UNAVAILABLE_ERROR'));
        }else if(error.message.includes("500")){
          setServerError(t('NOTE_ADD_INTERNAL_SERVER_ERROR'));
        }else{
          setServerError(t('MSG_PROCESS_ERROR'));
        }
      });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(noteName==''){
      formIsValid = false;
      errorsData["noteName"] = t('MSG_EMPTY_NAME');
    }

    if(noteName!=''&&noteName.length<3){
      formIsValid = false;
      errorsData["noteName"] = t('MSG_SHORT_NAME');
    }

    if(noteName!=''&&noteName.length>50){
      formIsValid = false;
      errorsData["noteName"] = t('MSG_LONG_NAME');
    }

    if(noteFileId=='' && documentType=="local"){
      formIsValid = false;
      errorsData["noteFileId"] = t('MSG_EMPTY_FILE');
    }

    if(noteFileURL=='' && documentType=="url"){
      formIsValid = false;
      errorsData["noteFileURL"] = t('MSG_EMPTY_LINK');
    }

    let reg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    if(noteFileURL!='' && reg.test(noteFileURL)==false && documentType=="url"){
      formIsValid = false;
      errorsData["noteFileURL"] = t('MSG_INVALID_LINK');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('NOTE_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={noteName}
                onChange={(e)=>setNoteName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="noteName"
                placeholder={t('NOTE_NAME')}
                name="noteName"
                autoComplete="noteName"
                error={errors["noteName"]}
                helperText={errors["noteName"]}
                autoFocus
                className="mb-4"
              />
            <FormControl className="mb-2" fullWidth component="fieldset">
              <RadioGroup aria-label="noteFileId" name="noteFileId" value={documentType} onChange={handleRadioChange}>
                <FormControlLabel value="local" control={<Radio />} label={
                  <div>
                    <input
                    id="noteFileId"
                    accept="application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/*"
                    type="file"
                    name="noteFileId"
                    value={noteFileId}
                    onChange={(e)=>{setNoteFileId(e.target.value); changeRadioValue("local")}}
                    style={{ display: "none" }}
                    />
                    <label className="w100" htmlFor="noteFileId">
                      <Button fullWidth variant="contained" className="yellowButton" component="span">
                        {noteFileId?(noteFileId.replace(/^.*[\\\/]/, '')):t('NOTE_CHOOSE_FILE')}
                      </Button>
                    </label>
                    <FormHelperText className="ml-3" error>{errors["noteFileId"]}</FormHelperText>
                  </div>
                } />
                <div className="flexCenter">OR</div>
                <FormControlLabel value="url" control={<Radio />} label={
                  <TextField
                  fullWidth
                  value={noteFileURL}
                  onChange={(e)=>{setNoteFileURL(e.target.value); changeRadioValue("url")}}
                  variant="outlined"
                  margin="normal"
                  required
                  id="noteFileURL"
                  placeholder={t('YOUTUBE_OR_PDF_LINK')}
                  name="noteFileURL"
                  autoComplete="noteFileURL"
                  error={errors["noteFileURL"]}
                  helperText={errors["noteFileURL"]}
                />
                } />
              </RadioGroup>
            </FormControl>
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('NOTE_ADD')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default AddNote;