import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../store/StateProvider'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../assets/styles/materialStyles'
import DateShow from '../../commonComponents/DateShow'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as moment from 'moment'
import UserAvatar from '../User/UserAvatar'
import axios from 'axios'
import { API_URL, axiosConfig } from '../../api/index'

const ChatBody = ({
  selectedChat,
  chatDetailData,
  receivedMessage,
  setChatDetailData,
  page,
  setPage,
}) => {
  const classes = useStyles()
  const [{ user }] = useStateValue()
  const { t } = useTranslation()
  const headers = axiosConfig(user.token)
  const [{ loading }, dispatchSetLoading] = useStateValue()
  const [{ snack }, dispatchSnack] = useStateValue()
  const [totalData, setTotalData] = useState('')
  const [{ sctn }] = useStateValue()
  const size = 25

  const scrollToBottom = (id) => {
    if (id) {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
        })
        //  document.getElementById(id).scrollTop -= 1
      }
    }
  }

  useEffect(() => {
    if (
      selectedChat.id &&
      sctn &&
      sctn.connected &&
      receivedMessage &&
      receivedMessage.body &&
      receivedMessage.headers
    ) {
      let body = JSON.parse(receivedMessage.body)
      let group = receivedMessage.headers.destination
      let str = group.replace('/topic/chatGroup', '')

      if (body.toUser) {
        if (
          (body.toUser.id == user.id && body.fromUser.id == selectedChat.id) ||
          (body.fromUser.id == user.id && body.toUser.id == selectedChat.id)
        ) {
          setChatDetailData((chatDetailData) => [body, ...chatDetailData])
        }
      } else if (selectedChat.group) {
        if (str == selectedChat.id) {
          setChatDetailData((chatDetailData) => [body, ...chatDetailData])
        }
      }
      debugger
      if (str == selectedChat.id && chatDetailData[0]) {
        scrollToBottom(chatDetailData[0].id)
      }
    }
  }, [receivedMessage])

  useEffect(() => {
    selectedChat.countNewMessages = 0
    fetchChatData()
    return () => resetChatPageCount()
  }, [selectedChat])

  const increasePageCount = () => {
    setPage(page + 1)
  }

  const resetChatPageCount = () => {
    setPage(0)
  }

  // useEffect(() => {
  //   setChatPage(0);
  // }, [selectedChat]);

  const fetchChatData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true,
    })
    let apiUrl = ''
    if (!selectedChat.group) {
      apiUrl =
        API_URL +
        'chat/user/' +
        selectedChat.id +
        `?page=${page}&size=${size}&sort=createdDate,desc`
    } else {
      apiUrl =
        API_URL +
        'chat/group/' +
        selectedChat.id +
        '/message' +
        `?page=${page}&size=${size}&sort=createdDate,desc`
    }
    axios
      .get(apiUrl, headers)
      .then((response) => {
        setTotalData(response.data.totalElements)
        if (response.data.content) {
          let nonReversedData = response.data.content
          const newArr = [...chatDetailData, ...nonReversedData]
          setChatDetailData(newArr)
          //setChatDetailData(response.data.content)
          increasePageCount(page)
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false,
          })
        }
      })
      .catch((errors) => {
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR'),
          },
        })

        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false,
        })
        console.log(errors)
      })
  }

  return (
    // <div>
    //   <h1>{page}</h1>
    //   <h1>{reset}</h1>
    //   <button onClick={fetchChatData}>Click here</button>
    // </div>
    <ul>
      <InfiniteScroll
        scrollableTarget='chatDataId'
        dataLength={chatDetailData.length}
        next={fetchChatData}
        hasMore={chatDetailData.length < totalData ? true : false}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
        inverse={true}
        loader={
          <h6
            style={{
              color: '#435f7a !important',
            }}
          >
            Loading...
          </h6>
        }
        endMessage={
          <p
            style={{
              textAlign: 'center',
              color: '#435f7a',
            }}
          >
            {/* <b>Yay! You have seen it all</b> */}
          </p>
        }
      >
        {/* <div className="ul-replace"> */}

        {chatDetailData.length > 0 &&
          chatDetailData.map((chatMessage, i) => {
            console.log(`chatDetailData[i + 1], i`, chatDetailData[i + 1], i)
            return (
              <>
                <li key={chatMessage.createdDate} id={chatMessage.id}>
                  {/* date of chat start */}
                  {chatDetailData[i + 1] &&
                  chatDetailData[i + 1].createdDate ? (
                    <DateShow
                      currentDate={chatMessage.createdDate}
                      previousDate={chatDetailData[i + 1].createdDate}
                    />
                  ) : (
                    <DateShow
                      currentDate={chatMessage.createdDate}
                      previousDate={'0000-00-00T00:00:00.000+00:00'}
                    />
                  )}

                  {chatMessage &&
                  chatMessage.fromUser &&
                  chatMessage.fromUser.id != user.id ? (
                    <>
                      <li className='sent' key={chatMessage.id}>
                        <div className='img'>
                          <UserAvatar noModal profile={chatMessage.fromUser} />
                        </div>
                        <p>
                          {' '}
                          {/* chat message start */}
                          {chatMessage && chatMessage.message}
                          {/* chat message end */}
                          <br />
                          {/* time of chat start */}
                          <small className='chat-time italic light'>
                            {moment(chatMessage.createdDate).format(
                              t('TIME_POSTED')
                            )}
                          </small>
                          {/* time of chat end */}
                        </p>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className='replies' key={chatMessage.id}>
                        <div className='img'>
                          <UserAvatar noModal profile={chatMessage.fromUser} />
                        </div>
                        <p>
                          {/* chat message start */}
                          {chatMessage && chatMessage.message}
                          {/* chat message end */}
                          <br />
                          {/* time of chat start */}
                          <small className='chat-time italic light'>
                            {moment(chatMessage.createdDate).format(
                              t('TIME_POSTED')
                            )}
                          </small>
                          {/* time of chat end */}
                        </p>
                      </li>
                    </>
                  )}
                </li>
              </>
            )
          })}
        {/* </div> */}
      </InfiniteScroll>
    </ul>
  )
}

export default ChatBody
