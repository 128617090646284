import React, { useState, useEffect, useContext } from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, makeStyles, Container, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import Footer from '../Includes/Footer/Footer';
import Loader from '../Tools/Loader';
import ForgotPassword from './ForgotPassword';
import { useTranslation } from "react-i18next";
import { useStateValue } from '../../store/StateProvider';
import { useHistory } from "react-router-dom";
import * as moment from 'moment';
import {SIGNIN_URL} from '../../api/index';
import axios from 'axios';
import SnackbarAlert from '../Tools/SnackbarAlert';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({showRegister, page, setPage}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  //Set States
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [{user}, dispatch] = useStateValue();
  const [serverError, setServerError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [{loading}, dispatchSetLoading] = useStateValue();

  let history = useHistory();
  
  const handleLogin = (e) => {
    e.preventDefault();
    if(validateLogin()){
      setErrors({});
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      var config={
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        validateStatus: (status) => status >= 200 && status < 300
      };
      axios.post(SIGNIN_URL+'?offset='+encodeURIComponent(moment().format('ZZ')), {email, password}, config)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        if(!response.data.verified){
          setServerError(t('MSG_USER_EXIST_NOT_VERIFIED'))
          return false;
        }else{
          setServerError('');
          localStorage.setItem('user', JSON.stringify(response.data));
          dispatch({
            type: 'LOGIN',
            user: response.data
          });
          history.push('./dashboard');
        }
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
          setServerError(t('MSG_INVALID_EMAIL_PASSWORD'));
      });
    }
  }
  
  const validateLogin = () => {
    let errorsData = {};
    let formIsValid = true;

    if(email==''){
      formIsValid = false;
      errorsData["email"] = t('MSG_EMPTY_EMAIL');
    }

    if(email!=''){
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(reg.test(email.toLowerCase())==false){
        formIsValid = false;
        errorsData["email"] = t('MSG_INVALID_EMAIL');
      }
    }

    if(password==''){
      formIsValid = false;
      errorsData["password"] = t('MSG_EMPTY_PASSWORD');
    }
    setErrors(errorsData);
    return formIsValid;
  }

  const handleForgotPasswordOpen = () => {
    setForgotPassword(true)
  }
  const handleForgotPasswordClose = () => {
    setForgotPassword(false)
  }

  const responseFacebook =(response) =>{
  //   console.log('response fb');
  //   console.log(response);
  //   console.log('response fb');
  }

  const responseGoogle = (response) =>{
    // console.log(response);
  }

  return (
    <div>
      {serverError!=""?
      <Alert severity="error" className={classes.errorAlert}>
        {serverError}
      </Alert>
      :null}
      <form className={classes.form} noValidate onSubmit={handleLogin}>
        <TextField
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('EMAIL_ADDRESS')}
          placeholder={t('EMAIL_ADDRESS')}
          name="email"
          autoComplete="email"
          error={errors["email"]}
          helperText={errors["email"]}
          autoFocus
        />
        <TextField
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('PASSWORD')}
          placeholder={t('PASSWORD')}
          type="password"
          id="password"
          autoComplete="current-password"
          error={errors["password"]}
          helperText={errors["password"]}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t('SIGN_IN')}
        </Button>
        {/* <Grid container>
          <Grid item xs={12} lg={6}>
          <GoogleLogin
    clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
          </Grid>
          <Grid item xs={12} lg={6}>
          <FacebookLogin style={{height:'30px'}}
        appId="145528850434206"
        autoLoad={false}
        callback={responseFacebook} />
          </Grid>
        </Grid> */}
       
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Link href="#" variant="body2" onClick={handleForgotPasswordOpen}>
              {t('FORGOT_PASSWORD?')}
            </Link>
            <ForgotPassword open={forgotPassword} onClose={handleForgotPasswordClose}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link href="/registration" onClick={showRegister} variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Footer />
        </Box>
      </form>
      <SnackbarAlert />
      {loading ?
      <Loader active={true} />
      :
      null}
    </div>
  );
}

export default Login;