import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import "../../assets/styles/App.scss";
import {
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,Tooltip,IconButton
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPen,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import axios from "axios";
import { API_URL,axiosConfigWithoutToken, axiosConfig } from "../../api/index";
import { useStateValue } from "../../store/StateProvider";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../assets/styles/materialStyles";
import Iframe from "react-iframe";
import ReactPlayer from "react-player";
import DeleteNote from "./DeleteNote";
import EditNote from "./EditNote";
import Draggable from 'react-draggable';


/* for making draggable start */
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
/* for making draggable end */


const Note = ({ styles,data,openedFolderId,currentFolder,reloadNotes,classId,parentFolder,rootClassId }) => {
  const classes = useStyles();
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{ snack }, dispatchSnack] = useStateValue();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [deleteNoteOpen,setDeleteNoteOpen]= useState(false);
  const [editNoteOpen,setEditNoteOpen]= useState(false);
  const [iframeOpen, setiframeOpen] = useState(false);
  const headers = axiosConfigWithoutToken();
  const[noteToEditId,setNoteToEditId] = useState('');
  const[noteToEditName,setNoteToEditName] = useState('');
  const [editNoteData,setEditNoteData] = useState([]);
  const[folderNameToEdit,setFolderNameToEdit] = useState('');
  const [folderIdToEdit,setFolderIdToEdit] = useState('');


 
  const windowOpen = (id) => {
    setiframeOpen(true);
  };

  const windowClose = () => {
    setiframeOpen(false);
  };

  const handleDeleteModalOpen = () =>{
    setDeleteNoteOpen(true);
  }
  const handleDeleteModalClose = () =>{
    setDeleteNoteOpen(false);
  }

  const handleEditNoteModalOpen = (name,id,data) =>{
 
    // if inside a folder then show opened folder name in edit note
    if(openedFolderId){
      setFolderNameToEdit(data.folder.name);
      setFolderIdToEdit(data.folder.id);
    }
    //if at root then show root as opened folder name in edit note
    if(!openedFolderId){
      setFolderNameToEdit('root')
    }
    setNoteToEditName(name)
    setNoteToEditId(id);
    setEditNoteOpen(true);
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios
    .get(API_URL + "class/"+rootClassId+"?token="+ user.token, headers)
    .then((response) => {
      setEditNoteData(response.data);
    }).catch((errors)=>{
  
    })
  }
  const handleEditNoteModalClose = () =>{
    setEditNoteOpen(false);
  }

  return (
    <>
      <Grid item xs={6} sm={4} className="tempNoteStyle" >
        <Paper className={classes.paper}>
          <div className="noteContainer">
            <div className="note-heading">{data.noteName}</div>
            <div className="note-content">
              {data.type == "DOCUMENT" && data.fileThumbnailPath && (
                <>
                  <Link onClick={() => windowOpen(data.id)}>
                    <img
                      className="img-thumbnail"
                      src={data.fileThumbnailPath}
                      alt={data.noteName}
                      className="noteFileImage"
                    />
                  </Link>
                 
                  <Dialog
                    fullScreen 
                    className="forWidth"
                    contentStyle={{
                      width: "80%",
                      maxWidth: "none",
                    }}
                    onClose={windowClose}
                    open={iframeOpen}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title" >
                   
                     <DialogActions className="draggable">
                    <Tooltip title={t('CLOSE_NOTE')}  arrow>
                    <IconButton className={"MyCustomButton"} onClick={()=>windowClose()}>
                      <CloseIcon />
                    </IconButton>
                    </Tooltip>
                  </DialogActions>
                
                    <DialogContent className="DialogIframe">
                      <Iframe
                        url={
                          "https://rc.jot.academy/flowpaper/#/view-note/" +
                          data.id +
                          "/" +
                          user.token
                        }
                        width="100%"
                        id="myId"
                        className="iFrameDesign"
                        display="initial"
                        position="relative"
                      />
                    </DialogContent>
                  </Dialog>
                </>
              )}
              {data.type == "VIDEO" && data.fileUrl && (
                <Iframe
                  url={data.fileUrl}
                  width="100%"
                  height="auto"
                  className="classBoardVideo"
                />
              )}
              {data.type == "VIDEO" && !data.fileUrl && (
                <ReactPlayer
                  url={data.filePath}
                  controls={true}
                  width="100%"
                  height="auto"
                  className="classBoardVideo"
                />
              )}
            </div>
            <div className="note-details">
              <div className="note-creationDate light italic">
                <FontAwesomeIcon className="clockIcon" icon={faClock} />{" "}
                {moment(data.creationDate).format(t("DATE_POSTED"))}
              </div>
              <div className="note-createdBy">
                {t("CREATED_BY")}
                {data.creator.id == user.id ? (
                  <strong>{t("YOU")}</strong>
                ) : (
                  <strong>
                    <a href="#">
                      {data.creator.firstName + " " + data.creator.lastName}
                    </a>
                  </strong>
                )}
              </div>
              <div>
                {/* edit delete button start by Ammar */}
                <div className="text-center">
                   {/*edit  delete note option only avilable to creator  start */}
                {(data.creator.id===user.id) && (user.role==="USER") &&
                <>
                <Tooltip title={t('EDIT_NOTE')}  arrow>
                        <IconButton
                            className=" editNoteIcon"
                            onClick={() =>
                              handleEditNoteModalOpen(data.noteName,data.id,data)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                </Tooltip>
                <Tooltip title={t('DELETE_NOTE')}  arrow>
                        <IconButton
                            className=" trashNoteIcon"
                            onClick={() =>
                            handleDeleteModalOpen()
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                </Tooltip>
                </>
                }
                {/* edit delete note option only avilable to creator  end */}
                {/*edit  delete note option only avilable to admin  start */}
                {(user.role!=="USER") &&
                <>
                <Tooltip title={t('EDIT_NOTE')}  arrow>
                        <IconButton
                            className=" editNoteIcon"
                            onClick={() =>
                              handleEditNoteModalOpen(data.noteName,data.id,data)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                </Tooltip>
                <Tooltip title={t('DELETE_NOTE')}  arrow>
                        <IconButton
                            className=" trashNoteIcon"
                            onClick={() =>
                            handleDeleteModalOpen()
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                </Tooltip>
                </>
                }
                {/* edit delete note option only avilable to admin  end */}

                </div>
                {/* edit delete button end by Ammar */}
                {/* delete Note start */}
                <DeleteNote 
                 open={deleteNoteOpen}
                 onClose={handleDeleteModalClose}
                 noteId={data.id}
                 currentFolder={currentFolder}
                 //to check root
                 openedFolderId={openedFolderId}
                 reloadNotes={reloadNotes} 
                 classId={classId} 
                />
                {/* Delete Note logic end
                {/* Edit Note start*/}
                 <EditNote
                open={editNoteOpen}
                onClose={handleEditNoteModalClose}
                folderNameToEdit={folderNameToEdit}
                folderIdToEdit={folderIdToEdit}
                noteId={data.id}
                noteToEditId={noteToEditId}
                noteToEditName={noteToEditName}
                parentFolder={parentFolder}
                classId={classId}
                openedFolderId={openedFolderId}
                reloadNotes={reloadNotes}
                editNoteData={editNoteData}
                
                />
                {/* Edit Note end */}
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </>
  );
};
export default Note;
