import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import ClassAssignments from './ClassAssignments';
import { useTranslation } from "react-i18next";

const Assignments = () => {
  const classes = useStyles();
  const [{loading}] = useStateValue();
  const [{snack}] = useStateValue();
  const [{title}, dispatchSetTitle] = useStateValue();
  const { t } = useTranslation();

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('ASSIGNMENTS')
    });
  }, []);

  return (
    <Grid item xs={12}>
      <Switch>
        <Route exact path={'*/assignments/:assignmentId?'} component={ClassAssignments}/>
      </Switch>
      <SnackbarAlert />
      {loading ?
      <Loader active={true} />
      :
      null}
    </Grid>
  );
}

export default Assignments;