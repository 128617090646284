import React, {useEffect, useState, useLayoutEffect} from 'react';
import { Paper, Button, Grid, Accordion, AccordionSummary, Typography, Card,Dialog, DialogTitle, DialogContent,DialogActions } from '@material-ui/core';
import axios from 'axios';
import {API_URL, axiosConfigWithoutToken, axiosConfig} from '../../api/index';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt,faShareAlt,faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import {
  useParams, useHistory, Link
} from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Classboard from './ClassBoard';
import ClassStudents from './ClassStudents';
import Assignment from '../Assignments/Assignment';
import Note from '../Notes/Note';
import AddClass from './AddClass';
//for invite button
import InviteStudents from "./InviteStudents";
import InviteProfessor from "./InviteProfessor"



const EditClass = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [data, setData]=useState();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  //exit class setter getter
  const [confirmExit, setConfirmExit] = useState(false);
  //exit class setter getter
  //invite logic
  const [inviteProfessorOpen, setInviteProfessorOpen] = useState(false);
  const [inviteStudentsOpen, setInviteStudentsOpen] = useState(false);


  let { classId } = useParams();
  let history = useHistory();
  const goBack=()=>{
    history.goBack();
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //exit class 
  const handleExitClassOpen = () => {
    setConfirmExit(true);
  }

  const handleExitClassClose = () => {
    setConfirmExit(false);
  }

  
  const exitClass = () => {
    
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.delete(API_URL+'class/'+data.id, axiosConfig(user.token))
    .then(()=>{
      setConfirmExit(false);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_EXIT_SUCCESS')
        }
      });
      history.goBack();
      // reloadClasses();
    })
    .catch(()=>{
      setConfirmExit(false);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_ERROR')
        }
      });
    });
  }
//exit class
//invite modal
const handleInviteProfessorOpen = () => {
  setInviteProfessorOpen(true);
};

const handleInviteProfessorClose = () => {
  setInviteProfessorOpen(false);
};

const handleInviteStudentsOpen = () => {
  setInviteStudentsOpen(true);
};

const handleInviteStudentsClose = () => {
  setInviteStudentsOpen(false);
};
// invite modal

  useEffect(()=>{
    fetchClass();
  }, []);


  const fetchClass=()=>{
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    const headers=axiosConfigWithoutToken();
    axios.get(API_URL + 'class/'+classId+'?token='+user.token, headers)
    .then((response) => {
      setData(response.data);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      })
    })
    .catch(errors => {
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })      
  }
  return (
    <Grid item xs={12}>
    {/* <Grid item xs={12} ref={editDiv}> */}
      <Paper className={classes.paper}>
        <Grid className="mb-3" item xs={12} sm={3}>
          <div>
            <Button onClick={goBack}  variant="contained" className="yellowButton">{t('GO_BACK_ONLY')} </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <div className="header">
              <Typography variant="h6">
                {t('CLASS_EDIT')} {(data && data.name)?data.name:""}
              </Typography>
            </div>
            <div className="contentContainer">
              <Accordion className="mb-3" defaultExpanded={true}>
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="classBoard"
                  >
                    <Link to="#"><Typography variant="h6">{t('CLASS_BOARD')}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer" >
                    <Classboard classId={classId} />
                  </div>
                </Grid>
              </Accordion>
              <Accordion className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="classStudents"
                  >
                    <Link to="#"><Typography variant="h6">{t('STUDENTS')}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                    <ClassStudents data={data} />
                  </div>
                </Grid>
              </Accordion>
              <Accordion className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="classAssignments"
                  >
                    <Link to="#"><Typography variant="h6">{t('ASSIGNMENTS')}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                    <Grid container spacing={3}>
                      {data && data.assignments.map((assignment, i)=>(
                        <Assignment data={assignment} classProfessor={data.professor} />
                      ))}
                    </Grid>
                  </div>
                </Grid>
              </Accordion>
              <Accordion className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="classNotes"
                  >
                    <Link to="#"><Typography variant="h6">{t('NOTES')}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                    <Grid container spacing={3}>
                      {data && data.notes.map((note, i)=>(
                        <Note data={note} />
                      ))}
                    </Grid>
                  </div>
                </Grid>
              </Accordion>
              <Accordion className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="classSchedule"
                  >
                    <Link to="#"><Typography variant="h6">{t('CLASS_EDIT_SCHEDULE')}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                    <AddClass reloadClasses={fetchClass} mode="edit" data={data} />
                  </div>
                </Grid>
              </Accordion>
            </div>

            {/* //buttons */}
          <div className="contentContainer bb editButtons">
          <Grid container spacing={2}>
              <Grid container item xs={12} md={12} lg={6}  justify="center" alignItems="center">
                <Button onClick={handleInviteStudentsOpen} disabled={user.semester.past} variant="contained" className="orangeButton"><FontAwesomeIcon className="buttonIcon" icon={faShareAlt} /> {t('INVITE_STUDENTS_JOIN')}</Button>
                <InviteStudents open={inviteStudentsOpen} onClose={handleInviteStudentsClose} reloadClasses={fetchClass} data={data} />
              </Grid>

              <Grid container item xs={12} md={12} lg={6} justify="center" alignItems="center">
                <Button onClick={handleExitClassOpen} disabled={user.semester.past} variant="contained" className="redButton"><FontAwesomeIcon className="buttonIcon" icon={faSignOutAlt} /> {t('CLASS_EXIT')}</Button>
                <Dialog open={confirmExit} aria-labelledby="class-exit-confirmation">
                  <DialogTitle>{t('CLASS_EXIT')}</DialogTitle>
                  <DialogContent>{t('MSG_EXIT_CONFIRMATION')+" "+((data && data.name)?data.name:"")}</DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleExitClassClose} color="primary">
                      {t('BTN_CANCEL')}
                    </Button>
                    <Button onClick={exitClass} color="primary">
                      {t('YES')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

          </Grid>
          </div>
          {/* buttons */}
          </Card>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default EditClass;