import React, {useState, useEffect} from 'react';
import { InputLabel, TextField, Button, Dialog, DialogTitle, DialogContent, Card, Typography, FormControl, Select, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';

const AddGrade = ({ onClose, reloadGrades, data={}, grades={}, editData={}, mode="add" }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [assignment, setAssignment] = useState('');
  const [totalGrades, setTotalGrades] = useState('');
  const [totalScore, setTotalScore] = useState('');

  useEffect(()=>{
    if(mode=="edit"){
      setAssignment(editData.id);
      setTotalGrades(editData.totalGrades);
      setTotalScore(editData.totalScore);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
        let grData={
          classId: data.id,
          entityId: assignment,
          totalGrades: totalGrades,
          totalScore: totalScore
        }
        axios.put(API_URL+'grades/assignment', grData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          setAssignment('');
          setTotalGrades('');
          setTotalScore('');
          reloadGrades();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
          console.log(error);
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(assignment==''||assignment==null||assignment==undefined){
      formIsValid = false;
      errorsData["assignment"] = t('MSG_EMPTY_ASSIGNMENT');
    }

    if(totalGrades==''){
      formIsValid = false;
      errorsData["totalGrades"] = t('GRADES_PERC_TOTAL_GRADE_REQUIRED');
    }

    if(totalGrades!=''&&totalGrades.length>10){
      formIsValid = false;
      errorsData["totalGrades"] = t('GRADES_PERC_TOTAL_GRADE_LONG');
    }

    if(totalGrades<1){
      formIsValid = false;
      errorsData["totalGrades"] = t('GRADES_PERC_TOTAL_GRADE_MORE');
    }

    if(totalGrades>100){
      formIsValid = false;
      errorsData["totalGrades"] = t('GRADES_PERC_TOTAL_GRADE_LESS');
    }

    let totalGradesTotal=parseFloat(0);
    grades.entities.map((gr)=>{
      if((gr.totalGrades && gr.totalGrades>0)&&(gr.id!=editData.id))
      totalGradesTotal+=parseFloat(gr.totalGrades);
    });
    if(((parseFloat(totalGradesTotal)+parseFloat(totalGrades)))>100){
      formIsValid = false;
      errorsData["totalGrades"] = t('GRADES_PERC_TOTAL_GRADE_LIMIT');
    }

    if(totalScore==''){
      formIsValid = false;
      errorsData["totalScore"] = t('GRADES_PERC_TOTAL_SCORE_REQUIRED');
    }

    if(totalScore!=''&&totalScore.length>10){
      formIsValid = false;
      errorsData["totalScore"] = t('GRADES_PERC_TOTAL_SCORE_LONG');
    }

    if(totalScore<1){
      formIsValid = false;
      errorsData["totalScore"] = t('GRADES_PERC_TOTAL_SCORE_MORE');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  const handleGrTitle = (event) => {
    setAssignment(event.target.value);
  };

  return (
    <div>
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Card className="class">
                <div className="card-header">
                    {t('GRADES_RESULTS')}
                </div>
                <div className="contentContainer">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="assignment">{t('GRADES_ASSIGNMENT_QUIZ')}</InputLabel>
                            <Select
                                label={t('GRADES_ASSIGNMENT_QUIZ')}
                                native
                                value={assignment}
                                onChange={handleGrTitle}
                                required
                                id="assignment"
                                name="assignment"
                                autoComplete="assignment"
                                error={errors["assignment"]}
                                className="mb-1"
                                >
                                  <option value="">{t('GRADES_ASSIGNMENT_QUIZ')}</option>
                                  {
                                  data && data.assignments && grades.entities &&
                                  (
                                      data.assignments.map((assignment, i) =>
                                      (
                                        (((_.find(grades.entities, {id:assignment.id})).totalGrades!=null)&&!editData.id && editData.id!=(_.find(grades.entities, {id:assignment.id})).id && mode!="edit")?
                                        // ((grades.entities.some((entity) => 
                                        //   entity.totalGrades != null && !editData.id && editData.id!=entity.id && mode!="edit"
                                        // )))?
                                          null
                                        :
                                          <option value={assignment.id}>{assignment.name}</option>
                                      )
                                    )
                                  )
                                  }
                            </Select>
                            <FormHelperText error>{errors["assignment"]}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <TextField
                            value={totalGrades}
                            onChange={(e)=>setTotalGrades(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="totalGrades"
                            placeholder={t('GRADES_PERC_TOTAL_GRADE')}
                            label={t('GRADES_PERC_TOTAL_GRADE')}
                            name="totalGrades"
                            autoComplete="totalGrades"
                            error={errors["totalGrades"]}
                            helperText={errors["totalGrades"]}
                            className="mt-0"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <TextField
                            value={totalScore}
                            onChange={(e)=>setTotalScore(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="totalScore"
                            placeholder={t('GRADES_TOTAL_SCORE')}
                            label={t('GRADES_TOTAL_SCORE')}
                            name="totalScore"
                            autoComplete="totalScore"
                            error={errors["totalScore"]}
                            helperText={errors["totalScore"]}
                            className="mt-0"
                          />
                        </Grid>
                    </Grid>
                </div>
              </Card>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SAVE_CRITERIA')}
            </Button>
          </form>
        </div>
      </div>
);
}

export default AddGrade;