import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import ClassNotes from './ClassNotes';
import ViewNote from './ViewNote';
import { useTranslation } from "react-i18next";

const Notes = () => {
  const classes = useStyles();
  const [{loading}] = useStateValue();
  const [{snack}] = useStateValue();
  const [{title}, dispatchSetTitle] = useStateValue();
  const { t } = useTranslation();

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('NOTES')
    });
  }, []);

  return (
    <Grid item xs={12}>
      <Switch>
        <Route exact path={'*/notes/:noteId?'} component={ClassNotes}/>
        <Route path={'*/notes/view-note/:noteId'} component={ViewNote}/>
      </Switch>
      <SnackbarAlert />
      {loading ?
      <Loader active={true} />
      :
      null}
    </Grid>
  );
}

export default Notes;