import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { makeStyles, Grid, Paper, Typography, List, ListItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { AssignmentInd } from '@material-ui/icons';

const DashboardUpcoming = ({data,dataCount}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [{assignmentParam},dispatchSetAssignmentWithParam] = useStateValue();
  const[{specificTaskId},dispatchSetSpecificTaskId] = useStateValue();
  const { t } = useTranslation();
  const [upcomingClasses, setUpcomingClasses]=useState({})
  const [dueTasks, setDueTasks]=useState({})
  const [dueAssignments, setDueAssignments]=useState({})

  useEffect(()=>{
    if(data && data.upcomingClasses){
      setUpcomingClasses(data.upcomingClasses)
      setDueTasks(data.dueTasks)
      setDueAssignments(data.dueAssignments)
      
    }
  });

  



  const assignmentInd = (id=null) =>{
dispatchSetAssignmentWithParam
({
  type: 'ASSIGNMENT_ID',
  assignmentParam
  : id
});
  }

  const specificTask= (id=null) =>{
   
    dispatchSetSpecificTaskId
({
  type: 'SPECIFIC_TASK',
  specificTaskId
  : id
});
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={4}>
            <Card>
            <Paper className={(upcomingClasses.length > 0) ? classes.infoCard1 : classes.infoCard1Null}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.upcomingClassesCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
              {(dataCount.upcomingClassesCount>1)?t('HOME_SD_UPCOMING_CLASSES'):t('HOME_SD_UPCOMING_CLASS')}
            </Typography>
          </Paper>
              {/* <div className="header headerClassDue" >
              <Typography variant="h3">
                  {dataCount.upcomingClassesCount}
                </Typography>
                <Typography variant="h6">
                  {t('HOME_SD_UPCOMING_CLASSES')}
                </Typography>
              </div> */}
                {(upcomingClasses.length>0)?
                upcomingClasses.map((upcomingClass, i) => (
                  <List>
                  <ListItem key={i} divider={((i+1)!=upcomingClasses.length)} className="listItemBlock">
                  <div>
                    <div>
                    <Link className="linkBold" to={`/classes/edit-class/${upcomingClass.id}`} >
                     {upcomingClass.name}</Link>
                    </div>
                    <div>
                      <FontAwesomeIcon className="clockIcon" icon={faClock} />{Object.keys(upcomingClass.scheduling)[0]}: {upcomingClass.scheduling[Object.keys(upcomingClass.scheduling)[0]]}
                    </div>
                  </div>
                </ListItem>
                </List>
                )):''
                // <ListItem className="listItemBlock">{t('MSG_NO_UPCOMING_CLASSES')}</ListItem>
                }
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
            <Paper className={(dueAssignments.length > 0) ? classes.infoCard2 : classes.infoCard2Null}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.dueAssignmentsCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
            {(dataCount.dueAssignmentsCount>1)?t('HOME_SD_ASSIGNMENTS_DUE'):t('HOME_SD_ASSIGNMENT_DUE')}
            </Typography>
          </Paper>
              {(dueAssignments.length>0)?
              dueAssignments.map((dueAssignment, i) => (
              <List>
                <ListItem key={i} divider={((i+1)!=dueAssignments.length)} className="listItemBlock">
                  <div>
                    {/* link to assignment by class id*/}
                   <Link className="linkBold" to={"/tabs/assignments/"} onClick={()=>assignmentInd(dueAssignment.classId)}>
                     {dueAssignment.name}</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon className="clockIcon" icon={faClock} />{moment(dueAssignment.dueDate).format(t('DATE_POSTED'))}
                  </div>
                </ListItem>
              </List>
                ))
                :
               '' // <ListItem className="listItemBlock">{t('MSG_NO_ASSIGNMENTS_DUE')}</ListItem>
                }
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
            <Paper className={(dueTasks.length > 0) ? classes.infoCard3 : classes.infoCard3Null}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.dueTasksCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
            {(dataCount.dueTasksCount>1)?t('HOME_SD_TASKS_DUE'):t('HOME_SD_TASK_DUE')}
            </Typography>
          </Paper>
            
              {(dueTasks.length>0)?
              dueTasks.map((dueTask, i) => (
                <List>
                  <ListItem key={i} divider={((i+1)!=dueTasks.length)} className="listItemBlock">
                  <div>
                    <Link className="linkBold" to={"/calendar"} onClick={()=> specificTask(dueTask.id)}>{dueTask.name}</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon className="clockIcon" icon={faClock} />{moment(dueTask.endTime).format(t('DATE_POSTED'))}
                  </div>
                </ListItem>
                </List>
                ))
                :''
                // <ListItem className="listItemBlock">{t('MSG_NO_TASKS_DUE')}</ListItem>
                }
            </Card>
          </Grid>
      </Grid>
    </Paper>
  );
}

export default DashboardUpcoming;