import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStyles } from '../../assets/styles/materialStyles';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { API_URL, axiosConfig } from '../../api/index';
import axios from 'axios';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
import _ from 'lodash';
import Dropdown from "./Dropdown"



const EditNote = ({ onClose, open, reloadNotes = null, editNoteData, noteToEditName, noteToEditId, classId, parentFolder, openedFolderId, folderNameToEdit, folderIdToEdit }) => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{ snack }, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [assignFolderId, setAssignFolderId] = useState(folderIdToEdit);
  const [noteName, setNoteName] = useState(noteToEditName);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [refreshFlag, setRefreshFlag]=useState(0);

  useEffect(() => {
    setRefreshFlag(0);
    setNoteName(noteToEditName);
    if (editNoteData && editNoteData.folders) {
      editNoteData.folders.unshift({ id: "", name: "root", checked: false });
      renderMenu(editNoteData.folders);
    }
  }, [editNoteData]);

  useEffect(()=>{

  },[assignFolderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //var result = _.find(editNoteData.folders, { "checked": true });
    let noteFolderData = {
      folderId: assignFolderId,
      id: noteToEditId,
      name: noteName
    }
    console.log(noteFolderData);
    setRefreshFlag(0);
    onClose();
    axios.put(API_URL + 'note', noteFolderData, headers)
      .then(response => {
        setServerError('');
        // reload data based on folder position 
        if (!openedFolderId) {
          reloadNotes(null, classId);
        } else {
          reloadNotes(parentFolder.id, null);
        }
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });

      }).catch((error) => {
        if (error.message.includes("507")) {
          setServerError(t('NOTE_ADD_ERROR_DISK_QUOTA_1' + " " + t('NOTE_ADD_ERROR_DISK_QUOTA_2')));
        } else if (error.message.includes("415")) {
          setServerError(t('NOTE_ADD_INCORRECT_FORMAT'));
        } else if (error.message.includes("503")) {
          setServerError(t('NOTE_ADD_SERVICE_UNAVAILABLE_ERROR'));
        } else if (error.message.includes("500")) {
          setServerError(t('NOTE_ADD_INTERNAL_SERVER_ERROR'));
        } else {
          setServerError(t('MSG_PROCESS_ERROR'));
        }
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });

      });
  }


  const onChangeDropDown = (currentNode, selectedNode) => {
    if(selectedNode.length>0){
      setAssignFolderId(selectedNode[0].value);
      setBtnDisabled(false);
    }
  }


  const renameKey = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }


  const renderMenu = items => {

    {
      items.map((i) => {
        if (i.id == folderIdToEdit) {
          i.checked = true
        } else {
          i.checked = false
        }
        renameKey(i, 'id', 'value');
        renameKey(i, 'name', 'label');
        renameKey(i, 'folders', 'children');
        if (i.folders) {
          renderMenu(i.folders)
        } else if (i.children) {
          renderMenu(i.children)
        }
      })
    }
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
  }

  // const Menu = ({ data }) => {
  //   return (data && data.folders && renderMenu(data.folders) )
  // }
  const nameChange = (e) => {
    setBtnDisabled(false);
    setNoteName(e.target.value);
  }




  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('EDIT_NOTE')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError != "" ?
            <Alert severity="error" className="alertBox">
              {serverError}
            </Alert>
            : null}
        </div>
        <div>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <TextField
                value={noteName}
                onChange={(e) => nameChange(e)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="noteName"
                placeholder={t('PROFILE_FIRST_NAME')}
                name="noteName"
                autoComplete="noteName"
                error={errors["noteName"]}
                helperText={errors["noteName"]}
                autoFocus
                className="mb-2"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" className={`${classes.formControl} ${classes.dropDownTreeTop}`} >
              {editNoteData && editNoteData.folders &&
                <Dropdown onChangeDropDown={onChangeDropDown} data={editNoteData.folders} refreshFlag={refreshFlag} setRefreshFlag={setRefreshFlag} />
              }
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
              disabled={btnDisabled}
            >
              {t('BTN_SAVE')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditNote

