import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  

const AddAssignment = ({ onClose, open, data, reloadAssignments }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [assignmentName, setAssignmentName] = React.useState('');
  const [dueDate, setDueDate] = React.useState(new Date());
  const [description, setDescription] = React.useState('');
  const [classId, setClassId] = React.useState('');

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleClassChange = (event) => {
    setClassId(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      let assignmentData={
        name: assignmentName,
        classId: classId,
        dueDate: dueDate.setHours(12, 0, 0, 0),
        description: description
      }
      axios.post(API_URL+'assignment', assignmentData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setAssignmentName('');
        setDueDate(new Date());
        setClassId('');
        setDescription('');
        reloadAssignments();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(assignmentName==''){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_EMPTY_TITLE');
    }

    if(assignmentName!=''&&assignmentName.length<3){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_SHORT_TITLE');
    }

    if(assignmentName!=''&&assignmentName.length>50){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_LONG_TITLE');
    }

    if(classId==''){
      formIsValid = false;
      errorsData["classId"] = t('MSG_EMPTY_CLASS');
    }

    if(dueDate=='' || dueDate==null){
        formIsValid = false;
        errorsData["dueDate"] = t('MSG_INVALID_DATE');
      }

    if(description!=''&&description==''){
        formIsValid = false;
        errorsData["description"] = t('MSG_EMPTY_DESCRIPTION');
      }
  
      if(description!=''&&description.length<10){
        formIsValid = false;
        errorsData["description"] = t('MSG_SHORT_DESCRIPTION');
      }
  
      if(description!=''&&description.length>3000){
        formIsValid = false;
        errorsData["description"] = t('MSG_LONG_DESCRIPTION');
      }

    setErrors(errorsData);
    return formIsValid;
  }

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-assignment" open={open}>
      <DialogTitle>{t('ASSIGNMENT_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={assignmentName}
                onChange={(e)=>setAssignmentName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="assignmentName"
                placeholder={t('ASSIGNMENT_NAME')}
                name="assignmentName"
                autoComplete="assignmentName"
                error={errors["assignmentName"]}
                helperText={errors["assignmentName"]}
                autoFocus
                className="mb-4"
              />
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={classId}
                  onChange={handleClassChange}
                  placeholder={t('CLASS_SELECT')}
                  fullWidth
                  required
                  id="classId"
                  name="classId"
                  autoComplete="classId"
                  error={errors["classId"]}
                  className="mb-1"
                >
                  <option aria-label={t('CLASS_SELECT')} value="">{t('CLASS_SELECT')}</option>
                  {data && data.classes && data.classes.length>0 &&
                    (data.classes.map((classData, i)=>(
                      <option value={classData.id}>{classData.name}</option>
                    )))
                  }
                </Select>
                <FormHelperText error>{errors["classId"]}</FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                minDate={new Date()}
                shouldDisableDate={disableWeekends}
                fullWidth
                inputVariant="outlined"
                margin="normal"
                error={errors["dueDate"]}
                helperText={errors["dueDate"]}
                id="dueDate"
                name="dueDate"
                placeholder={t('ASSIGNMENT_DUE_DATE')}
                format="MMMM dd, yyyy"
                value={dueDate}
                onChange={handleDueDateChange}
            />
            </MuiPickersUtilsProvider>
            <TextField
                value={description}
                multiline
                rows={4}
                onChange={(e)=>setDescription(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                placeholder={t('ASSIGNMENT_DESCRIPTION')}
                name="description"
                autoComplete="description"
                error={errors["description"]}
                helperText={errors["description"]}
                className="mb-4"
              />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('ASSIGNMENT_ADD')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default AddAssignment;