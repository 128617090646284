import React, { useEffect, useState } from 'react';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';
import UserAvatar from '../User/UserAvatar';
import { Badge } from '@material-ui/core';

const ChatUser = ({chatItem, selectedChat, setSelectedChat, i, setChatDetailData, page, setPage, lastItem=false, responsive=false,setResponsive=false,showSideBar=false, setShowSideBar=false,setShowContent=false}) => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [{chatWithParam}, dispatchSetChatWithParam] = useStateValue();

  const selectChat = (chatItem = null, i = null) => {
    if(responsive){
      setShowSideBar(false)
      setShowContent(true);
    }
    dispatchSetChatWithParam
    ({
      type: 'CHAT_WITH_PARAM', 
      chatWithParam: null
    });
    setChatDetailData([]);
    setPage(0);
    setSelectedChat(chatItem);
    chatItem.countNewMessages=0;
    // if(data.length>0){
    //   let tempData=data;
    //   tempData[i].countNewMessages=0;
    //   setData(tempData);
    // }
  }

  useEffect(()=>{
    
  }, [chatItem]);

  const condition1css=(chatItem && selectedChat && chatItem.id == selectedChat.id) ? "contact active" : "contact";
  const condition2css=(lastItem) ? "lastChat" : "";

  return (
    <li className={`${condition1css} ${condition2css}`} style={{display: responsive && showSideBar ? 'block' :  !responsive ? 'block' : 'none' }} onClick={() => { selectChat(chatItem, i) }}>
      <div className="wrap">
        <div className="img">
          {(chatItem && selectedChat && chatItem.id == selectedChat.id) ?
          <UserAvatar profile={chatItem} noModal chat status={chatItem.chatActiveStatus} />
          :
          <Badge className="chatBadgeDesign" anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }} badgeContent={chatItem.countNewMessages} color="primary">
            <UserAvatar profile={chatItem} noModal chat status={chatItem.chatActiveStatus} />
          </Badge>
          }
        </div>
        <div className="meta">
          <p className="name">
            <strong>
              {chatItem.name}
            </strong>
          </p>
          {/* if group number of members start */}
          {chatItem.group &&
            <p className="preview mb-0 italic">{t('CHATS_MEMBERS')}: {chatItem.users.length}</p>
          }
          {/* if group number of members end */}
          {/* if countNewMessages present start */}
          {chatItem.countNewMessages != 0 && <>
            <div className="chatCount">
              {/* {(chatItem.id==selectedChat.id) ? '' : <Badge badgeContent={chatItem.countNewMessages} color="primary" />} */}
            </div>
          </>}
          {/* if countNewMessages present end */}
          {/* if individual participant showing online status start */}
          {!chatItem.group &&
            <p className="preview mb-0 italic">{chatItem.chatActiveStatus ? t('CHATS_ONLINE') : t('CHATS_OFFLINE')}</p>
          }

          {/* if individual participant showing online status end */}
        </div>
      </div>
    </li>
  );
}

export default ChatUser;