import React, {useState, useEffect} from 'react';
import { InputLabel, TextField, Button, Dialog, DialogTitle, DialogContent, Card, Typography, FormControl, Select, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';

const AddGradeCriteria = ({ onClose, reloadGrades, data={}, grades={} }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [percentageFrom, setPercentageFrom] = useState(0);
  const [percentageTo, setPercentageTo] = useState(0);
  const [grade, setGrade] = useState('');
  const [criteria, setCriteria] = useState([]);

  useEffect(()=>{
    if(grades && grades.criteria && grades.criteria.length>0){
      setCriteria(grades.criteria);
    }
  },[data, grades]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(criteria.length>0){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
        let gcData={
          classId: data.id,
          criteria: criteria
        }
        axios.post(API_URL+'grades/class', gcData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          setPercentageFrom(0);
          setPercentageTo(0);
          setGrade('');
          reloadGrades();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
          console.log(error);
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(grade==''){
      formIsValid = false;
      errorsData["grade"] = t('MSG_EMPTY_GRADE');
    }

    if(grade!=''&&grade.length>50){
      formIsValid = false;
      errorsData["grade"] = t('MSG_LONG_GRADE');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  const handleRemoveFromCriteria = (gc) => {
    setCriteria(
      criteria => criteria.filter(item => item !== gc)
    )
  };

  const handlePercentageFrom = (event) => {
    setPercentageFrom(event.target.value);
  };

  const handlePercentageTo = (event) => {
    setPercentageTo(event.target.value);
  };

  const handleGrade = (event) => {
    setGrade(event.target.value);
  };

  const addToCriteria = () => {
    if(validateForm()){
      let tempCriteria={
          percentageFrom: percentageFrom,
          percentageTo: percentageTo,
          grade: grade
      };
      setCriteria([
          ...criteria,
          tempCriteria
      ])
    }
  }

  return (
    <div>
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Card className="class">
                <div className="card-header">
                    {t('GRADES_CRITERIA')}
                </div>
                <div className="contentContainer">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="percentageFrom">{t('GRADES_FROM')}</InputLabel>
                            <Select
                                label={t('GRADES_FROM')}
                                native
                                value={percentageFrom}
                                onChange={handlePercentageFrom}
                                required
                                id="percentageFrom"
                                name="percentageFrom"
                                autoComplete="percentageFrom"
                                error={errors["percentageFrom"]}
                                className="mb-1"
                                >
                                  {[...Array(101)].map((x, i) =>
                                    <option value={i}>{i}</option>
                                  )}
                            </Select>
                            <FormHelperText error>{errors["percentageFrom"]}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="percentageTo">{t('GRADES_TO')}</InputLabel>
                            <Select
                                label={t('GRADES_TO')}
                                native
                                value={percentageTo}
                                onChange={handlePercentageTo}
                                required
                                id="percentageTo"
                                name="percentageTo"
                                autoComplete="percentageTo"
                                error={errors["percentageTo"]}
                                className="mb-1"
                                >
                                  {[...Array(101)].map((x, i) =>
                                    <option value={i}>{i}</option>
                                  )}
                            </Select>
                            <FormHelperText error>{errors["percentageTo"]}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <TextField
                            value={grade}
                            onChange={(e)=>setGrade(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="grade"
                            placeholder={t('GRADES_GRADE')}
                            label={t('GRADES_GRADE')}
                            name="grade"
                            autoComplete="grade"
                            error={errors["grade"]}
                            helperText={errors["grade"]}
                            className="mt-0"
                          />
                        </Grid>
                    </Grid>
                    <Button
                    onClick={addToCriteria}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="blueButton mt-2"
                        >
                        {t('GRADES_CRITERIA_ADD')}
                    </Button>
                    {criteria.length>0 &&
                    <TableContainer className="mt-2">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>{t('GRADES_FROM')}</TableCell>
                                <TableCell>{t('GRADES_TO')}</TableCell>
                                <TableCell>{t('GRADES_GRADE')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {criteria.map((gc, i) => (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {gc.percentageFrom}
                                    </TableCell>
                                    <TableCell>{gc.percentageTo}</TableCell>
                                    <TableCell>{gc.grade}</TableCell>
                                    <TableCell><IconButton aria-label="delete" onClick={()=>handleRemoveFromCriteria(gc)}>
                                    <DeleteIcon />
                                  </IconButton>
                                  </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                      </TableContainer>
                    }
                </div>
              </Card>
            <Button
              disabled={criteria.length==0}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SAVE_CRITERIA')}
            </Button>
          </form>
        </div>
      </div>
);
}

export default AddGradeCriteria;