import React, {useState,useEffect} from 'react';
import { TextField,Checkbox, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText,
  FormLabel } from '@material-ui/core';
import { Alert,Autocomplete } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    } from '@material-ui/pickers';
import axios from 'axios';
import * as moment from 'moment';



const EditUser = ({onClose, open,userEditData,reloadTableData,reloadSuperAdminTableData,universityId,errors,setErrors}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const [serverError, setServerError] = useState('');

    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [{user}] = useStateValue();
    const headers = axiosConfig(user.token);
    const[address,setAddress] = useState('');
    const[dob,setDob] = useState('');
    const[email,setEmail] = useState('');
    const[firstName,setFirstName] = useState('');
    const[lastName,setLastName] = useState('');
    const[password,setPassword] = useState('');
    const[phoneNumbers,setPhoneNumbers] = useState('')
    const[semesterId,setSemesterId] = useState('');
    const[semesterName,setSemesterName] = useState('');
    const [gender,setGender] = useState("MALE");
    const[confirmPassword,setConfirmPassword] = useState('');
    const [universityIdForEdit,setUniversityIdForEdit] = useState([]);
    const headersWithToken = axiosConfig(user.token);
    const [semesterData,setSemesterData] = useState([]);



   

    // function to get id from university object start
    function GetPropertyValue(obj1, dataToRetrieve) {
      return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k) {
          return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }
       // function to get id from university object end

    

    useEffect(()=>{
        setEmail(userEditData.email);
        setAddress(userEditData.address);
        setDob(userEditData.dateOfBirth);
        setFirstName(userEditData.firstName);
        setLastName(userEditData.lastName);
        setFirstName(userEditData.firstName);
        if(userEditData.sex){
          setGender(userEditData.sex);
        }else{
          setGender("MALE");
        }
        if(userEditData.phoneNumbers){
        setPhoneNumbers(userEditData.phoneNumbers[0])
        }
        if(userEditData.university){
          setUniversityIdForEdit(GetPropertyValue(userEditData.university, "id")) 
        }if(userEditData.semester){
          setSemesterId(GetPropertyValue(userEditData.semester, "id")) 
          setSemesterName(GetPropertyValue(userEditData.semester, "name"))
        }
      
    },[userEditData]);

    useEffect(()=>{
     semesterLoad();
    },[universityIdForEdit])

const semesterLoad = () =>{
  axios.get(API_URL+'semester/university?universityId='+universityIdForEdit, headersWithToken)
    .then(response => {
      setSemesterData(response.data);
    
    }).catch((error)=>{

    })
}

const handleSubmit = (e) =>{
    e.preventDefault();
    if(validateForm()){
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
  
      let userData =  {
        "address":address,
        "dateOfBirth":dob,
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "password": password,
        "phoneNumbers": [
          phoneNumbers
        ],
        "semesterId": semesterId,
        "sex": gender,
        "universityId": universityIdForEdit,
        }

     
      axios.put(API_URL+'administrator/user/'+userEditData.id, userData, headers)
    .then(response => {
     
      setServerError('');
      if(universityId){
        reloadSuperAdminTableData();
      }else{
        reloadTableData();
      }
     
   
    dispatchSnack({
      type: 'SET_SNACK',
      snack: {
        flag: true,
        message: t('USER_UPDATED_SUCCESS')
      }
    });
    onClose();

    }).catch((error)=>{
     
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      if(error.message.includes("507")){
        setServerError(t('NOTE_ADD_ERROR_DISK_QUOTA_1' + " " + t('NOTE_ADD_ERROR_DISK_QUOTA_2')));
      }else if(error.message.includes("415")){
        setServerError(t('NOTE_ADD_INCORRECT_FORMAT'));
      }else if(error.message.includes("503")){
        setServerError(t('NOTE_ADD_SERVICE_UNAVAILABLE_ERROR'));
      }else if(error.message.includes("500")){
        setServerError(t('NOTE_ADD_INTERNAL_SERVER_ERROR'));
      }else{
        setServerError(t('MSG_PROCESS_ERROR'));
      }

    })
  }

}


const handleDateOfBirth = (date) =>{
var momentDate = moment(date).format(t('DATE_USER_EDIT'));
  setDob(momentDate);
}

const genderDeal =(e) =>{
  setGender(e.target.value);
}

const validateForm = () => {
  let errorsData = {};
  let formIsValid = true;

  if(firstName==''){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_EMPTY_FIRST_NAME');
  }
  if(firstName!=''&&firstName.length<2){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_SHORT_FIRST_NAME');
  }

  if(firstName!=''&&firstName.length>50){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_LONG_FIRST_NAME');
  }

  if(lastName==''){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_EMPTY_LAST_NAME');
  }

  
  if(lastName!=''&&lastName.length<2){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_SHORT_LAST_NAME');
  }

  if(lastName!=''&&lastName.length>50){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_LONG_LAST_NAME');
  }

  if(address=='' || address==null){
    formIsValid = false;
    errorsData["address"] = t('USER_ADDRESS_VALIDATION');
  }

  
  setErrors(errorsData);
  return formIsValid;

}

const handleSemesterChange =(event) => {
setSemesterId(event.target.value)
}




    return (
       <>
       <Dialog  fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('USER_EDIT')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                   <TextField
                    value={firstName}
                    onChange={(e)=> setFirstName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t('USER_FIRST_NAME')}
                    placeholder={t('USER_FIRST_NAME')}
                    name="firstName"
                    autoComplete="firstName"
                    error={errors["firstName"]}
                    helperText={errors["firstName"]}
                    autoFocus
                    className="mb-2"
                  />
                   </FormControl>
       <FormControl  fullWidth variant="outlined" className={classes.formControl}>
                        <TextField
                    value={lastName}
                    onChange={(e)=> setLastName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={t('USER_LAST_NAME')}
                    placeholder={t('USER_LAST_NAME')}
                    name="lastName"
                    autoComplete="lastName"
                    error={errors["lastName"]}
                    helperText={errors["lastName"]}
                    className="mb-2"
                  />
                   </FormControl>
                   {/* material ui gender */}
                  <FormControl component="fieldset" className={classes.formControlEditUser}>
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                  aria-label="Gender"
                  name="gender"
                  className={classes.groupEditUser}
                  value={gender}
                  onChange={(e)=>genderDeal(e)}
                >
                <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
           {/* material ui gender */}
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  margin="normal"
                  error={errors["dob"]}
                  helperText={errors["dob"]}
                  id="dob"
                  name="dob"
                  placeholder={t('USER_DATE_OF_BIRTH')}
                  format="MMMM dd, yyyy"
                  value={dob}
                  onChange={handleDateOfBirth}
              />
              </MuiPickersUtilsProvider>
                    
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                   <TextField
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label={t('USER_EMAIL')}
                    placeholder={t('USER_EMAIL')}
                    name="email"
                    autoComplete="email"
                    error={errors["email"]}
                    helperText={errors["email"]}
                    className="mb-2"
                  />
                   </FormControl>
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                 <TextField
                    value={phoneNumbers}
                    onChange={(e)=> setPhoneNumbers(e.target.value)}
                    variant="outlined"
                    margin="normal"
                     type="number"
                    fullWidth
                    id="phoneNumbers"
                    label={t('USER_PHONE_NUMBER')}
                    placeholder={t('USER_PHONE_NUMBER')}
                    name="phoneNumbers"
                    autoComplete="phoneNumbers"
                    error={errors["phoneNumbers"]}
                    helperText={errors["phoneNumbers"]}
                    className="mb-2"
                  />
                   </FormControl>
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                  <TextField
                    value={address}
                    multiline
                    rows={4}
                    onChange={(e)=> setAddress(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    label={t('USER_ADDRESS')}
                    placeholder={t('USER_ADDRESS')}
                    name="address"
                    autoComplete="address"
                    error={errors["address"]}
                    helperText={errors["address"]}
                    className="mb-4"
                  />
                   </FormControl>

                 {/* old option start */}
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={semesterId}
                  onChange={handleSemesterChange}
                  placeholder={t('SEMESTER_SELECT')}
                  fullWidth
                  id="semesterId"
                  name="semesterId"
                  autoComplete="semesterId"
                  error={errors["semesterId"]}
                  className="mb-1"
                >
                  <option aria-label={t('SEMESTER_SELECT')} value={semesterId? semesterId: ''}>
                    {semesterName ? semesterName : t('SEMESTER_SELECT')}</option>
                  {semesterData &&  semesterData.length>0 &&
                    (semesterData.map((semester, i)=>(
                      <option value={semester.id}>{semester.name}</option>
                    )))
                  }
                </Select>
                <FormHelperText error>{errors["semesterId"]}</FormHelperText>
            </FormControl>
            {/* old option end*/}
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                  <TextField
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label={t('PASSWORD')}
                    placeholder={t('PASSWORD')}
                    name="password"
                    type="password"
                    autoComplete="password"
                    error={errors["password"]}
                    helperText={errors["password"]}
                    className="mb-2"
                  />
                   </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3">
              {t('BTN_SAVE')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>

       </>
    )
}

export default EditUser
