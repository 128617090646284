import React, {useState, useEffect} from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import axios from 'axios';
import {useStyles} from '../../../assets/styles/materialStyles';
import {useStateValue} from '../../../store/StateProvider';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import {API_URL, axiosConfig, SOCKET_API} from '../../../api/index';
import Stomp from 'stompjs';
import { useHistory } from "react-router-dom";

const HeaderNotifications = ({notificationsMenu, handleNotificationsMenuClose, handleMobileMenuClose}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [serverError, setServerError] = useState('');
  const { t } = useTranslation();
  const headers = axiosConfig(user.token);
  const [sc, setSc]=useState(null);
  const [data, setData]=useState([]);
  const [{inviteEventsCount}, dispatchInviteEventsCount] = useStateValue();
  const [{addNoteEventsCount}, dispatchAddNoteEventsCount] = useStateValue();
  const [{shareNoteEventsCount}, dispatchShareNoteEventsCount] = useStateValue();
  const [{addAssignmentEventsCount}, dispatchAddAssignmentEventsCount] = useStateValue();
  const [{shareAssignmentEventsCount}, dispatchShareAssignmentEventsCount] = useStateValue();
  const [{feedbackEventsCount}, dispatchFeedbackEventsCount] = useStateValue();
  const [{addPostEventsCount}, dispatchAddPostEventsCount] = useStateValue();
  const [{chatEventsCount}, dispatchChatEventsCount] = useStateValue();
  const history = useHistory();

  let socket = new WebSocket(SOCKET_API);
  let stompClient = Stomp.over(socket);

  //Notification Menu
  const notificationsMenuId = 'primary-notification-menu';

  const handleChange = (event, id) => {
    // dispatchSetLoading({
    //   type: 'SET_LOADING',
    //   loading: true
    // });
    //   axios.put(API_URL+"event/" + id + "?complete=" + event.target.checked, null, headers)
    //   .then(response => {
    //     dispatchSetLoading({
    //       type: 'SET_LOADING',
    //       loading: false
    //     });
    //     reloadEvents();
    //     dispatchSnack({
    //       type: 'SET_SNACK',
    //       snack: {
    //         flag: true,
    //         message: t('MSG_SUCCESS')
    //       }
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     dispatchSetLoading({
    //       type: 'SET_LOADING',
    //       loading: false
    //     });
    //     setServerError(t('MSG_PROCESS_ERROR'));
    //   });
  };

  useEffect(()=>{
    fetchHeaderNotifications();
  }, []);

  const fetchHeaderNotifications = ()=>{
    let headersSocket = {
      token: user.token
    };
    stompClient.connect(headersSocket, ()=>{
      setSc(stompClient);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    }, ()=>{
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
    });
  }

  useEffect(()=>{
    let subHeader=null;
    if(sc && sc.connected && user && user.id){
      subHeader=sc.subscribe('/topic/user/events'+user.id, (eventData) => {
        let edBody=JSON.parse(eventData.body);
        let eventType=edBody.type;
        let cPath="";
        let str=history.location.pathname;
        let regex=new RegExp(/[^/]*\/([^/]*)/);
        let result = regex.exec(str);
        if(result.length>0){
          cPath=result[0];
          cPath=cPath.replace('/',"")
        }
        switch (eventType) {
            case "INVITE":
              if(cPath!="dashboard"&&cPath!=""){
                dispatchInviteEventsCount(
                {
                  type: 'INVITE_EVENTS_COUNT',
                  count: inviteEventsCount+1
                });
                break;
              }
            case "ADD_NOTE":
              if(cPath!="notes"){
                dispatchAddNoteEventsCount(
                {
                  type: 'ADD_NOTE_EVENTS_COUNT',
                  count: addNoteEventsCount+1
                });
                break;
              }
            case "SHARE_NOTE":
              if(cPath!="dashboard"&&cPath!=""){
                dispatchShareNoteEventsCount(
                {
                  type: 'SHARE_NOTE_EVENTS_COUNT',
                  count: shareNoteEventsCount+1
                });
                break;
              }
            case "ADD_ASSIGNMENT":
              if(cPath!="assignments"){
                dispatchAddAssignmentEventsCount(
                {
                  type: 'ADD_ASSIGNMENT_EVENTS_COUNT',
                  count: addAssignmentEventsCount+1
                });
                break;
              }
            case "SHARE_ASSIGNMENT":
              if(cPath!="dashboard"&&cPath!=""){
                dispatchShareAssignmentEventsCount(
                {
                  type: 'SHARE_ASSIGNMENT_EVENTS_COUNT',
                  count: shareAssignmentEventsCount+1
                });
                break;
              }
            case "FEEDBACK":
              if(cPath!="dashboard"&&cPath!=""){
                dispatchFeedbackEventsCount(
                {
                  type: 'FEEDBACK_EVENTS_COUNT',
                  count: feedbackEventsCount+1
                });
                break;
              }
            case "ADD_POST":
              if(cPath!="dashboard"&&cPath!=""){
                dispatchAddPostEventsCount(
                {
                  type: 'ADD_POST_EVENTS_COUNT',
                  count: addPostEventsCount+1
                });
                break;
              }
            case "CHAT":
                if(cPath!="chats"){
                  dispatchChatEventsCount(
                  {
                    type: 'CHAT_EVENTS_COUNT',
                    count: chatEventsCount+1
                  });
                  break;
                }
            default:
                break;
        }
      });
    }
    return ()=>{
      // if(subHeader)
      // subHeader.unsubscribe();
    }
  }, [sc, user, inviteEventsCount, addNoteEventsCount, shareNoteEventsCount, addAssignmentEventsCount, shareAssignmentEventsCount, feedbackEventsCount, addPostEventsCount, chatEventsCount]);

  const goTo = (locationPath) => {
    handleNotificationsMenuClose();
    handleMobileMenuClose();
    history.push(locationPath);
  }

  return (
    <Menu
        anchorEl={notificationsMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id={notificationsMenuId}
        keepMounted
        open={notificationsMenu}
        onClose={handleNotificationsMenuClose}
    >
        {((inviteEventsCount + addNoteEventsCount + shareNoteEventsCount + addAssignmentEventsCount + shareAssignmentEventsCount + feedbackEventsCount + addPostEventsCount + chatEventsCount)==0) &&
        <MenuItem onClick={handleNotificationsMenuClose}>{t('MSG_NO_NOTIFICATIONS')}</MenuItem>
        }

        {inviteEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/dashboard')}}>{t('YOUR_INVITES')} <strong className="orange ml-1">{inviteEventsCount}</strong></MenuItem>
        }

        {addNoteEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/notes')}}>{t('ADDED_NOTES')} <strong className="orange ml-1">{addNoteEventsCount}</strong></MenuItem>
        }

        {shareNoteEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/dashboard')}}>{t('SHARED_NOTES')} <strong className="orange ml-1">{shareNoteEventsCount}</strong></MenuItem>
        }

        {addAssignmentEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/assignments')}}>{t('ADDED_ASSIGNMENTS')} <strong className="orange ml-1">{addAssignmentEventsCount}</strong></MenuItem>}
        
        {shareAssignmentEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/dashboard')}}>{t('SHARED_ASSIGNMENTS')} <strong className="orange ml-1">{shareAssignmentEventsCount}</strong></MenuItem>}
        
        {feedbackEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/dashboard')}}>{t('CHANGE_REQUEST')} <strong className="orange ml-1">{feedbackEventsCount}</strong></MenuItem>
        }
        {addPostEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/dashboard')}}>{t('ADDED_POSTS')} <strong className="orange ml-1">{addPostEventsCount}</strong></MenuItem>
        }
        {chatEventsCount>0 &&
        <MenuItem className="bb" onClick={()=>{goTo('/chats')}}>{t('CHAT_NEW_MESSAGE')} <strong className="orange ml-1">{chatEventsCount}</strong></MenuItem>
        }
    </Menu>
  );
}

export default HeaderNotifications;