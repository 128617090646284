import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { Grid, Paper, Card, IconButton } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPen, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {useStyles} from '../../assets/styles/materialStyles';
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import DiffMatchPatch from 'diff-match-patch';

const Assignment = ({data, classProfessor}) => {
  const classes = useStyles();
  const [confirmExit, setConfirmExit] = useState(false);
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [{user}] = useStateValue();
  const headers = axiosConfig(user.token);
  const { t } = useTranslation();

  const editAssignment = (assignment) => {
    var orgText = assignment.description;
    var newText = "Assignment describe";
    var dmp = new DiffMatchPatch();
    dmp.Diff_Timeout = 5;
    var d = dmp.diff_main(orgText, newText);
    dmp.diff_cleanupSemantic(d);
    var ds = dmp.diff_prettyHtml(d);
    var elem="desc-"+assignment.id;
    document.getElementById(elem).innerHTML=ds;
  }

  const shareAssignment = (assignmentId) => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.put(API_URL + 'assignment/'+assignmentId+'/share', null, headers)
    .then((response)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('ASSIGNMENT_SHARED')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_PROCESS_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
  }

  return (
          <Grid item xs={12} sm={6}>
            <Card variant="outlined">
              <div className="assignmentContainer">
                <div className="assignment-header">
                    <div className="assignment-heading">
                        {data.name}
                    </div>
                    <div className="assignment-icon-container">
                        <div className="hidden">
                        {((data.creator.id == user.id || (data.professor && data.professor.id == user.id)) && !user.semester.past) &&
                        <IconButton className="headerIconButton ml-1" onClick={()=>{editAssignment(data)}}>
                        <FontAwesomeIcon className="editIcon" icon={faPen} />
                        </IconButton>
                        }
                        </div>
                        {(!data.professor && !data.shared && !user.semester.past) &&
                        <IconButton className="headerIconButton ml-1" onClick={()=>{shareAssignment(data.id)}}>
                        <FontAwesomeIcon className="editIcon" icon={faShareAlt} />
                        </IconButton>
                        }
                    </div>
                </div>
                <div className="assignment-content" id={"desc-"+data.id}>
                    {data.description}
                </div>
                <div className="assignment-details">
                  <div className="assignment-creationDate">
                    <FontAwesomeIcon className="clockIcon" icon={faClock} /> {t('DUE_ON')} {moment(data.creationDate).format(t('DATE_POSTED'))}
                  </div>
                  <div className="assignment-createdBy italic light">
                    {t('CREATED_BY')}
                    {(data.creator.id == user.id)?
                      <strong>{t('YOU')}</strong>
                    :
                      <strong><a href="#">{data.creator.firstName + " " + data.creator.lastName}</a></strong>
                    }
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
  );
}
export default Assignment;