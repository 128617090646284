export const initialState = {
    isAppInstalled: false,
    loading: false,
    snack: {
        flag: false,
        message: undefined
    },
    user: undefined,
    title: '',
    inviteEventsCount: 0,
    addNoteEventsCount: 0,
    shareNoteEventsCount: 0,
    addAssignmentEventsCount: 0,
    shareAssignmentEventsCount: 0,
    feedbackEventsCount: 0,
    addPostEventsCount: 0,
    chatEventsCount: 0,
    //for route parameter
    chatWithParam: '',
    assignmentParam: '',
    specificTaskId: '',
    singleChatLoader: false,
    sctn:null,
    //classID for folder
    // classId:'',
    // folderIdParam:'',
   
  
};

const reducer = (state, action) => {
    switch(action.type){
        //Authentication
        case 'SET_IS_APP_INSTALLED':
            return {
                ...state,
                isAppInstalled: action.isAppInstalled
            };
            break;
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.loading
            };
            break;
        case 'SET_SNACK':
            return {
                ...state,
                snack: {
                    flag: action.snack.flag,
                    message: action.snack.message
                }
            };
            break;
        case 'LOGIN':
            return {
                ...state,
                user: action.user
            };
            break;
        case 'PROFILE_UPDATE':
            return {
                ...state,
                user: action.user
            };
            break;
        case 'LOGOUT':
            return {
                ...state,
                user: undefined
            };
            break;
        //Title
        case 'SET_TITLE':
            return {
                ...state,
                title: action.title
            };
            break;
        //Header Notifications Counter
        case 'INVITE_EVENTS_COUNT':
            return {
                ...state,
                inviteEventsCount: action.count
            }
        case 'ADD_NOTE_EVENTS_COUNT':
            return {
                ...state,
                addNoteEventsCount: action.count
            }
        case 'SHARE_NOTE_EVENTS_COUNT':
            return {
                ...state,
                shareNoteEventsCount: action.count
            }
        case 'ADD_ASSIGNMENT_EVENTS_COUNT':
            return {
                ...state,
                addAssignmentEventsCount: action.count
            }
        case 'SHARE_ASSIGNMENT_EVENTS_COUNT':
            return {
                ...state,
                shareAssignmentEventsCount: action.count
            }
        case 'FEEDBACK_EVENTS_COUNT':
            return {
                ...state,
                feedbackEventsCount: action.count
            }
        case 'ADD_POST_EVENTS_COUNT':
            return {
                ...state,
                addPostEventsCount: action.count
            }
        case 'CHAT_EVENTS_COUNT':
            return {
                ...state,
                chatEventsCount: action.count
            }
        //Dashboard to chat redirct param
        case 'CHAT_WITH_PARAM':
            return {
                ...state,
                chatWithParam: action.chatWithParam
            }
        //Dashboard to assignment class param
        case 'ASSIGNMENT_ID':
            return {
                ...state,
                assignmentParam: action.assignmentParam
            }
         //Dashboard to task class param
        case 'SPECIFIC_TASK':
            return {
                ...state,
                specificTaskId: action.specificTaskId
            }
        //DashboardNotification to single chat loader
        case 'SINGLE_CHAT_LOADER':
            return{
                ...state,
                singleChatLoader: action.singleChatLoader
            }
        // Socket starting from home param to chat
        case "SOCKET_CONNECT":
            return{
                ...state,
                sctn: action.sctn
            }
        // //for Folder Class Id
        // case "CLASS_ID":
        //     return{
        //         ...state,
        //         classId:action.classId
        //     }
    
       
        default:
            return state;
    }
}

export default reducer;