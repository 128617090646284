import React, {useEffect, useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText, List,ListItem, ListItemAvatar,Hidden ,Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import UserAvatar from '../User/UserAvatar';
import * as moment from 'moment';

const JoinTerm = ({ onClose, open, reloadProfile,loadSemesterData }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const [futureSemesters, setFutureSemesters] = useState([]);
  const headers = axiosConfig(user.token);
  const [width, setWidth] = useState(window.innerWidth);
  const [responsiveView,setResponsiveView] = useState(false)

    // screen size responsive logic start
useEffect(() => {
  function handleResize() {
    setWidth(window.innerWidth);
  }
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, [width]);

useEffect(() => {
  width < 770 && handleMobileView();
  width > 770 && handleDesktopView();
 },[width]);

function handleMobileView() {
  setResponsiveView(true);
 
}
function handleDesktopView() {
  setResponsiveView(false);
}
// screen size responsive logic end
  
  useEffect(()=>{
    if(open){
      fetchFutureTerms();
    }
  }, [open]);

  const fetchFutureTerms = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });

    axios.get((API_URL + 'semester/university/'+user.university.id + '/future'), headers)
    .then((response)=>{
      setFutureSemesters(response.data);
      
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const joinTerm = (semester) => {
   
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });

    axios.put((API_URL + 'semester/join/'+semester.id), null, headers)
    .then((response)=>{
      reloadProfile();
      loadSemesterData();
      onClose();
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_SUCCESS')
        }
      });
    })
    .catch((errors)=>{
     
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="add-term" open={open}>
      <DialogTitle>{t('SEMESTER_JOIN')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {(futureSemesters.length>0)?
              futureSemesters.map((semester, i) => (
                <div key={i}>
                  <List>
                    {
                    futureSemesters.map((semester, i) => (
                      <>
                      {responsiveView? <>
                      <div className="jointTermDividerRemove jointTermScroll">
                    <ListItem clasName="listItemBorder" key={i} divider={((i+1)!=futureSemesters.length)}>
                       <ListItemAvatar>
                        <UserAvatar profile={semester.creator} />
                      </ListItemAvatar>
                      <div className="termData" >
                        <div><strong>{semester.name}</strong></div>
                        <div>{moment(semester.startDate).format(t('ONLY_DATE_POSTED'))} - {moment(semester.endDate).format(t('ONLY_DATE_POSTED'))}</div>
                        <div className="italic">{t('CREATOR')} {semester.creator.firstName + ' ' + semester.creator.lastName}</div>
                      </div>
                    </ListItem>
                    <div >
                        <Button variant="contained" className="orangeButton" onClick={()=>joinTerm(semester)}>{t('SEMESTER_JOIN')}</Button>
                      </div>
                      <div className="orangeButtonBorder" ></div>
                    </div>
                    </> :
                    <>
                    <div className="jointTermScroll">
                    <ListItem  key={i} divider={((i+1)!=futureSemesters.length)}>
                      <ListItemAvatar>
                        <UserAvatar profile={semester.creator} />
                      </ListItemAvatar>
                      <div className="termData" >
                        <div><strong>{semester.name}</strong></div>
                        <div>{moment(semester.startDate).format(t('ONLY_DATE_POSTED'))} - {moment(semester.endDate).format(t('ONLY_DATE_POSTED'))}</div>
                        <div className="italic">{t('CREATOR')} {semester.creator.firstName + ' ' + semester.creator.lastName}</div>
                      </div>
                      <div className="ml-4">
                        <Button variant="contained" className="orangeButton" onClick={()=>joinTerm(semester)}>{t('SEMESTER_JOIN')}</Button>
                      </div>
                    </ListItem>
                    </div>
                    </>}
                      
                    </>
                    ))}
                </List>
                </div>
              ))
              :
              <div>{t('SEMESTER_NO')}</div>
            }
          </form>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default JoinTerm;