import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  

const AddFolder = ({ onClose, open, reloadFolders=null, parentFolder, classId,openedFolderId ,fetchNotesData}) => {
    const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const headersWithToken = axiosConfig(user.token);
// folder setter getter
  const [folderName, setFolderName] = React.useState('');
  const [folderId, setFolderId] = React.useState('');



  const handleSubmit = (e) =>{
    e.preventDefault();
    
    if(validateForm()){
       
        setErrors({});
        let folderData;
        if(openedFolderId){
           folderData={
            name: folderName,
            classId: classId,
            parentFolderId:parentFolder.id,
          }
        }
        if(!openedFolderId){
           folderData={
            name: folderName,
            classId: classId,
            parentFolderId:'',
          }
        }
          axios.post(API_URL+'folder',folderData, headers)
          .then(response => {
          
            dispatchSetLoading({
              type: 'SET_LOADING',
              loading: false
            });
            setServerError('');
            setFolderName('');
            if(!openedFolderId){
              reloadFolders(null,classId);
           }else{
            reloadFolders(parentFolder.id,null);

           }
          //  reloadFolders(parentFolder.id,null);
            onClose();
            dispatchSnack({
              type: 'SET_SNACK',
              snack: {
                flag: true,
                message: t('MSG_SUCCESS')
              }
            });
            
          
          })
          .catch((error) => {
            dispatchSetLoading({
              type: 'SET_LOADING',
              loading: false
            });
            setServerError(t('MSG_PROCESS_ERROR'));
          });
        }

  }

  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(folderName==''){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_EMPTY_NAME');
    }

    if(folderName!=''&&folderName.length<3){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_SHORT_NAME');
    }

    if(folderName!=''&&folderName.length>50){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_LONG_NAME');
    }
   

   

    setErrors(errorsData);
    return formIsValid;
  }
    return (
        <Dialog fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('FOLDER_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={folderName}
                onChange={(e)=>setFolderName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="folderName"
                placeholder={t('FOLDER_NAME')}
                name="folderName"
                autoComplete="folderName"
                error={errors["folderName"]}
                helperText={errors["folderName"]}
                autoFocus
                className="mb-4"
              />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('FOLDER_ADD')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
    )
}

export default AddFolder
