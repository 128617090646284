import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  /* dropdowntreeselect input */
  dropDownTreeTop:{
    marginTop: 
        "8px",
  },
  /* dropdowntreeselect input */
  /* datatable start*/
  searchBarDatatable:{
    padding: '0px 5px 0px 5px',
    marginTop: '5px', 
  },
  rootTable: {
    width: "100%",
  },
  paperTable: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableTable: {
    minWidth: 750,
  },
  visuallyHiddenTable: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  

  /* datatable end*/
  // edituser style start
  rootEditUser: {
    display: 'flex',
    
  },
  formControlEditUser: {
    margin: theme.spacing.unit * 3,
    margin: '0px !important',
  },
  groupEditUser: {
    margin: `${theme.spacing.unit}px 0`,
    flexDirection: 'row !important',
  },
  
  // edit user Style end
  
  //removing iconbutton hover
  remHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  // folder card start
  cardroot: {
    maxWidth: 345,
    marginBottom: "-15px",
    cursor: "pointer",
  },
  cardAva: {
    color: "#bdbdbd",
    background: "none ",
  },
  //folder card end
 
  paperSecondForDatable:{
    padding: theme.spacing(2),
    display: "flex",
    // to increase size at zoom
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: "transparent",
    boxShadow: "none",
    //removed extra spacing
    // marginTop: '-29px',
    paddingTop: "0px !important",
    marginRight: "-20px",
    // marginLeft: "-20px",
    width: '100%',

  },
  //General
  root: {
    flexGrow: 1,
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    // to increase size at zoom
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: "transparent",
    boxShadow: "none",
    //removed extra spacing
    // marginTop: '-29px',
    paddingTop: "0px !important",
    marginRight: "-20px",
    marginLeft: "-20px",
  },
  paperforDatatable: {
    width: '100%',
    // padding: theme.spacing(2),
    display: "flex",
    // to increase size at zoom
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: "transparent",
    boxShadow: "none",
    //removed extra spacing
    // marginTop: '-29px',
    paddingTop: "0px !important",
    // marginRight: "-20px",
    // marginLeft: "-20px",
  },

  //Dashboard Summary
  welcomeCard: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "#f26222",
  },
  infoCard1: {
    backgroundColor: "#4287f5",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
  },
  infoCard1Null: {
    backgroundColor: "#4287f5",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
    borderRadius: "0px",
  },
  infoCard2: {
    backgroundColor: "#f29b22",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
  },
  infoCard2Null: {
    backgroundColor: "#f29b22",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
    borderRadius: "0px",
  },
  infoCard3: {
    backgroundColor: "#5bb531",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
  },
  // when nor record
  infoCard3Null: {
    backgroundColor: "#5bb531",
    height: 60,
    display: "flex",
    alignItems: "center",
    padding: 20,
    color: "white",
    borderRadius: "0px",
  },
  bigFont: {
    fontWeight: "400",
    marginRight: 10,
  },
  iconList: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  listIcon: {
    fontSize: 20,
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  indicator: {
    backgroundColor: "#f29b22",
    height: "10px",
    top: "45px",
  },
}));
