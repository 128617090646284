import React, {useEffect, useState} from 'react';
import { makeStyles, Grid, Paper, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {API_URL, axiosConfigWithoutToken, axiosConfig} from '../../api/index';
import Grade from './Grade';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import { Alert } from '@material-ui/lab'; 

const Grades = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [gradeClasses, setGradeClasses]=useState([]);
  const [{title}, dispatchSetTitle] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('GRADES')
    });
    fetchGradesData();
  }, []);
  const fetchGradesData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.get(API_URL + 'class?token='+user.token, headers)
    .then((response)=>{
      setGradeClasses(response.data);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
          {gradeClasses && gradeClasses && gradeClasses.length>0 &&
            (gradeClasses.map((classData, i)=>(
              <Accordion defaultExpanded={i==0} className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={classData.id+"-content"}
                    id={classData.id}
                  >
                    <Link to="#"><Typography variant="h6">{classData.name}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                        {(classData.professor) ?
                          <Grade data={classData} />
                        : 
                        <Alert severity="warning" className="alertBox">
                          {t('GRADES_NO_TEACHER_FOR_CLASS_MSG')}
                        </Alert>
                        }
                  </div>
                </Grid>
              </Accordion>
            )))
          }
          <SnackbarAlert />
          {loading ?
          <Loader active={true} />
          :
          null}
      </Paper>
    </Grid>
  );
}

export default Grades;