import React, {useEffect, useState, useRef} from 'react';
import { Paper, Button, Grid } from '@material-ui/core';
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {
  useParams
} from "react-router-dom";
import { Document, Page } from 'react-pdf';
import pdfjs from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useHistory } from "react-router-dom";
// import WebViewer from '@pdftron/webviewer';


const ViewNote = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [data, setData]=useState();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfig(user.token);
  let { noteId } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let history = useHistory()
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const viewer = useRef(null);

 

  useEffect(()=>{
    const fetchNote=()=>{
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      axios.get(API_URL + 'note/'+noteId, headers)
      .then((response) => {
        setData(response.data);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        })
      })
      .catch(errors => {
        console.log(errors);
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })      
    }
    fetchNote();
  }, []);
  const onDocumentLoadSuccess=({numPages})=>{
    setNumPages(numPages);
  }
  const goBack=()=>{
    history.goBack();
  }

  // useEffect(() => {
  //   WebViewer(
  //     {
  //       path: '/webviewer/lib',
  //       initialDoc: '/files/pdftron_about.pdf',
  //     },
  //     viewer.current,
  //   ).then((instance) => {
  //       const { docViewer } = instance;
  //       // you can now call WebViewer APIs here...
  //     });
  // }, []);
  return (
    <>
  <Paper className={classes.paper}>
         <Grid className="mb-3" item xs={12} sm={3}>
           <input type="hidden" id="data" value="ok" />
           <div>
             <Button onClick={goBack}  variant="contained" className="yellowButton">{t('GO_BACK_ONLY')}
              </Button>
           </div>
         </Grid>
         {/* <div className="header">React sample</div>
      <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div> */}
         <Grid item justify="center" alignContent="center" xs={12} className="flexAlignCenter">
           <div>
             { data && 
               <div>
                 <Document
                   file={data.filePath}
                   onLoadSuccess={onDocumentLoadSuccess}
                   loading={t('LOADING')}
                   noData={t('MSG_LOAD_FILE_ERROR')}
                   onLoadError={t('MSG_LOAD_FILE_ERROR')}
                 >
                   <Page pageNumber={pageNumber} />
                 </Document>
                 <p>{t('PAGE')} {pageNumber} {t('OF')} {numPages}</p>
               </div>
             }
           </div>
         </Grid>
       </Paper>
    </> 
     
  );
}

export default ViewNote;