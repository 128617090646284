import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth from './Auth/Auth';
import Home from './Home';
import {useStateValue} from '../store/StateProvider';

const App = () => {
  const [{user}, dispatch] = useStateValue();
  useEffect(()=>{
    if(user || localStorage.getItem('user')!="undefined"){
      //Validate Token will come here

      //Once token validated
      dispatch({
        type: 'LOGIN',
        user: JSON.parse(localStorage.getItem('user'))
      });
    }
  }, []);
  return (
      <Router >
      
        {!user ?
        // Unauthenticated Routes
        <Switch>
          <Route exact path="/" component={Auth} />
          <Route path="/login" component={Auth} />
          <Route path="/registration" component={Auth} />
        </Switch>
        :
        //Authenticated Routes
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Home} />
          <Route path="/classes" component={Home} />
          <Route path="/notes" component={Home} />
          <Route path="/students" component={Home} />
          <Route path="/assignments" component={Home} />
          <Route path="/calendar" component={Home} />
          <Route path="/chats" component={Home} />
          <Route path="/grades" component={Home} />
          <Route path="/administrator" component={Home} />
          <Route path="/profile" component={Home} />
          <Route path="/logout" component={Home} />
          <Route path="/flowpaper" component={null} />
        </Switch>
        }
      </Router>
  );
}

export default App;