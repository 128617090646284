import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom'
import '../../assets/styles/App.scss';
import { Badge, Menu, MenuItem, Hidden, Paper, makeStyles, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, TextField, IconButton, Dialog,
  DialogTitle,
  DialogContent,Tooltip,Button ,
  DialogActions } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';

import {
  Link,useHistory
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronCircleRight, faBell } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import Iframe from 'react-iframe';
import ReactPlayer from 'react-player';
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import UserAvatar from '../User/UserAvatar';
import DashboardNotifications from './DashboardNotifications';
import Draggable from 'react-draggable';

function PaperComponent(props) {

  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const DashboardClassBoards = ({findClassBoards, reloadClassBoards}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [notificationsMenu, setNotificationsMenu] = React.useState(false);
  const [postToClassBoards, setPostToClassBoards] = useState('');
  const [postToClassBoardsError, setPostToClassBoardsError] = useState(false);
  const [postToClassBoardsErrorMessage, setPostToClassBoardsErrorMessage] = useState('');
  const [dashboardNotificationsCount, setDashboardNotificationsCount]=useState(0);
  const [{assignmentParam},dispatchSetAssignmentWithParam] = useStateValue();
  let history = useHistory();
  const [iframeOpen, setiframeOpen] = useState(false);
  const[noteId,setNoteId] = useState('');

  

  const windowOpen = (id) => {
    setNoteId(id)
    setiframeOpen(true);
  };

  const windowClose = () => {
    setiframeOpen(false);
  };

  
  

  useEffect(()=>{
   
  },[]);

  const assignmentInd = (id=null) =>{
    dispatchSetAssignmentWithParam
    ({
      type: 'ASSIGNMENT_ID',
      assignmentParam
      : id
    });
      }

  const redirecttoClass = (id=null) =>{
    history.push("/classes/edit-class/"+id);
  }

     

  //Class Board Text
  const classBoardContent = (feed) => {
    // console.log('feed');
    // console.log(feed);
    
    let dateToday = new Date().setHours(0,0,0,0);
    let today = new Date(dateToday);
    let yesterday = new Date(dateToday);
    yesterday=yesterday.setDate(yesterday.getDate() - 1);

    let content=[];
    //CREATE_ASSIGNMENT
    if(feed.user.professor){
      if(user.id == feed.user.id){
        content.push(
          <span>{t('YOU')} </span>
        );
      }else{
        content.push(
          <span>{t('PROFESSOR_YOUR')} <strong>{feed.user.firstName} {feed.user.lastName}</strong> </span>
        );
      }
    }else{
      if(user.id == feed.user.id){
        content.push(
          <span>{t('YOU')} </span>
        );
      }else{
        content.push(
          <strong>{feed.user.firstName} {feed.user.lastName} </strong>
        );
      }
    }
    if(feed.action == 'CREATE_ASSIGNMENT'){
      // link to assignment and class by id using context
      content.push(
        <span>{t('HOME_ADDED_ASSIGNMENT')} <Link to={"/tabs/assignments/"} onClick={()=>assignmentInd(feed.whereModel.id)}> <strong>{feed.whatModel.name}</strong> </Link>
        {t('HOME_IN_CLASS')} <Link to="#" onClick={()=>redirecttoClass(feed.whereModel.id)} ><strong>{feed.whereModel.name}</strong></Link></span>
      );
      if(feed.user.professor){
        content.push(
          <span>
            <br /><div className="classBoardContent">{t('HOME_CHECK_ASSIGNMENTS')}</div>
          </span>
        );
      }
    }//END CREATE_ASSIGNMENT
    //INVITE_TO_CLASS
    if(feed.action == 'INVITE_TO_CLASS' || feed.action == 'INVITE_TO_CLASS_AS_PROFESSOR_TO_CREATOR' || feed.action == 'INVITE_TO_CLASS_AS_PROFESSOR'){
      if(feed.action == 'INVITE_TO_CLASS'){
        content.push(
        <span>{t('HOME_INVITED')} <strong>{t('YOU')}</strong> {t('HOME_JOIN_CLASS')}</span>
        );
      }else if(feed.action == 'INVITE_TO_CLASS_AS_PROFESSOR_TO_CREATOR'){
        content.push(
          <span>{t('HOME_INVITED')} {t('PROFESSOR')} <strong>{feed.whereModel.firstName} {feed.whereModel.lastName}</strong> {t('HOME_JOIN_CLASS')}</span>
        );
      }else if(feed.action == 'INVITE_TO_CLASS_AS_PROFESSOR'){
        content.push(
          <span>{t('HOME_INVITED')} {t('PROFESSOR')} <strong>{t('YOU')}</strong> {t('HOME_JOIN_CLASS')}</span>
        );
      }
      content.push(
        <Link to="#" onClick={()=>redirecttoClass(feed.whereModel.id)} ><strong>{feed.whatModel.name}</strong></Link>
      );
    }//END INVITE_TO_CLASS

    //CREATE_POST
    if(feed.action == 'CREATE_POST'){
      content.push(
        <span><span>{t('HOME_POSTED_IN_CLASS')} <Link to="#" onClick={()=>redirecttoClass(feed.whereModel.id)}  ><strong>{feed.whatModel.name}</strong></Link></span><br /><div className="classBoardContent">{feed.whereModel.text}</div></span>
      );
    }//END CREATE_POST

    //CREATE_NOTE
    if(feed.action == 'CREATE_NOTE'){
      content.push(
        <span>
          <span>{t('HOME_ADDED_NOTE')+" "}</span>
          <Link onClick={() => windowOpen(feed.whatModel.id)} ><strong>{feed.whatModel.noteName} </strong></Link> 
          {/* <Hidden smDown><Link to={"tabs/notes/"+feed.whereModel.id}><strong>{feed.whatModel.noteName} </strong></Link></Hidden> 
          <Hidden mdUp>{feed.whatModel.noteName}</Hidden>  */}
          {t('HOME_IN_CLASS')} <Link to="#" onClick={()=>redirecttoClass(feed.whereModel.id)} > <strong>{feed.whereModel.name}</strong></Link>
        </span>
      );

    if(feed.whatModel.fileThumbnailPath && feed.whatModel.type == 'DOCUMENT')
    {
      // uuid
      content.push(
        <div>
        <Link  onClick={() => windowOpen(feed.whatModel.id)}>
        <img className="img-thumbnail" src={feed.whatModel.fileThumbnailPath} alt={feed.whatModel.noteName} className="noteFileImage" />
          {/* <Hidden smDown><img className="img-thumbnail" src={feed.whatModel.fileThumbnailPath} alt={feed.whatModel.noteName} className="noteFileImage" /></Hidden> */}
        </Link>
          {/* <Hidden mdUp><img className="img-thumbnail" src={feed.whatModel.fileThumbnailPath} alt={feed.whatModel.noteName} className="noteFileImage" /></Hidden> */}
        </div>
      );
    }

    if(feed.whatModel.type == 'VIDEO' && feed.whatModel.fileUrl){
      content.push(
        <Iframe url={feed.whatModel.fileUrl} width="100%" height="auto" className="classBoardVideo" />
      );
    }

    if(feed.whatModel.type == 'VIDEO' && !feed.whatModel.fileUrl){
      content.push(
        <ReactPlayer url={feed.whatModel.filePath} controls={true} width="100%" height="auto" className="classBoardVideo"/>
      );
    }
    }//END CREATE_NOTE

    //CREATE_WALL_POST
    if(feed.action == 'CREATE_WALL_POST'){
      content.push(
        <span>{t('HOME_POSTED_IN_BOARDS')}<br /><div  className="classBoardContent">{feed.whatModel.text}</div></span>
      );
    }//END CREATE_WALL_POST

    if(feed.createdDate < yesterday){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {moment(feed.createdDate).format(t('DATE_POSTED_SHORT'))}</div>
      )}
    else if(feed.createdDate < today && feed.createdDate > yesterday){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {t('DATE_POSTED_YESTERDAY')} {moment(feed.createdDate).format(t('TIME'))}</div>
      )}
    else if(feed.createdDate > today){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {t('DATE_POSTED_TODAY')} {moment(feed.createdDate).format(t('TIME'))}</div>
      )}
    return (content);
  }
  //Notifications Menu Open
  const handleNotificationsMenuOpen = (event) => {
    setNotificationsMenu(event.currentTarget);
  };

  //Notifications Menu Close
  const handleNotificationsMenuClose = () => {
    setNotificationsMenu(false);
  };

  //Post to Class Boards
  const handleSubmitPostToClassBoard = () => {
    if(postToClassBoards.length<10){
      setPostToClassBoardsError(true);
      setPostToClassBoardsErrorMessage(t('MSG_INVALID_POST_CLASS_BOARD'));
    }else{
      setPostToClassBoardsError(false);
      setPostToClassBoardsErrorMessage(t(''));
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      let headers={
        headers: {
          'jot-token': user.token,
          'text': postToClassBoards,
          'postToWall': true
        },
        timeout: 20000,
        validateStatus: (status) => status >= 200 && status < 300
      };
      let postData=postToClassBoards;
      axios.post(API_URL+'post', postData, headers)
      .then((response)=>{
          setPostToClassBoards('');
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
          reloadClassBoards();
      })
      .catch((errors)=>{
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_ERROR')
            }
          });
      })
    }
  }

  const notificationsMenuId = 'primary-notification-menu';
  const renderNotificationsMenu = (
    // <Menu
    //   anchorEl={notificationsMenu}
    //   getContentAnchorEl={null}
    //   anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    //   transformOrigin={{ vertical: "top", horizontal: "center" }}
    //   id={notificationsMenuId}
    //   keepMounted
    //   open={notificationsMenu}
    //   onClose={handleNotificationsMenuClose}
    // >
    //   <MenuItem onClick={handleNotificationsMenuClose}>{t('MSG_NO_NOTIFICATIONS')}</MenuItem>
    // </Menu>
    <DashboardNotifications notificationsMenu={notificationsMenu} notificationsMenuId={notificationsMenuId} handleNotificationsMenuClose={handleNotificationsMenuClose} setDashboardNotificationsCount={setDashboardNotificationsCount}  />
  );
  
  return (
    <>
    <Paper className={classes.paper}>
      <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
      <Card>
          <div className="header">
              <Typography variant="h6">
              {t('HOME_CLASS_BOARDS')}
              </Typography>
              <IconButton onClick={handleNotificationsMenuOpen}>
                <Badge badgeContent={dashboardNotificationsCount} color="primary">
                  <FontAwesomeIcon className="bellIcon" icon={faBell} />
                </Badge>
              </IconButton>
          </div>
          <List>
              <ListItem divider className="listItem">
                <TextField
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      handleSubmitPostToClassBoard(); // here was the mistake
                    }
                  }}
                value={postToClassBoards}
                onChange={(e)=>setPostToClassBoards(e.target.value)}
                id="postToClassBoards"
                placeholder={t('HOME_POST_CLASS_BOARDS')}
                name="postToClassBoards"
                error={postToClassBoardsError}
                helperText={postToClassBoardsErrorMessage}
                disabled={user.semester? user.semester.past : ''}
                placeholder={t('HOME_POST_CLASS_BOARDS')} variant="outlined" fullWidth className="postInput" InputProps={{endAdornment: <Link onClick={handleSubmitPostToClassBoard}><FontAwesomeIcon className="textFieldChevronIcon" icon={faChevronCircleRight} /></Link>}} />
              </ListItem>
              {(findClassBoards.length>0)?
              findClassBoards.map((feed, i) => (
              <ListItem key={i} divider={((i+1)!=findClassBoards.length)}>
                <ListItemAvatar>
                  <UserAvatar profile={feed.user}  userClasses={feed.user.classes} />
                </ListItemAvatar>
                <div>
                  {classBoardContent(feed)}
                </div>
              </ListItem>
              )):
              <ListItem className="listItem">
                  {t('MSG_NO_POSTS_CLASS_BOARDS')}
              </ListItem>
              }
          </List>
      </Card>
    </Grid>
    </Grid>
            {renderNotificationsMenu}
    </Paper>
    <Dialog
          fullScreen 
                    className="forWidth"
                    contentStyle={{
                      width: "80%",
                      height: "100%",
                      maxWidth: "none",
                    }}
                    onClose={windowClose}                    
                    open={iframeOpen}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <DialogActions className="draggable">
                    <Tooltip title={t('CLOSE_NOTE')}  arrow>
                    <IconButton className={"MyCustomButton"} onClick={()=>windowClose()}>
                      <CloseIcon />
                    </IconButton>
                    </Tooltip>
                  </DialogActions>
                    
                    <DialogContent className="DialogIframe">
                    <div class="iframe-wrapper">
                      <Iframe
                        url={
                          "https://rc.jot.academy/flowpaper/#/view-note/" +
                          noteId +
                          "/" +
                          user.token
                        }
                        width="100%"
                        height="520px"
                        id="myId"
                        className="iFrameDesign"
                        display="initial"
                        position="relative"
                      />
                      </div>
                    </DialogContent>
                  </Dialog>
    </>
  );
}

export default DashboardClassBoards;