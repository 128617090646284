import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  

const AddTask = ({ onClose, open, data, reloadTasks }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [taskName, setTaskName] = useState('');
  const [taskLocation, setTaskLocation] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [description, setDescription] = useState('');

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      // {
      //   "description": "string",
      //   "endTime": "2020-09-30T20:17:16.404Z",
      //   "id": "string",
      //   "location": "string",
      //   "name": "string",
      //   "recurrencePattern": "string",
      //   "reminderSeconds": "string",
      //   "startTime": "2020-09-30T20:17:16.404Z",
      //   "toDo": true
      // }
      let taskData={
        completed: false,
        name: taskName,
        location: taskLocation,
        startTime: startDate,
        //endTime: dueDate.setHours(12, 0, 0, 0),
        endTime: dueDate,
        description: description,
        toDo: true,
        recurrencePattern: "FREQ=DAILY;INTERVAL=1;COUNT=1",
        reminderSeconds: null
      }
      axios.post(API_URL+'event', taskData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setTaskName('');
        setDueDate(new Date());
        setDescription('');
        reloadTasks();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(taskName==''){
      formIsValid = false;
      errorsData["taskName"] = t('MSG_EMPTY_TASK');
    }

    if(taskName!=''&&taskName.length<3){
      formIsValid = false;
      errorsData["taskName"] = t('MSG_SHORT_TASK');
    }

    if(taskName!=''&&taskName.length>50){
      formIsValid = false;
      errorsData["taskName"] = t('MSG_LONG_TASK');
    }

    if(startDate=='' || startDate==null){
        formIsValid = false;
        errorsData["startDate"] = t('MSG_INVALID_DATE');
    }

    if(dueDate=='' || dueDate==null){
        formIsValid = false;
        errorsData["dueDate"] = t('MSG_INVALID_DATE');
    }

    if(description!=''&&description.length>3000){
        formIsValid = false;
        errorsData["description"] = t('MSG_LONG_DESCRIPTION');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-task" open={open}>
      <DialogTitle>{t('EVENT_ADD_TASK')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
              className="alladdinputMain"
                value={taskName}
                onChange={(e)=>setTaskName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="taskName"
                placeholder={t('EVENT_ADD_TASK_NAME')}
                name="taskName"
                autoComplete="taskName"
                error={errors["taskName"]}
                helperText={errors["taskName"]}
                autoFocus
              />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
             className="alladdinput"
                minDate={new Date()}
                fullWidth
                inputVariant="outlined"
                margin="normal"
                error={errors["startDate"]}
                helperText={errors["startDate"]}
                id="startDate"
                name="startDate"
                placeholder={t('DATE_START')}
                value={startDate}
                onChange={handleStartDateChange}
            />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
             className="alladdinput"
                minDate={new Date()}
                fullWidth
                inputVariant="outlined"
                margin="normal"
                error={errors["dueDate"]}
                helperText={errors["dueDate"]}
                id="dueDate"
                name="dueDate"
                placeholder={t('EVENT_END_ON_DATE')}
                value={dueDate}
                onChange={handleDueDateChange}
            />
            </MuiPickersUtilsProvider>
            <TextField
             className="alladdinput"
                value={taskLocation}
                onChange={(e)=>setTaskLocation(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="taskLocation"
                placeholder={t('EVENT_LOCATION')}
                name="taskLocation"
                autoComplete="taskLocation"
                error={errors["taskLocation"]}
                helperText={errors["taskLocation"]}
                autoFocus
              />
            <TextField
             className="alladdinput"
                value={description}
                multiline
                rows={4}
                onChange={(e)=>setDescription(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                placeholder={t('EVENT_DESCRIPTION')}
                name="description"
                autoComplete="description"
                error={errors["description"]}
                helperText={errors["description"]}
                autoFocus
              />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('EVENT_ADD_TASK')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default AddTask;