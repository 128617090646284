import React, {useEffect, useState,useRef} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl,Grid, Select, FormHelperText,ListItem, ListItemAvatar } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
// auto suggest input
import PlacesAutocomplete from 'react-places-autocomplete';
// auto suggest input
import axios from 'axios'; 
import UserAvatar from '../User/UserAvatar';
// import UserAvatar from '../User/UserAvatar';
import * as moment from 'moment'; 

const AddTerm = ({ onClose, open, data=null, reloadProfile, addTerm=true,loadSemesterData,fieldEnable }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [termName, setTermName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // term data
  const [termData,setTermData]= useState([]);
  const [display,setDisplay] = useState(false);
  const [search,setSearch] = useState("");
  const wrapperRef = useRef(null);
  // onselection of semester join term
  const [showJoin,setShowJoin] = useState(false);
  const [joinData,setJoinData] = useState([]);
  const[disableInput,setDisableInput] = useState(true);
  // const[disableInput,setDisableInput] = useState('disabled');
  const[hideDisplay,setHideDisplay] = useState(false);

  
  useEffect(()=>{
    
    // for add term make field enabled start
    if(fieldEnable){
      setDisableInput(false);
      setHideDisplay(true);
    }
    // for add term make field enabled end

    if(data && data.id){
      // if past term make field read only start
      if(user.role==="ADMIN" && !data.past){
    

        setDisableInput(false);
        setHideDisplay(true);
      }
      if(user.role==="SUPERADMIN" && !data.past){
     

      setDisableInput(false);
      setHideDisplay(true);
      }
      // if past term make field read only end

      setSearch(data.name);
      // setTermName(data.name);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
    term();
   
  }, [fieldEnable,user,data]);





  //term logic 1 start
  const term =() =>{

  
    axios.get((API_URL + 'semester/university/'+user.university.id + '/future'), headers)
    .then((response)=>{
      setTermData(response.data);
    }).catch((error)=>{

    });
  }
  //term logic 1 end

  //term logic 2 start
  //if user types set termname start
  useEffect(()=>{
    setTermName(search)
  },[search])
  //if user types set termname end

// if user clicks outside dropdown close dropdown start
  useEffect(()=>{
    document.addEventListener('mousedown',handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  },[])

  const handleClickOutside = event =>{
    const {current:wrap} = wrapperRef;
    if(wrap && !wrap.contains(event.target)){
      setDisplay(false);
    }
  }
// if user clicks outside dropdown close dropdown end

 
  //if user selects set term name start
  const setTermValue = (name) =>{
    var index_to_get = termData.findIndex(x => x.name.toLowerCase() ===name.toLowerCase());
    setJoinData(termData[index_to_get]);
    setShowJoin(true);
    setSearch(name);
    setDisplay(false);
    setTermName(search)
  }
  //if user selects set term name end

  const joinTerm = (semester) =>{
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });

    axios.put((API_URL + 'semester/join/'+semester.id), null, headers)
    .then((response)=>{
      setServerError('');
      setSearch('');
      setTermName('');
      setShowJoin(false);
      setJoinData([]);
      reloadProfile();
      onClose();
      loadSemesterData();
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_SUCCESS')
        }
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })

  }
  const setSearching = (e) =>{
setSearch(e);
setShowJoin(false)
 setJoinData([]);
  }

  //term logic 2 end

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      if(data && data.id){
        let termData={
          ...data,
          name: termName,
          startDate: startDate,
          endDate: endDate
        }
        axios.put(API_URL+'semester', termData, headers)
        .then(response => {
         
          loadSemesterData();
         dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          reloadProfile();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
         
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }else{
        let termData={
          name: termName,
          startDate: startDate.setHours(12, 0, 0, 0),
          endDate: endDate.setHours(12, 0, 0, 0),
          universityId: user.universityId,
          id: ""
        }
        axios.post(API_URL+'semester', termData, headers)
        .then(response => {
          loadSemesterData();
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          setSearch('');
          setTermName('');
          setStartDate('');
          setEndDate('');
          reloadProfile();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(termName==''){
      formIsValid = false;
      errorsData["termName"] = t('MSG_EMPTY_TITLE');
    }

    if(termName!=''&&termName.length<3){
      formIsValid = false;
      errorsData["termName"] = t('MSG_SHORT_TITLE');
    }

    if(termName!=''&&termName.length>50){
      formIsValid = false;
      errorsData["termName"] = t('MSG_LONG_TITLE');
    }

    if(startDate=='' || startDate==null){
        formIsValid = false;
        errorsData["startDate"] = t('MSG_INVALID_DATE');
      }

    if(endDate=='' || endDate==null){
        formIsValid = false;
        errorsData["endDate"] = t('MSG_INVALID_DATE');
      }

    setErrors(errorsData);
    return formIsValid;
  }

  const setCurrentSemester = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });

    axios.put((API_URL + 'semester/current/'+data.id), null, headers)
    .then((response)=>{
      reloadProfile();
      onClose();
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_SUCCESS')
        }
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-term" open={open}>
      <DialogTitle>{(data && data.id)?t('SEMESTER_EDIT'):t('SEMESTER_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <TextField
                value={search}
                onChange={(event) => setSearching(event.target.value)}
                onClick={() => setDisplay(!display)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="termName"
                placeholder={t('SEMESTER_ADD')}
                name="termName"
                autoComplete="off"
                error={errors["termName"]}
                helperText={errors["termName"]}
                autoFocus
                className="mb-2"
                disabled={disableInput}
              />
          {display && hideDisplay && termData.length> 0 &&(
            <>
        <div className="autoContainer" ref={wrapperRef}>
          {termData.filter(({name})=>name.toLowerCase().indexOf(search.toLowerCase())>-1).map((v,i)=>{
            return (
              <div onClick={() => setTermValue(v.name)} className="option" key={i}>
                <span>{v.name}</span>
              </div>
            )
          })}
          </div>
          </>
          )
          }
          </FormControl>
          {(showJoin && joinData)? <> 
         
            <ListItem className="joinTerm" >
                      <ListItemAvatar>
                        <UserAvatar profile={joinData.creator} />
                      </ListItemAvatar>
                      <div className="insidejoinTerm">
                        <div><strong>{joinData.name}</strong></div>
                        <div>{moment(joinData.startDate).format(t('ONLY_DATE_POSTED'))} - {moment(joinData.endDate).format(t('ONLY_DATE_POSTED'))}</div>
                        <div className="italic">{t('CREATOR')} {joinData.creator.firstName + ' ' + joinData.creator.lastName}</div>
                      </div>
                     
                    </ListItem>
                   
          </> : <>  <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  margin="normal"
                  error={errors["startDate"]}
                  helperText={errors["startDate"]}
                  id="startDate"
                  name="startDate"
                  placeholder={t('DATE_START')}
                  format="MMMM dd, yyyy"
                  value={startDate}
                  onChange={handleStartDateChange}
                  disabled={disableInput}
              />
              </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                margin="normal"
                error={errors["endDate"]}
                helperText={errors["endDate"]}
                id="endDate"
                name="endDate"
                placeholder={t('DATE_END')}
                format="MMMM dd, yyyy"
                value={endDate}
                onChange={handleEndDateChange}
                disabled={disableInput}
            />
            </MuiPickersUtilsProvider> </>
            } 
            {data && data.id && user.semester && user.semester.id != data.id && <Button
                  onClick={setCurrentSemester}
                  fullWidth
                  variant="contained"
                  className="blueButton mt-3"
                >
                  {t('SEMESTER_SWITCH')}
                </Button>}
            {(data && data.id)?
              ((!data.past) &&
              <>
              <div>
                 {user.id == data.creator.id && <>  <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="mt-3"
                >
                  { t('BTN_UPDATE')  }
                </Button> </>}
             
              </div>
              </>
              )
            :
            <>
            {(showJoin && joinData) ? 
             <Button variant="contained" className="orangeButton" onClick={()=>joinTerm(joinData)}>{t('SEMESTER_JOIN')}</Button>
           
            : 
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_ADD')}
            </Button>}
            
            </>}
          </form>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default AddTerm;