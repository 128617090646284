import React, { useEffect, useState, useRef } from 'react';
import {useStateValue} from '../../store/StateProvider';
import { FormControlLabel, Switch, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';
import ChatUser from './ChatUser';
import UserAvatar from '../User/UserAvatar';
import axios from 'axios';
import { API_URL, axiosConfig } from '../../api/index';
import AddChatGroup from './AddChatGroup';

const ChatSidebar = ({selectedChat, setSelectedChat, setReceivedMessage, setChatDetailData, setReloadFlag, reloadFlag, page, setPage,responsive=false,setResponsive=false,showSideBar=false,setShowSideBar=false,showContent=false,setShowContent=false}) => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [isOnline, setIsOnline] = React.useState(true);
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{ snack }, dispatchSnack] = useStateValue();
  const headers = axiosConfig(user.token);
  const [addChatGroupOpen, setAddChatGroupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [{ sctn }] = useStateValue();
  const [{ chatWithParam }, dispatchSetChatWithParam] = useStateValue();
  let isRendered = useRef(false);

  //Chat from Routes
  useEffect(() => {
    if (chatWithParam && sctn && data.length > 0) {
      var elementPos = data.map(function (x) { return x.id; }).indexOf(chatWithParam);
      var objectFound = data[elementPos];
      if (objectFound) {
        //setShowData(true);
        setSelectedChat(objectFound);

        //for notification route
        //setFirstTime(true);
      }
    }

  }, [chatWithParam, data, sctn]);

  //Subscribe
  useEffect(() => {
    isRendered = true;
    let sub1 = null;
    let sub2 = null;
    let sub3 = null;
    if (sctn && sctn.connected) {
      data.map((chat, i) => {
        sub1 = sctn.subscribe('/topic/chatEvents' + user.id, (eventData) => {
        });

        //On user change status
        sub2 = sctn.subscribe('/topic/status/user' + chat.id, (userData) => {
          let mdata = JSON.parse(userData.body);
          chat.chatActiveStatus = mdata.chatActiveStatus;
          let newArr = [...data];
          newArr[i] = chat;
          setData(newArr);
        });

        //On new message
        sub3 = sctn.subscribe((chat.group ? "/topic/chatGroup" : "/topic/user" + user.id) + chat.id, (groupData) => {
          if(isRendered){
            handleCount(groupData, chat.id);
            changeChatOrder(chat.id, i);
            setReceivedMessage(groupData);
          }
        });
      });
    }
    return () => {
      if (sub1)
        sub1.unsubscribe();
      if (sub2)
        sub2.unsubscribe();
      if (sub3)
        sub3.unsubscribe();
      isRendered = false;
    }

  }, [selectedChat, sctn]);
  //End Subscribe

  // useEffect(()=>{
    
  // }, [selectedChat]);

  const handleCount = (socketData, chatId) => {
    let body=socketData.body;
    //let fromUser=JSON.parse(body).fromUser;
    let tempArray=data;
    //if(fromUser.id!=selectedChat.id){
    if(chatId!=selectedChat.id){
      //let index=tempArray.findIndex(d => d.id == fromUser.id);
      let index=tempArray.findIndex(d => d.id == chatId);
      if(tempArray[index]){
        tempArray[index].countNewMessages++;
        setData(tempArray);
      }
    }
  }

  const changeChatOrder = (chatId, i) => {
    if(chatId!=selectedChat.id){
      data.unshift(data.splice(i, 1)[0]);
    }
  }

  useEffect(()=>{
    fetchChatData();
    setReloadFlag(false);
  }, [reloadFlag]);

  const fetchChatData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    const findChatGroupsByUser = axios.get(API_URL + 'chat/group', headers);
    const findAllUsersByTokenAndTakingClasses = axios.get(API_URL + 'user/taking/classes', headers);
    axios.all([findChatGroupsByUser, findAllUsersByTokenAndTakingClasses])
      .then(axios.spread((...response) => {
        let chatGroupsAndUsers = [];
        if (response[0].data && response[0].data.length > 0) {
          response[0].data.map((chatGroupItem) => {
            chatGroupsAndUsers.push({
              'id': chatGroupItem.id,
              'name': chatGroupItem.name,
              'group': true,
              'countNewMessages': chatGroupItem.countNewMessages,
              'lastMessageCreatedDate': chatGroupItem.lastMessageCreatedDate ? chatGroupItem.lastMessageCreatedDate : new Date(1, 0, 1, 0, 0, 0).getTime(),
              'lastMessageViewedDate': chatGroupItem.lastMessageViewedDate,
              'users': chatGroupItem.users,
              'profileImageThumbnail': "img/user-group.png",
              'creator': (chatGroupItem.creator && chatGroupItem.creator.id) ? chatGroupItem.creator.id : null
            });
          });
        }
        if (response[1].data && response[1].data.length > 0) {
          response[1].data.map((chatUserItem) => {
            if (chatUserItem.id != user.id) {
              chatGroupsAndUsers.push({
                'id': chatUserItem.id,
                'name': chatUserItem.firstName + " " + chatUserItem.lastName,
                'firstName': chatUserItem.firstName,
                'lastName': chatUserItem.lastName,
                'group': false,
                'countNewMessages': chatUserItem.userChatDataOutPayload.countNewMessages,
                'lastMessageCreatedDate': chatUserItem.userChatDataOutPayload.lastMessageCreatedDate ? chatUserItem.userChatDataOutPayload.lastMessageCreatedDate : new Date(1, 0, 1, 0, 0, 0).getTime(),
                'lastMessageViewedDate': chatUserItem.userChatDataOutPayload.lastMessageViewedDate,
                'chatActiveStatus': chatUserItem.chatActiveStatus,
                'profileImageThumbnail': chatUserItem.profileImageThumbnail,
                'professor': chatUserItem.professor
              });
            }
          });
        }
        chatGroupsAndUsers.map((c, i)=>{
          if(c.countNewMessages>0){
            chatGroupsAndUsers.unshift(chatGroupsAndUsers.splice(i, 1)[0]);
          }
        });

        setData(chatGroupsAndUsers);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });

      }))
      .catch((errors) => {
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
  }

  const handleChange = (event) => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    setIsOnline(event.target.checked);
    axios.put(API_URL + "user/chat?status=" + !isOnline, null, headers).then(() => {
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    }).catch(() => {
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
    });
  };

  const handleAddChatGroupOpen = () => {
    setAddChatGroupOpen(true);
  };
  const handleAddChatGroupClose = () => {
    setAddChatGroupOpen(false);
  };

  return (
    <div id="sidepanel"  style={{display: responsive && showSideBar ? 'block' :  !responsive ? 'block' : 'none' }}>
      <div id="profile">
        <div className="wrap">
          <div className="img">
            <UserAvatar profile={user} />
          </div>
          <p>{user.firstName + ' ' + user.lastName}</p>
        </div>
      </div>
      <div id="search">
        <label for=""><i className="fa fa-search" aria-hidden="true"></i></label>
        <input type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          id="searchText"
          placeholder={t('CHATS_SEARCH')}
          name="searchText" />
      </div>
      <div id="contacts">
        <ul>
          {(data && data.length > 0) ?
            (data
              .filter(item => {
                if (!searchText) return true
                if (((item.name).toLowerCase()).includes(searchText.toLowerCase())) {
                  return true
                }
              }).map((chatItem, i) => (
                <ChatUser lastItem={((data.length-1)==i)?true:false} chatItem={chatItem} i={i} selectedChat={selectedChat} setSelectedChat={setSelectedChat} setChatDetailData={setChatDetailData} page={page} setPage={setPage} responsive={responsive} setResponsive={setResponsive} showSideBar={showSideBar} setShowSideBar={setShowSideBar} showContent={showContent} setShowContent={setShowContent}/>
              )))
            :
            null
          }
        </ul>
      </div>
      {/* bottom add group or online status button start */}
      <div id="bottom-bar">
        <button className="button1" onClick={handleAddChatGroupOpen} id="addcontact">
          <i className="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span className="addGroup">Add Group</span></button>
        <button id="settings" className="button2">
          <FormControlLabel
            control={<Switch checked={isOnline} onChange={handleChange} name="isOnline" color="primary" />}
            label={(isOnline) ? t('CHATS_ONLINE') : t('CHATS_OFFLINE')}
          />
        </button>
      </div>
      {/* bottom add group or online status button end */}
      {/* add chat group modal open start */}
      <Dialog fullWidth onClose={handleAddChatGroupClose} aria-labelledby="add-class" open={addChatGroupOpen}>
        <DialogTitle>{t('CHATS_ADD_GROUP')}</DialogTitle>
        <DialogContent className="modalContent">
          <AddChatGroup onClose={handleAddChatGroupClose} reloadChat={fetchChatData} />
        </DialogContent>
      </Dialog>
      {/* add chat group modal open end */}
    </div>
  );
}

export default ChatSidebar;