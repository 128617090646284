import React, {useState, useEffect} from 'react';
import { InputLabel, TextField, Button, Dialog, DialogTitle, DialogContent, Card, Typography, FormControl, Select, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import UserAvatar from '../User/UserAvatar';

const AddChatGroup = ({ onClose, reloadChat, data={} }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [groupName, setGroupName] = useState('');
  const [members, setMembers] = useState([]);
  const [checkedArray, setCheckedArray]=([])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    setErrors({});
      let ids=[];
      if(members.length>0){
        members.map((member)=>{
          if(member.checked){
            ids.push(member.id);
          }
        });
      }
      let submitData={
        "ids": ids,
        "name": groupName
      };
      axios.post(API_URL+'chat/group', submitData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        reloadChat();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }

  useEffect(()=>{
    fetchStudents();
  }, []);

  const fetchStudents = () =>{
      dispatchSetLoading({
          type: 'SET_LOADING',
          loading: true
      });
      axios.get(API_URL + 'user/taking/classes', headers)
      .then((response)=>{
          setMembers(response.data);
          dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
          });
      })
      .catch((errors)=>{
          dispatchSnack({
          type: 'SET_SNACK',
          snack: {
              flag: true,
              message: t('MSG_LOAD_DATA_ERROR')
          }
          });
          dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
          });
          //console.log(errors);
      })
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(groupName==''){
      formIsValid = false;
      errorsData["groupName"] = t('MSG_EMPTY_GROUP_CHAT');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  const handleChange = (event, member, i) => {
    let tempMembers=[...members];
    let tempMember={...members[i]};
    tempMember.checked=event.target.checked;
    tempMembers[i]=tempMember;
    setMembers(tempMembers);
  }

  return (
    <div>
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={groupName}
                onChange={(e)=>setGroupName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="groupName"
                placeholder={t('GROUP_NAME')}
                name="groupName"
                autoComplete="groupName"
                error={errors["groupName"]}
                helperText={errors["groupName"]}
                autoFocus
              />
              {members.length>0 && members.map((member, i) => (
              ((member.id!=user.id) &&
              <div>
                <FormControlLabel
                  control={<Checkbox onChange={(event)=>{handleChange(event, member, i)}} checked={(member && member.checked?true:false)} name="gilad" />}
                  label={
                  <div className="mb-2">
                    <div className="newGroupChat">
                      <UserAvatar profile={member} />
                      <span className="newGroupChatName">
                        {member.firstName + " " + member.lastName}
                      </span>
                    </div>
                  </div>
                }
                />
              </div>
              )
              ))}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SAVE')}
            </Button>
          </form>
        </div>
      </div>
);
}

export default AddChatGroup;