import React, {useMemo, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,Checkbox,FormControlLabel,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,faPen,faEye,faBan,faCheck,faTimes
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL, axiosConfig } from "../../api/index";
import { useStyles } from "../../assets/styles/materialStyles";
import { useStateValue } from "../../store/StateProvider";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Table from "./Table" ;
/* React Datatble */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import BlockUser from "./BlockUser";
import ViewUser from "./ViewUser"
import UserAvatar from '../User/UserAvatar'; 
import TableEnhancedHead from "./TableEnhancedHead"

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function Administrator() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ university,setUniversity] = useState('');
  const [{user}] = useStateValue();
  const headersWithToken = axiosConfig(user.token);
  const [tableData,setTableData] = useState([]);
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const { t } = useTranslation();
  const [addModalOpen,setAddModalOpen] = useState(false);
  const [editModalOpen,setEditModalOpen] = useState(false);
  const [viewModalOpen,setViewModalOpen] = useState(false);
  const [deleteModalOpen,setDeleteModalOpen] = useState(false);
  const [userEditData,setUserEditData] = useState([]);
  const [userViewData,setUserViewData] = useState([]);
  const [userBlockData,setUserBlockData] = useState([]);
  const [addUserUniversityId,setAddUserUniversityId]= useState('');
  const [universities,setUniversities] = useState([]);
  const[universityId,setUniversityId] = useState();
  const [filterFn,setFilterFn] = useState({fn: items =>{return items}});
  //to collapse first accordion on opening of second
  const [expanded, setExpanded] = React.useState(false);
  const [errors, setErrors] = useState({});
  //to pass university id for university semester
  const[universityDatum,setUniversityDatum]= useState();


  // to collapse first accordion on opening of second method start
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // to collapse first accordion on opening of second method end




  useEffect(()=>{
  if(user.university && user.role=="ADMIN"){
  setUniversity(user.university);
  setAddUserUniversityId(user.university.id);

}
fetchAdministratorData();
  },[]);

  // to reload admin data exclusively start 
 const reloadTableData = () =>{
  axios.get(API_URL + "administrator/university/"+user.university.id,headersWithToken)
  .then((response) => {
    setTableData(response.data);
  dispatchSetLoading({
      type: "SET_LOADING",
      loading: false,
    });
  })
  .catch((errors) => {
    dispatchSnack({
      type: "SET_SNACK",
      snack: {
        flag: true,
        message: t("MSG_LOAD_DATA_ERROR"),
      },
    });
  });

  }
  // to reload admin data exclusively end 


 const fetchAdministratorData = () =>{
   if(user.role=="SUPERADMIN" ){
    dispatchSetLoading({
      type: "SET_LOADING",
      loading: true,
    });
    axios.get(API_URL + "university/active/",headersWithToken)
    .then((response) => {
     setUniversities(response.data);
    dispatchSetLoading({
        type: "SET_LOADING",
        loading: false,
      });
    })
    .catch((errors) => {
      dispatchSnack({
        type: "SET_SNACK",
        snack: {
          flag: true,
          message: t("MSG_LOAD_DATA_ERROR"),
        },
      });
    });



   }
   else{
    dispatchSetLoading({
      type: "SET_LOADING",
      loading: true,
    }); 
      axios.get(API_URL + "administrator/university/"+user.university.id,headersWithToken)
.then((response) => {
  setTableData(response.data);
dispatchSetLoading({
    type: "SET_LOADING",
    loading: false,
  });
})
.catch((errors) => {
  dispatchSnack({
    type: "SET_SNACK",
    snack: {
      flag: true,
      message: t("MSG_LOAD_DATA_ERROR"),
    },
  });
});
 }
 }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const isSelected = (name) => selected.indexOf(name) !== -1;


  /* add edit view delete modal start */
  const handleAddUserModalOpen = (e=null) =>{
      setUniversityDatum(e)
    setAddModalOpen(true)
  }
  const handleAddUserModalClose = () =>{
    setAddModalOpen(false)
    setErrors({}); 

  }
  const handleEditModalOpen = (row) =>{
    setUserEditData(row)
    setEditModalOpen(true);  
  }
  const handleEditModalClose = () =>{
    setEditModalOpen(false); 
    setErrors({}); 
   }

  const handleViewModalOpen = (row) =>{
    setUserViewData(row)
    setViewModalOpen(true)
  }
  const handleViewModalClose = () =>{
    setViewModalOpen(false)
  }
  const handleDeleteModalOpen = (row) =>{
    setUserBlockData(row);
    setDeleteModalOpen(true)
  }
  const handleDeleteModalClose = () =>{
    setDeleteModalOpen(false)
  }
  /* add edit view delete modal end */

  // checkbox logic to make a person admin start
const handleCheck =(e,row) =>{
  dispatchSetLoading({
    type: "SET_LOADING",
    loading: true,
  }); 
  var newRole = row.role === 'USER' ? 'ADMIN' : 'USER';
axios.put(API_URL + 'administrator/user/' + row.id + '/role/' + newRole, null, headersWithToken).then(response => {
  // console.log('response')
  dispatchSnack({
    type: 'SET_SNACK',
    snack: {
      flag: true,
      message: t('MSG_SUCCESS')
    }
  });
  if(universityId){
    reloadSuperAdminTableData();
  }else{
    fetchAdministratorData();
  }
 }).catch((error)=>{ 
 });
}
  // checkbox logic to make a person admin end


const handleSearch =(e) =>{
  let target = e.target;
  
  setFilterFn({
    fn: items =>{
      if(target.value ==""){

        return items;
      }
      else {
          return items.filter(x => x.firstName.toLowerCase().includes(target.value.toLowerCase()) ||  x.lastName.toLowerCase().includes(target.value.toLowerCase()) || x.email.toLowerCase().includes(target.value.toLowerCase()))
      }
    }
  })
}
// to avoid double loader exclusive api called start
const reloadSuperAdminTableData = () =>{
  axios.get(API_URL + "administrator/university/"+universityId,headersWithToken)
.then((response) => {
setTableData(response.data);
dispatchSetLoading({
  type: "SET_LOADING",
  loading: false,
});
})
.catch((errors) => {
dispatchSnack({
  type: "SET_SNACK",
  snack: {
    flag: true,
    message: t("MSG_LOAD_DATA_ERROR"),
  },
});
});
//to load table data for superadmin end
}
// to avoid double loader exclusive api called end

//to load table data for superadmin start
useEffect(()=>{
if(universityId){
  loadSuperAdminTableData();
}
},[universityId])
const loadSuperAdminTableData = () =>{
  //stops loader to run again once it is already running start 
   dispatchSetLoading({
     type: "SET_LOADING",
     loading: true,
   }); 
  //stops loader to run again once it is already running end 
  axios.get(API_URL + "administrator/university/"+universityId,headersWithToken)
.then((response) => {
setTableData(response.data);
dispatchSetLoading({
  type: "SET_LOADING",
  loading: false,
});
})
.catch((errors) => {
dispatchSnack({
  type: "SET_SNACK",
  snack: {
    flag: true,
    message: t("MSG_LOAD_DATA_ERROR"),
  },
});
});
//to load table data for superadmin end
}

  return (
    <>
    {user.role=="SUPERADMIN" &&
    <>
    {universities && universities.length > 0 &&
    (universities.map((universityData,i)=>(
      <Accordion className="mb-3" expanded={expanded === i} onChange={handleAccordionChange(i)}> 
      <div className="accordionHeader">
      <AccordionSummary onClick={()=>setUniversityId(universityData.id)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header" >
         <Link to="#" ><Typography variant="h6">{universityData.name}</Typography></Link>
        </AccordionSummary>
        </div>
        {/* table search start */}
        <Grid container spacing={3} className={`${classes.root} ${classes.searchBarDatatable}`}   justify="space-between">
              <Grid item xs={12} lg={8} sm={8} className="searchBottom "  >
              <TextField
               onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch(e)
                              }
                            }}
                            id="search"
                            placeholder={t('USER_SEARCH')}
                            name="search"
                            variant="outlined" 
                            fullWidth
                            className="postInput mb-2 hsearch" 
                            size="small"
                              InputProps={{endAdornment: 
                              <FontAwesomeIcon className="textFieldSearchIcon onlySearchIcon" icon={faSearch} />}}
                            // InputProps={{endAdornment: <Link onClick={(e)=>handleSearch(e)}
                            //   >
                            //   <FontAwesomeIcon className="textFieldSearchIcon" icon={faSearch} /></Link>}}
                                />
              </Grid>
              <Grid item xs={12} lg={4} sm={4} className="mb-3">
              <Button onClick={() =>handleAddUserModalOpen(universityData)} variant="contained" className="yellowButton ">{t('USER_ADD')}</Button>
              </Grid>
              </Grid>
              {/* table search end */}
              <Grid item xs={12}>
                  <div className="contentContainer">
                  <div className={classes.root}>
          <Paper className={classes.paperSecondForDatable}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table" >
                <TableEnhancedHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                {stableSort(filterFn.fn(tableData), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
    
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected} padding="none" scope="row">
                           <TableCell className="cellDesign imageDesign" >
                           <UserAvatar viewUserModal={true}  profile={row} />
                            {/* <UserAvatar noModal profile={row} /> */}
                          </TableCell>
                          <TableCell  className="cellDesign">{row.firstName}</TableCell>
                          <TableCell  className="cellDesign">{row.lastName}</TableCell>
                          <TableCell  className="cellDesign">{row.email}</TableCell>
                          {/* if professor start */}
                          <TableCell  className="cellDesign">{row.professor? 
                          <FontAwesomeIcon className="checkIcon" icon={faCheck}  /> : <FontAwesomeIcon
                                className="timesIcon"  icon={faTimes}   />}</TableCell>
                          {/* if professor end */}
                          {/* if verified start */}
                          <TableCell  className="cellDesign">{row.verified? <FontAwesomeIcon className="checkIcon" icon={faCheck}  /> : <FontAwesomeIcon  className="timesIcon"  icon={faTimes}   />}</TableCell>
                          {/* if verified end */}
                          {/* if professor admin start*/}
                          {(row.professor==true)?
                          // if user sees his status start 
                          <>
                          {(user.id == row.id) ?  
                          <> 
                          <TableCell  className="cellDesign"  padding="none">
                          <FormControlLabel className="forDesign"
                          control={<Checkbox type="checkbox" onChange={(e)=>{handleCheck(e,row)}} id={"cbox_"+row.i}  disabled/>} checked={row && row.role=="ADMIN" ? true: false} name="gilad"/>
                          </TableCell>
                          </>
                          // if user sees his status end
                          // if user sees another teacher's status start
                          : 
                          <>
                          <TableCell  className="cellDesign"  padding="none">
                          <FormControlLabel className="forDesign"
                          control={<Checkbox type="checkbox" onChange={(e)=>{handleCheck(e,row)}} id={"cbox_"+row.i} />} checked={row && row.role=="ADMIN" ? true: false} name="gilad"/>
                          </TableCell>
                          </>
                          }
                          {/* if user sees another teacher's status */}
                          {/* if professor admin end*/}
                         {/* if Student start */}
                          </>
                          : <TableCell  className="cellDesign" >
                          </TableCell>}
                          {/* if Student end */}
                          <TableCell className="cellDesign">
                            <Grid item >
                            {/* <Tooltip title={t('USER_VIEWS')} arrow>
                            <IconButton className="actionButton" onClick={() => handleViewModalOpen(row)} >
                                <FontAwesomeIcon className="datatableIcon" icon={faEye} />
                            </IconButton>
                            </Tooltip> */}
                            <Tooltip title={t('USER_EDIT')} arrow>
                            <IconButton className="actionButton" onClick={() => handleEditModalOpen(row)}
                                >
                                <FontAwesomeIcon className="datatableIcon" icon={faPen} />
                            </IconButton>
                            </Tooltip>
    
                            {row.blocked?  
                            <>
                            <Tooltip title={t('USER_UNBLOCK')} arrow>
                              <IconButton className="actionButton" onClick={() => handleDeleteModalOpen(row)}
                                ><FontAwesomeIcon className="datatableIconGrey" icon={faBan} />
                            </IconButton>
                            </Tooltip> 
                            </>
                            :
                            <>
                            <Tooltip title={t('USER_BLOCK')} arrow>
                              <IconButton className="actionButton" onClick={() => handleDeleteModalOpen(row)}
                                ><FontAwesomeIcon className="datatableIcon" icon={faBan} />
                            </IconButton>
                            </Tooltip>
                            </> 
                            }
    
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              // filterFn.fn(tableData)
              // rowsPerPage={rowsPerPage}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        </div>
            </Grid>
        </Accordion>

    )))
    }
    </>
    }
    {user.role=="ADMIN" && <>
    <Grid item xs={12}>
        <Paper className={classes.paperforDatatable}>
          {university  && <>
            <Accordion>
            <div className="accordionHeader">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" >
               <Link to="#"><Typography variant="h6">{university.name}</Typography></Link>
              </AccordionSummary>
              </div>
              {/* table search start */}
              <Grid container spacing={3} className={`${classes.root} ${classes.searchBarDatatable}`}   justify="space-between">
              <Grid item xs={12} lg={8} sm={8} className="searchBottom "  >
              <TextField
               onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch(e)
                              }
                            }}
                            id="search"
                            placeholder={t('USER_SEARCH')}
                            name="search"
                            variant="outlined" 
                            fullWidth
                            className="postInput mb-2 hsearch" 
                            size="small"
                            InputProps={{endAdornment: 
                              <FontAwesomeIcon className="textFieldSearchIcon onlySearchIcon" icon={faSearch} />}}
                            // InputProps={{endAdornment: <Link onClick={(e) =>handleSearch(e)}
                            //   >
                            //   <FontAwesomeIcon className="textFieldSearchIcon" icon={faSearch} /></Link>}}
                                />
              </Grid>
              <Grid item xs={12} lg={4} sm={4} className="mb-3">
              <Button onClick={() =>handleAddUserModalOpen(university)} variant="contained" className="yellowButton ">{t('USER_ADD')}</Button>
              </Grid>
              </Grid>
              {/* table search end */}
              <Grid item xs={12}>
                  <div className="contentContainer">
                  <div className={classes.root}>
          <Paper className={classes.paperSecondForDatable}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table" >
                <TableEnhancedHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                {stableSort(filterFn.fn(tableData), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
    
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected} padding="none" scope="row">
                           <TableCell className="cellDesign imageDesign" >
                           <UserAvatar viewUserModal={true}  profile={row} />
                            {/* <UserAvatar noModal profile={row} /> */}
                          </TableCell>
                          <TableCell  className="cellDesign">{row.firstName}</TableCell>
                          <TableCell  className="cellDesign">{row.lastName}</TableCell>
                          <TableCell  className="cellDesign">{row.email}</TableCell>
                          {/* if professor start */}
                          <TableCell  className="cellDesign">{row.professor? 
                          <FontAwesomeIcon className="checkIcon" icon={faCheck}  /> : <FontAwesomeIcon
                                className="timesIcon"  icon={faTimes}   />}</TableCell>
                          {/* if professor end */}
                          {/* if verified start */}
                          <TableCell  className="cellDesign">{row.verified? <FontAwesomeIcon className="checkIcon" icon={faCheck}  /> : <FontAwesomeIcon  className="timesIcon"  icon={faTimes}   />}</TableCell>
                          {/* if verified end */}
                          {/* if professor admin start*/}
                          {(row.professor==true)?
                          // if user sees his status start 
                          <>
                          {(user.id == row.id) ?  
                          <> 
                          <TableCell  className="cellDesign"  padding="none">
                          <FormControlLabel className="forDesign"
                          control={<Checkbox type="checkbox" onChange={(e)=>{handleCheck(e,row)}} id={"cbox_"+row.i}  disabled/>} checked={row && row.role=="ADMIN" ? true: false} name="gilad"/>
                          </TableCell>
                          </>
                          // if user sees his status end
                          // if user sees another teacher's status start
                          : 
                          <>
                          <TableCell  className="cellDesign"  padding="none">
                          <FormControlLabel className="forDesign"
                          control={<Checkbox type="checkbox" onChange={(e)=>{handleCheck(e,row)}} id={"cbox_"+row.i} />} checked={row && row.role=="ADMIN" ? true: false} name="gilad"/>
                          </TableCell>
                          </>
                          }
                          {/* if user sees another teacher's status end*/}
                          {/* if professor admin end*/}
                         {/* if Student start */}
                          </>
                          : <TableCell  className="cellDesign" >
                          </TableCell>}
                          {/* if Student end */}
                          <TableCell className="cellDesign">
                            <Grid item >

                              {/* removing button to show modal on profile pic start */}
                            {/* <Tooltip title={t('USER_VIEWS')} arrow>
                            <IconButton className="actionButton" onClick={() => handleViewModalOpen(row)} >
                                <FontAwesomeIcon className="datatableIcon" icon={faEye} />
                            </IconButton>
                            </Tooltip> */}
                              {/* removing button to show modal on profile pic end */}

                            <Tooltip title={t('USER_EDIT')} arrow>
                            <IconButton className="actionButton" onClick={() => handleEditModalOpen(row)}
                                >
                                <FontAwesomeIcon className="datatableIcon" icon={faPen} />
                            </IconButton>
                            </Tooltip>
    
                            {row.blocked?  
                            <>
                            <Tooltip title={t('USER_UNBLOCK')} arrow>
                              <IconButton className="actionButton" onClick={() => handleDeleteModalOpen(row)}
                                ><FontAwesomeIcon className="datatableIconGrey" icon={faBan} />
                            </IconButton>
                            </Tooltip> 
                            </>
                            :
                            <>
                            <Tooltip title={t('USER_BLOCK')} arrow>
                              <IconButton className="actionButton" onClick={() => handleDeleteModalOpen(row)}
                                ><FontAwesomeIcon className="datatableIcon" icon={faBan} />
                            </IconButton>
                            </Tooltip>
                            </> 
                            }
    
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              // filterFn.fn(tableData)
              // rowsPerPage={rowsPerPage}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        </div>
            </Grid>
          </Accordion>
          </> }
        </Paper>
        </Grid>    
    </>}
    <AddUser
    open={addModalOpen} 
    onClose={handleAddUserModalClose}
    addUserUniversityId={addUserUniversityId}
    reloadTableData={reloadTableData}
    reloadSuperAdminTableData={reloadSuperAdminTableData}
    universityIdForAdd={universityId}
    universityDatum={universityDatum}
    setErrors={setErrors}
    errors={errors} />
    <EditUser
    open={editModalOpen}
    onClose={handleEditModalClose}
    userEditData={userEditData}
    reloadTableData={reloadTableData}
    reloadSuperAdminTableData={reloadSuperAdminTableData}
    universityId={universityId}
    setErrors={setErrors}
    errors={errors}  />
    <BlockUser
    open={deleteModalOpen}
    onClose={handleDeleteModalClose}
    userBlockData={userBlockData}
    reloadTableData={reloadTableData}
    reloadSuperAdminTableData={reloadSuperAdminTableData}
    universityId={universityId}/>
    <ViewUser
    open={viewModalOpen}
    onClose={handleViewModalClose}
    userViewData={userViewData} />
    </>
  );
}

