import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  
import DeleteIcon from '@material-ui/icons/Delete';

const InviteStudents = ({ onClose, open, data, reloadClasses }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [students, setStudents] = useState([]);
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  //const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //setSuccess(false);
    if(students.length>0){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      let emailsData=[];
      students.map((student)=>{
        emailsData.push({
          email: student
        });
      });
      let emails={
        emails: emailsData
      };
      const headers = axiosConfig(user.token);
      axios.post(API_URL+'invite/class/'+data.id+'/emails?type=student', emails, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setEmail('');
        reloadClasses();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_INVITE_SENT')
          }
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(email==''){
      formIsValid = false;
      errorsData["email"] = t('MSG_EMPTY_EMAIL');
    }

    if(email!=''){
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(reg.test(email.toLowerCase())==false){
        formIsValid = false;
        errorsData["email"] = t('MSG_INVALID_EMAIL');
      }
    }
    setErrors(errorsData);
    return formIsValid;
  }

  const handleAddStudent = () => {
    if(validateForm()){
      if(students.includes(email)){
        setErrors({
          "email": t('MSG_EMAIL_EXISTS')
        });
      }else{
        setStudents([
          ...students,
          email
        ])
        setEmail('');
      }
    }
  };

  const handleRemoveStudent = (email) => {
    setStudents(
      students => students.filter(item => item !== email)
    )
  };

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="invite-students-to-class" open={open}>
      {
        user.professor?
        <DialogTitle>{t('INVITE_STUDENTS_JOIN')+"  "+((data && data.name)?data.name:"")}</DialogTitle>
        :
        <DialogTitle>{t('INVITE_STUDENTS_JOIN')+"  "+((data && data.name)?data.name:"")}</DialogTitle>
      }
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <div>
        {(students.length>0) &&
        <div className="mb-3">
        <Paper variant="outlined">
          <List>
            {students.map((student, i) => (
              <ListItem key={i} divider={((i+1)!=students.length)}>
                <ListItemText>
                  {student}
                </ListItemText>
                <ListItemSecondaryAction>
                <IconButton aria-label="delete" onClick={()=>handleRemoveStudent(student)}>
                  <DeleteIcon />
                </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="mt-2"
        >
          {t('SEND_INVITE')}
        </Button>
        </div>
        }
        <div>
          <TextField
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder={t('EMAIL_ADDRESS')}
            name="email"
            autoComplete="email"
            error={errors["email"]}
            helperText={errors["email"]}
            autoFocus
          />
          <Button onClick={handleAddStudent} variant="contained" className="darkBlueButton">{t('ADD')}</Button>
        </div>
        </div>
        </form>
      </DialogContent>
    </Dialog>
);
}

export default InviteStudents;