import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { Grid, Typography, IconButton, Tooltip, Avatar, Button, Divider, Accordion, AccordionSummary, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapPin, faPen, faInfoCircle, faInfo, faStickyNote, faEdit, faUsers, faShareAlt, faSignOutAlt, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import ClassBoard from './ClassBoard';
import ClassStudents from './ClassStudents';
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import InviteProfessor from './InviteProfessor';
import InviteStudents from './InviteStudents';
import {useStyles} from '../../assets/styles/materialStyles';
import UrlDetector from "../UrlDetector";
const Class = ({data, reloadClasses, searchResult}) => {
  const classes = useStyles();
  const [classStudentsOpen, setClassStudentsOpen] = useState(false);
  const [inviteProfessorOpen, setInviteProfessorOpen] = useState(false);
  const [inviteStudentsOpen, setInviteStudentsOpen] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [{user}] = useStateValue();
  const { t } = useTranslation();

  

  const handleExitClassOpen = () => {
    setConfirmExit(true);
  }

  const handleExitClassClose = () => {
    setConfirmExit(false);
  }

  const exitClass = () => {
  
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.delete(API_URL+'class/'+data.id, axiosConfig(user.token))
    .then(()=>{
      setConfirmExit(false);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_EXIT_SUCCESS')
        }
      });
      reloadClasses();
    })
    .catch(()=>{
      setConfirmExit(false);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_ERROR')
        }
      });
    });
  }

  const handleClassStudentsOpen = () => {
    setClassStudentsOpen(true);
  };

  const handleClassStudentsClose = () => {
    setClassStudentsOpen(false);
  };

  const handleInviteProfessorOpen = () => {
    setInviteProfessorOpen(true);
  };

  const handleInviteProfessorClose = () => {
    setInviteProfessorOpen(false);
  };

  const handleInviteStudentsOpen = () => {
    setInviteStudentsOpen(true);
  };

  const handleInviteStudentsClose = () => {
    setInviteStudentsOpen(false);
  };

  let professorData=[];
  if(data.professor){
    
    professorData.push(
      <div className="flexAlignCenter">
        <Link to="#">

        <div className="classAvatarOuterBorderProfessor">
          <div className="classAvatarInnerBorder">
          <Avatar className="teacherIcon" alt={data.professor.firstName + " " + data.professor.lastName} src={data.professor.profileImageThumbnail} />
          </div>
          </div>
          
          </Link>
        <strong>{t('PROFESSOR')}: <Link to="#">{data.professor.firstName} {data.professor.lastName}</Link></strong>
      </div>
    )
  }else if(data.professorInvited == false && data.ownerInvitedProfessor == false){
    professorData.push(
      <div>{t('CLASS_PROFESSOR_NOT_INVITED')}</div>
    );
  }else if(data.creator.id != user.id && data.ownerInvitedProfessor == true){
    professorData.push(
      <div>{t('CLASS_PROFESSOR_INVITED')}</div>
    );
  }else if(data.ownerInvitedProfessor == false && data.professorInvited == true){
    if(data.invitedProfessor != null && data.invitedProfessor.id != null){
      professorData.push(
        <div>{t('CLASS_INVITE_TO')} <strong>{data.invitedProfessor.firstName + " " + data.invitedProfessor.lastName}</strong></div>
      );
    }else if(data.invitedProfessor != null && data.invitedProfessor.id == null){
      professorData.push(
      <div>{t('CLASS_INVITE_TO')}<strong>{data.invitedProfessor}</strong> {t('CLASS_ALREADY_PENDING_1')}<strong>{data.creator.firstName + " " + data.creator.lastName}</strong> {t('CLASS_ALREADY_PENDING_2')}</div>
      );
    }
  }else if(data.ownerInvitedProfessor == true && data.creator.id == user.id){
    if(data.invitedProfessor != null && data.invitedProfessor.id != null){
      professorData.push(
        <div>{t('CLASS_INVITE_TO')} <strong>{data.invitedProfessor.firstName + " " + data.invitedProfessor.lastName}</strong></div>
      );
    }else if(data.invitedProfessor != null && data.invitedProfessor.id == null){
      professorData.push(
      <div>{t('CLASS_INVITE_TO')} <strong>{data.invitedProfessor}</strong> {t('CLASS_ALREADY_PENDING_3')}</div>
      );
    }
  }

  return (
    <Grid item xs={12} sm={6} >
      <Card className="class">
          <div className="header pt-2 classmobile">
            <Typography variant="h6">
              <Link to={"./classes/edit-class/"+data.id}>{data.name}</Link>
              <Tooltip className="infoIcon" title={t('CREATED_BY')+" "+((data.creator.id == user.id) ? t('YOU') : data.creator.firstName + " " + data.creator.lastName) + " " + t('ON') + " " + moment(data.createdDate).format(t('DATE_POSTED'))}>
                <IconButton className="infoIcon">
                  <FontAwesomeIcon className="infoIcon" icon={faInfoCircle} />
                </IconButton>
              </Tooltip>
            </Typography>
            <IconButton className="headerIconButton" component={Link} to={"./classes/edit-class/"+data.id}>
              <FontAwesomeIcon className="editIcon" icon={faPen} />
            </IconButton>
          </div>
          <div className="contentContainer bb">
            {professorData}
          </div>
          <div className="contentContainer bb">
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button onClick={handleClassStudentsOpen} variant="contained" className="yellowButton mr-3"><FontAwesomeIcon className="buttonIcon" icon={faUsers} /> {t('STUDENTS')} ({(data.professor)?data.students.length-1:data.students.length})</Button>
                <Dialog fullWidth onClose={handleClassStudentsClose} aria-labelledby="class-students" open={classStudentsOpen}>
                  <DialogTitle>{t('CLASS_STAFF')}</DialogTitle>
                  <DialogContent className="classStudentModalContent">
                    <ClassStudents data={data} />
                  </DialogContent>
                </Dialog>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button component={ Link } to={"./notes/"+data.id} variant="contained" className="greenButton mr-3"><FontAwesomeIcon className="buttonIcon" icon={faStickyNote} /> {t('NOTES')} ({data.notes.length})</Button>
              </Grid>
              <Grid item xs={12}>
                <Button component={ Link } to={"./assignments/"+data.id} variant="contained" className="blueButton"><FontAwesomeIcon className="buttonIcon" icon={faEdit} /> {t('ASSIGNMENTS')} ({data.assignments.length})</Button>
              </Grid>
            </Grid>
          </div>
          <div className="contentContainer bb">
            <div>
              <Typography variant="h6" color="primary" className="classInnerHeading"><Link to="./calendar">{t('CLASS_NEXT')}</Link></Typography>
              {(data.schedulingFront.length>0)?
                data.schedulingFront.map((upcomingClass, i) => (
                  <div key={i}>
                    <FontAwesomeIcon className="clockIcon" icon={faClock} />
                    {upcomingClass[Object.keys(upcomingClass)[0]]}: {upcomingClass[Object.keys(upcomingClass)[1]]} - {upcomingClass[Object.keys(upcomingClass)[2]]}
                  </div>
                ))
                :
                <div>{t('MSG_NO_UPCOMING_CLASSES')}</div>
              }
            </div>
          </div>
          <div className="contentContainer bb">
            <div>
              <Typography variant="h6" color="primary" className="classInnerHeading">{t('LOCATION')}</Typography>
              <FontAwesomeIcon className="mapIcon" icon={faMapPin} /> <UrlDetector url={data.location}/>
            </div>
          </div>
          {!searchResult &&
          <div>
          <div className="contentContainer bb">
            <Grid container spacing={2}>
              <Grid container item xs={12} justify="center" alignItems="center">
                <Button onClick={handleInviteStudentsOpen} disabled={user.semester.past} variant="contained" className="orangeButton"><FontAwesomeIcon className="buttonIcon" icon={faShareAlt} /> {t('INVITE_STUDENTS_JOIN')}</Button>
                <InviteStudents open={inviteStudentsOpen} onClose={handleInviteStudentsClose} data={data} reloadClasses={reloadClasses}/>
              </Grid>
              {(!data.professor && (data.creator.id == user.id || data.ownerInvitedProfessor == false))?
              <Grid container item xs={12} justify="center" alignItems="center">
                <Button onClick={handleInviteProfessorOpen} disabled={user.semester.past} variant="contained" className="darkBlueButton"><FontAwesomeIcon className="buttonIcon" icon={faGraduationCap} /> {t('INVITE_PROFESSOR')}</Button>
                <InviteProfessor open={inviteProfessorOpen} onClose={handleInviteProfessorClose} data={data} reloadClasses={reloadClasses}/>
              </Grid>
              :null}
              <Grid container item xs={12} justify="center" alignItems="center">
                <Button onClick={handleExitClassOpen} disabled={user.semester.past} variant="contained" className="redButton"><FontAwesomeIcon className="buttonIcon" icon={faSignOutAlt} /> {t('CLASS_EXIT')}</Button>
                <Dialog open={confirmExit} aria-labelledby="class-exit-confirmation">
                  <DialogTitle>{t('CLASS_EXIT')}</DialogTitle>
                  <DialogContent>{t('MSG_EXIT_CONFIRMATION')+" "+data.name}</DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleExitClassClose} color="primary">
                      {t('BTN_CANCEL')}
                    </Button>
                    <Button onClick={exitClass} color="primary">
                      {t('YES')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </div>
          <div>
          <Accordion>
            <div className="accordionHeader">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Link to="#"><Typography variant="h6">{t('CLASS_BOARD')}</Typography></Link>
              </AccordionSummary>
            </div>
            <ClassBoard classId={data.id} />
          </Accordion>
          </div>
        </div>
        }
      </Card>
    </Grid>
  );
}

export default Class;