import React, {useState, useEffect} from 'react';
import { InputLabel, TextField, Button, Dialog, DialogTitle, DialogContent, Card, Typography, FormControl, Select, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import UserAvatar from '../User/UserAvatar';

const AddStudentGrades = ({ onClose, reloadGrades, data={}, grades={}, editData={}, mode="add" }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [students, setStudents] = useState([]);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [assignment, setAssignment] = useState('');
  const headersWithToken = axiosConfig(user.token);

  useEffect(()=>{
    fetchScores();
  }, []);

  const fetchScores = () =>{
      dispatchSetLoading({
          type: 'SET_LOADING',
          loading: true
      });
      axios.get(API_URL + 'grades/class/' + data.id  + '/assignment/' + editData.id, headersWithToken)
      .then((response)=>{
          setStudents(response.data);
          dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
          });
      })
      .catch((errors)=>{
          dispatchSnack({
          type: 'SET_SNACK',
          snack: {
              flag: true,
              message: t('MSG_LOAD_DATA_ERROR')
          }
          });
          dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
          });
          //console.log(errors);
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
        let studentsScore=[];
        students.map((student)=>{
          studentsScore.push({
            totalScore: student.score,
            userId: student.user.id
          });
        });
        let grData={
          studentsScore
        }
        axios.put(API_URL+'grades/class/'+data.id+'/assignment/'+editData.id, grData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          reloadGrades();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
          console.log(error);
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }
  }

  const setStudentGrade = (score, i) => {
    let tempScores=[...students];
    let tempScore={...students[i]};
    tempScore.score=score;
    tempScores[i]=tempScore;
    setStudents(tempScores);
  }
  
  const validateForm = () => {
    let errorsData = [];
    let formIsValid = true;

    {students.map((student, i) => {
      if(!student.score || student.score==''){
        formIsValid = false;
        errorsData[i] = t('GRADES_SCORE_REQUIRED');
      }

      if(student.score && student.score!='' && student.score.length>10){
        formIsValid = false;
        errorsData[i] = t('GRADES_SCORE_LONG');
      }

      if(student.score && student.score<0){
        formIsValid = false;
        errorsData[i] = t('GRADES_SCORE_MORE');
      }

      if(student.score && student.score>editData.totalScore){
        formIsValid = false;
        errorsData[i] = t('GRADES_SCORE_LESS');
      }
    })}

    // if(totalGrades==100){
    //   formIsValid = false;
    //   errorsData["totalGrades"] = t('GRADES_SCORE_LIMIT');
    // }

    setErrors(errorsData);
    return formIsValid;
  }

  const handleGrTitle = (event) => {
    setAssignment(event.target.value);
  };

  return (
    <div>
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Card className="class">
                <div className="card-header">
                    {editData.name}
                </div>
                <div className="contentContainer">
                      <TableContainer className="mt-2">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('PROFILE_FIRST_NAME')}</TableCell>
                                <TableCell>{t('PROFILE_LAST_NAME')}</TableCell>
                                <TableCell>{t('GRADES_SCORE')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {students.map((student, i) => (
                                (!student.user.professor &&
                                <TableRow>
                                    <TableCell>
                                      <UserAvatar profile={student.user} />
                                    </TableCell>
                                    <TableCell scope="row">
                                        {student.user.firstName}
                                    </TableCell>
                                    <TableCell>{student.user.lastName}</TableCell>
                                    <TableCell>
                                    <TextField
                                      value={student.score}
                                      onChange={(e)=>setStudentGrade(e.target.value, i)}
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      id={"score"+i}
                                      placeholder={t('GRADES_SCORE')}
                                      label={t('GRADES_SCORE')}
                                      name={"score"+i}
                                      error={(errors[i] && errors[i])?errors[i]:null}
                                      helperText={(errors[i] && errors[i])?errors[i]:null}
                                      className="mt-0"
                                    />
                                    </TableCell>
                                    <TableCell>/{editData.totalScore}</TableCell>
                                </TableRow>
                                )
                                ))}
                            </TableBody>
                        </Table>
                      </TableContainer>
                </div>
              </Card>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SAVE_SCORES')}
            </Button>
          </form>
        </div>
      </div>
);
}

export default AddStudentGrades;