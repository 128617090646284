import React,{useEffect} from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
let flag=0;
const Dropdown = ({onChangeDropDown, data}) => {

    useEffect(()=>{
    }, [data]);

    return (
        <DropdownTreeSelect className="dropDownStyle"  texts={{ placeholder: ' ' }} data={data}  mode="radioSelect" onChange={onChangeDropDown}/>
     )
};
// const Dropdown = ({onChangeDropDown,data}) => {
//     return (
//        <DropdownTreeSelect className="dropDownStyle"  texts={{ placeholder: ' ' }} data={data}  mode="radioSelect" onChange={onChangeDropDown}/>
//     )
// }

const areEqual = (prevProps, nextProps) => {
    if(prevProps.refreshFlag==0){
        prevProps.setRefreshFlag(1);
        return false;
    }else{
        return true;
    }
}

export default React.memo(Dropdown, areEqual);
//export default Dropdown;

