import React, {useEffect, useState} from 'react';
import { makeStyles, Grid, Paper, Typography } from '@material-ui/core';
import DashboardSummary from './DashboardSummary';
import DashboardUpcoming from './DashboardUpcoming';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import * as moment from 'moment';
import DashboardWhileYouWereOut from './DashboardWhileYouWereOut';
import DashboardClassBoards from './DashboardClassBoards';

  const Dashboard = () => {
  const [{title}, dispatchSetTitle] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [data, setData]=useState();
  const [{inviteEventsCount}, dispatchInviteEventsCount] = useStateValue();
  const [{shareNoteEventsCount}, dispatchShareNoteEventsCount] = useStateValue();
  const [{shareAssignmentEventsCount}, dispatchShareAssignmentEventsCount] = useStateValue();
  const [{feedbackEventsCount}, dispatchFeedbackEventsCount] = useStateValue();
  const [{addPostEventsCount}, dispatchAddPostEventsCount] = useStateValue();
  const [dataCount, setDataCount] = useState({
    upcomingClassesCount: 0,
    dueAssignmentsCount: 0,
    dueTasksCount: 0
  });
  const headers = axiosConfig(user.token);

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('MENU_DASHBOARD')
    });
    dispatchInviteEventsCount({
      type: 'INVITE_EVENTS_COUNT',
      count: 0
    });
    dispatchShareNoteEventsCount({
      type: 'SHARE_NOTE_EVENTS_COUNT',
      count: 0
    });
    dispatchShareAssignmentEventsCount({
      type: 'SHARE_ASSIGNMENT_EVENTS_COUNT',
      count: 0
    });
    dispatchFeedbackEventsCount({
      type: 'FEEDBACK_EVENTS_COUNT',
      count: 0
    });
    dispatchAddPostEventsCount({
      type: 'ADD_POST_EVENTS_COUNT',
      count: 0
    });
    const fetchDashboardData=()=>{
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      const findUpcomingClasses = axios.get(API_URL + 'class/dashboard', headers);
      const findDueTasks = axios.get(API_URL + 'event/dashboard?offset='+encodeURIComponent(moment().format('ZZ')), headers);
      const findDueAssignments = axios.get(API_URL + 'assignment/dashboard', headers);
      const findUnreadProfessorPosts = axios.get(API_URL + 'post/dashboard', headers);
      const findUnreadNotes = axios.get(API_URL + 'note/dashboard', headers);
      const findUnreadGroupChats = axios.get(API_URL + 'chat/group/dashboard', headers);
      const findUnreadUserChats = axios.get(API_URL + 'chat/user/dashboard', headers);
      const findClassBoards = axios.get(API_URL + 'home', headers);

      axios.all([findUpcomingClasses, findDueTasks, findDueAssignments, findUnreadProfessorPosts, findUnreadNotes, findUnreadGroupChats, findUnreadUserChats, findClassBoards])
      .then(axios.spread((...response) => {
        setData({
          upcomingClasses: response[0].data,
          dueTasks: response[1].data,
          dueAssignments: response[2].data,
          unreadProfessorPosts: response[3].data,
          unreadNotes: response[4].data,
          unreadGroupChats: response[5].data,
          unreadUserChats: response[6].data,
          findClassBoards: response[7].data
        });
        setDataCount({
          upcomingClassesCount: response[0].data.length,
          dueTasksCount: response[1].data.length,
          dueAssignmentsCount: response[2].data.length
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })).catch(errors => {
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })      
    }
    fetchDashboardData();
  }, []);

  const reloadClassBoards = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.get(API_URL + 'home', headers)
    .then((response)=>{
      setData({
        ...data,
        findClassBoards: response.data
      });
      dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
      });
    })
    .catch((errors)=>{
      dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
      });
      //console.log(errors);
    })
  }

  return (
    <Grid item xs={12}>
      <DashboardSummary data={data} dataCount={dataCount} />
      {/*  */}
      <DashboardUpcoming data={data} dataCount={dataCount} />
      {(data && (data.unreadProfessorPosts.length>0 || data.unreadNotes.length>0 || data.unreadGroupChats.length>0 || data.unreadUserChats.length>0)) &&
        <DashboardWhileYouWereOut unreadProfessorPosts={data.unreadProfessorPosts} unreadNotes={data.unreadNotes} unreadGroupChats={data.unreadGroupChats} unreadUserChats={data.unreadUserChats} />
      }
       {/*  */}
      {data &&<>
       <DashboardClassBoards findClassBoards={data.findClassBoards} reloadClassBoards={reloadClassBoards} />
      </>}
      <SnackbarAlert />
      {loading ?
      <Loader active={loading} />
      :
      null}
    </Grid>
  );
}

export default Dashboard;