import React, {useState,useEffect} from 'react';
import { Grid,Avatar, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import {useStyles} from '../../assets/styles/materialStyles';
import { useTranslation } from "react-i18next";
//
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const ViewUser = ({onClose, open,userViewData}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const[semesterName,setSemesterName] = useState('');

     // function to get id from university object start
     function GetPropertyValue(obj1, dataToRetrieve) {
      return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k) {
          return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }
       // function to get id from university object end

    useEffect(()=>{
if(userViewData.semester){
  setSemesterName(GetPropertyValue(userViewData.semester, "name"))
}
},[userViewData]);


  
    return (
       <>
        <Dialog fullWidth onClose={onClose} aria-labelledby="add-assignment" open={open}>
        <DialogTitle>{(userViewData.professor)?t('PROFESSOR'):t('STUDENT')} {t('PROFILE')}</DialogTitle>
        <DialogContent className="modalContent">
        <Grid container  className="userImageContainer">
                    <Grid item lg="5" sm="5" md="5" xs="4"></Grid>
                    <Grid item lg="1" sm="1" md="1" xs="4" >
                       {userViewData.professor==true && <>
                        <div className="userAvatarOuterBorderProfessor" style={{width:'82px'}}>
                     <div className="useAvatarInnerBorder">
                        <Avatar alt={userViewData.firstName + " " + userViewData.lastName} src={userViewData.profileImageThumbnail} className="userAvatarMedium" />
                      </div></div>
                      
                      </>}
                      {userViewData.professor==false && <>
                        <div className="userAvatarOuterBorderStudent" style={{width:'82px'}}>
                     <div className="useAvatarInnerBorder">
                        <Avatar alt={userViewData.firstName + " " + userViewData.lastName} src={userViewData.profileImageThumbnail} className="userAvatarMedium" />
                      </div></div>
                      </>}
                      </Grid>
                   <Grid item lg="6" sm="6" md="6" xs="4"></Grid>
                  </Grid>
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
   
        <TableBody>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('PROFILE_FIRST_NAME')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.firstName}</StyledTableCell>
            </StyledTableRow>
          
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('PROFILE_LAST_NAME')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.lastName}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_GENDER')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.sex}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_DOB')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.dateOfBirth}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_EMAIL_ONLY')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.email}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_PHONE_NUMBER_ONLY')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.phoneNumbers? userViewData.phoneNumbers[0]: ''}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_ADDRESS')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.address}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('USER_SEMESTER')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{semesterName?semesterName:''}</StyledTableCell>
            </StyledTableRow>
            
            <StyledTableRow >
              <StyledTableCell>
              <strong className="orange">{t('STATUS')}</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{userViewData.blocked? 'Blocked' : 'Unblocked'}</StyledTableCell>
            </StyledTableRow>
         
        </TableBody>
      </Table>
    </TableContainer>
    </DialogContent>
    </Dialog>
       </>
    )
}

export default ViewUser
