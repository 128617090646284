import React, { useEffect, useState, useRef } from 'react';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';

const ChatContent = ({ selectedChat, setSelectedChat, receivedMessage, chatDetailData, totalData, setChatDetailData, setReloadFlag, reloadFlag, page, setPage,responsive=false,showSideBar=false,setShowSideBar=false }) => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const messageEl = useRef(null);

  

  return (
    <>
    {/* <div className="content"  > */}
    <div className="content"  style={{display: responsive && !showSideBar ? 'block' : !responsive? 'block' : 'none' }}>
      {selectedChat && selectedChat.id &&
      <>
      <ChatHeader setReloadFlag={setReloadFlag} selectedChat={selectedChat} setSelectedChat={setSelectedChat} responsive={responsive} showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
      <div className="messages" id="chatDataId" ref={messageEl}
        style={{
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse"
        }}>
        <ChatBody selectedChat={selectedChat} chatDetailData={chatDetailData} totalData={totalData} receivedMessage={receivedMessage} setChatDetailData={setChatDetailData} page={page} setPage={setPage} />
      </div>
      <ChatFooter selectedChat={selectedChat} messageEl={messageEl} />
      </>
      }
    </div>
    </>
  );
}

export default ChatContent;