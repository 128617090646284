import React, {useEffect, useState} from 'react';
import { Grid, Paper, Button, Card, Typography, Checkbox, FormControlLabel, AppBar, Tabs, Tab } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,useHistory
} from "react-router-dom";
import axios from 'axios';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Tasks from './Tasks';
import AddTask from './AddTask';
import {API_URL, axiosConfig} from '../../api/index';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import $ from "jquery";

const JOTCalendar = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [{title}, dispatchSetTitle] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [serverError, setServerError] = useState('');
  const [eventsCount, setEventsCount] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const { t } = useTranslation();
  const [eventsList, setEventsList] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const [tab, setTab] = useState('all');
  moment.locale('en-GB');
  const localizer = momentLocalizer(moment);
  const allViews = Object.keys(Views).map(k => Views[k]);
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const headers = axiosConfig(user.token);
  const [{assignmentParam},dispatchSetAssignmentWithParam] = useStateValue();
  let history = useHistory();
  const[{specificTaskId},dispatchSetSpecificTaskId] = useStateValue();
  const [defaults,setDefaults]=useState();
  const [width, setWidth] = useState(window.innerWidth);

    // screen size responsive logic start
useEffect(() => {
  function handleResize() {
    setWidth(window.innerWidth);
  }
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, [width]);



useEffect(() => {
  width < 770 && handleMobileView();
  width > 770 && handleDesktopView();
 },[width]);

function handleMobileView() {
  setDefaults(true);
}

function handleDesktopView() {
 setDefaults(false);
}
// screen size responsive logic end


  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('CALENDAR')
    });
    fetchEvents();
  }, []);

  const fetchEvents = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.get(API_URL + 'event?offset='+encodeURIComponent(moment().format('ZZ')), headers)
    .then((response)=>{
      let eventsData=[];
      let tasksData=[];
      let eventTasksCount=0;
      let completedTasksCount=0;
      if(response.data && response.data.length>0){
        response.data.map((task)=>{
          //let sDate=moment(task.startTime).toISOString();
          let str=task.recurrencePattern;
          var options = RRule.parseString(str);
          options.dtstart=new Date(task.startTime);
          var rRule = new RRule(options);
          var occurs = rRule.all();
          occurs.forEach(occur => {
            if(task.toDo){
              tasksData.push(task);
              eventTasksCount++;
              if(task.completed){
                completedTasksCount++;
              }else{
                eventsData.push({
                  id: task.id,
                  title: task.name,
                  type: task.type,
                  allData: false,
                  start: occur,
                  end: moment(task.endTime).toDate()
                });
              }
            }else{
              let mergeDate=moment(new Date(occur)).format("MM/DD/YYYY");
              let mergeTime=moment(task.endTime).format("h:mm A");
              let newEndDate=mergeDate+" "+mergeTime;
              eventsData.push({
                id: task.id,
                title: task.name,
                type: task.type,
                allData: false,
                start: occur,
                end: new Date(newEndDate)
              });
            }
          });
        });
        setEventsList(eventsData);
        setTasksList(tasksData);
        setEventsCount(completedTasksCount);
        setTotalEvents(eventTasksCount);
      }
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }

  const handleAddTaskOpen = () => {
    setAddTaskOpen(true);
  };

  const handleAddTaskClose = () => {
    setAddTaskOpen(false);
  };

  const typeRoute = (event) =>{
   
    if(event.type == 'CLASS'){
      history.push(`/classes/edit-class/${event.id}`);
    }
    else if(event.type == 'CUSTOM'){ 
      //code to  land on specific task in calendar
      document.getElementById(event.id).scrollIntoView({behavior: 'smooth',  block: 'nearest'});
    
    }
    else if(event.type == 'ASSIGNMENT'){
  dispatchSetAssignmentWithParam
    ({
      type: 'ASSIGNMENT_ID',
      assignmentParam
      : event.id
    });
      history.push('/assignments/');
    }
  }
 
 

  return (
    <>
    <Grid item xs={12}>
     <Grid item xs={12}>
       <Paper className={classes.paper}>
         <Grid container spacing={3} className={classes.root} justify="flex-end" className="calendarMargin">
         <Grid item xs={12} sm={4} lg={3} className="btnforCalendar" >
             <Button onClick={handleAddTaskOpen} variant="contained" className="yellowButton yellowButton-calendar">{t('EVENT_ADD')}</Button>
             <AddTask reloadTasks={fetchEvents} open={addTaskOpen} onClose={handleAddTaskClose}/>
           </Grid>
           <Grid item xs={12} className="mb-4">
           <div className="calendarContainer">
            
             <Calendar
             selectable
             view={defaults?"day":"month"}
             localizer={localizer}
             events={eventsList}
              onSelectEvent={event  => typeRoute(event)}
             />
        
           </div>
           </Grid>
           <Grid item xs={12}>
             <Tasks reloadEvents={fetchEvents} tasksList={tasksList} eventsCount={eventsCount} totalEvents={totalEvents} />
           </Grid>
         </Grid>
       </Paper>
     </Grid>
   <SnackbarAlert />
   {loading ?
   <Loader active={true} />
   :
   null}
 </Grid>
  </>
  );
}

export default JOTCalendar;