import React, {useEffect, useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfigWithoutToken} from '../../api/index';
import axios from 'axios';  

const TermsConditions = ({ onClose, open }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-term" open={open}>
      <DialogTitle>{t('JOT_TERMS_AND_CONDITIONS')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
        <h2>Terms and Conditions ("Terms")</h2>

<p>Last updated: June 21, 2016</p>

<p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the www.jot.academy
    website and the Jot mobile application (together, or individually, the "Service") operated by Jot Technologies LLC
    ("us", "we", or "our").</p>

<p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These
    Terms apply to all visitors, users and others who wish to access or use the Service.</p>

<p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms
    then you do not have permission to access the Service.</p>

<p><strong>Communications</strong></p>

<p>By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and
    other information we may send. However, you may opt out of receiving any, or all, of these communications from us by
    following the unsubscribe link or instructions provided in any email we send.</p>

<p><strong>Purchases</strong></p>

<p>If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to
    supply certain information relevant to your Purchase including, without limitation, your credit card number, the
    expiration date of your credit card, your billing address, and your shipping information.</p>

<p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in
    connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>

<p>The service may employ the use of third party services for the purpose of facilitating payment and the completion of
    Purchases. By submitting your information, you grant us the right to provide the information to these third parties
    subject to our Privacy Policy.</p>

<p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or
    service availability, errors in the description or price of the product or service, error in your order or other
    reasons.</p>

<p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is
    suspected.</p>

<p><strong>Availability, Errors and Inaccuracies</strong></p>

<p>We are constantly updating product and service offerings on the Service. We may experience delays in updating
    information on the Service and in our advertising on other web sites. The information found on the Service may
    contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described
    inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information
    found on the Service.</p>

<p>We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at
    any time without prior notice.</p>

<p><strong>Subscriptions</strong></p>

<p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a
    recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis,
    depending on the type of subscription plan you select when purchasing a Subscription.</p>

<p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless
    you cancel it or Jot Technologies LLC cancels it. You may cancel your Subscription renewal either through your
    online account management page or by contacting Jot Technologies LLC customer support team.</p>

<p>A valid payment method, including credit card or PayPal, is required to process the payment for your Subscription.
    You shall provide Jot Technologies LLC with accurate and complete billing information including full name, address,
    state, zip code, telephone number, and a valid payment method information. By submitting such payment information,
    you automatically authorize Jot Technologies LLC to charge all Subscription fees incurred through your account to
    any such payment instruments.</p>

<p>Should automatic billing fail to occur for any reason, Jot Technologies LLC will issue an electronic invoice
    indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to
    the billing period as indicated on the invoice.</p>

<p><strong>Free Trial</strong></p>

<p>Jot Technologies LLC may, at its sole discretion, offer a Subscription with a free trial for a limited period of time
    ("Free Trial").</p>

<p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>

<p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by Jot
    Technologies LLC until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled
    your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription
    you have selected.</p>

<p>At any time and without notice, Jot Technologies LLC reserves the right to (i) modify the terms and conditions of the
    Free Trial offer, or (ii) cancel such Free Trial offer.</p>

<p><strong>Fee Changes</strong></p>

<p>Jot Technologies LLC, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions.
    Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>

<p>Jot Technologies LLC will provide you with a reasonable prior notice of any change in Subscription fees to give you
    an opportunity to terminate your Subscription before such change becomes effective.</p>

<p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to
    pay the modified Subscription fee amount.</p>

<p><strong>Refunds</strong></p>

<p>Certain refund requests for Subscriptions may be considered by Jot Technologies LLC on a case-by-case basis and
    granted in sole discretion of Jot Technologies LLC.</p>

<p><strong>Content</strong></p>

<p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics,
    videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service,
    including its legality, reliability, and appropriateness.</p>

<p>By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own it)
    and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and
    (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity
    rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate
    the account of anyone found to be infringing on a copyright.</p>

<p>You retain any and all of your rights to any Content you submit, post or display on or through the Service and you
    are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or
    any third party posts on or through the Service. However, by posting Content using the Service you grant us the
    right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and
    through the Service. You agree that this license includes the right for us to make your Content available to other
    users of the Service, who may also use your Content subject to these Terms.</p>

<p>Jot Technologies LLC has the right but not the obligation to monitor and edit all Content provided by users.</p>

<p>In addition, Content found on or through this Service are the property of Jot Technologies LLC or used with
    permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in
    whole or in part, for commercial purposes or for personal gain, without express advance written permission from
    us.</p>

<p><strong>Accounts</strong></p>

<p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you
    provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may
    result in the immediate termination of your account on the Service.</p>

<p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to
    the restriction of access to your computer and/or account. You agree to accept responsibility for any and all
    activities or actions that occur under your account and/or password, whether your password is with our Service or a
    third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized
    use of your account.</p>

<p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name
    or trademark that is subject to any rights of another person or entity other than you, without appropriate
    authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>

<p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole
    discretion.</p>

<p><strong>Intellectual Property</strong>
    The Service and its original content (excluding Content provided by users), features and functionality are and will
    remain the exclusive property of Jot Technologies LLC and its licensors. The Service is protected by copyright,
    trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be
    used in connection with any product or service without the prior written consent of Jot Technologies LLC.</p>

<p><strong>Links To Other Web Sites</strong></p>

<p>Our Service may contain links to third party web sites or services that are not owned or controlled by Jot
    Technologies LLC.</p>

<p>Jot Technologies LLC has no control over, and assumes no responsibility for the content, privacy policies, or
    practices of any third party web sites or services. We do not warrant the offerings of any of these
    entities/individuals or their websites.</p>

<p>You acknowledge and agree that Jot Technologies LLC shall not be responsible or liable, directly or indirectly, for
    any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
    goods or services available on or through any such third party web sites or services.</p>

<p>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services
    that you visit.</p>

<p><strong>Termination</strong></p>

<p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or
    liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to
    a breach of the Terms.</p>

<p>If you wish to terminate your account, you may simply discontinue using the Service.</p>

<p>All provisions of the Terms which by their nature should survive termination shall survive termination, including,
    without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

<p><strong>Indemnification</strong></p>

<p>You agree to defend, indemnify and hold harmless Jot Technologies LLC and its licensee and licensors, and their
    employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations,
    losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or
    arising out of a) your use and access of the Service, by you or any person using your account and password; b) a
    breach of these Terms, or c) Content posted on the Service.</p>

<p><strong>Limitation Of Liability</strong></p>

<p>In no event shall Jot Technologies LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be
    liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss
    of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
    inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any
    content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content,
    whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have
    been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of
    its essential purpose.</p>

<p><strong>Disclaimer</strong></p>

<p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The
    Service is provided without warranties of any kind, whether express or implied, including, but not limited to,
    implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of
    performance.</p>

<p>Jot Technologies LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
    uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected;
    c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your
    requirements.</p>

<p><strong>Exclusions</strong></p>

<p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for
    consequential or incidental damages, so the limitations above may not apply to you.</p>

<p><strong>Governing Law</strong></p>

<p>These Terms shall be governed and construed in accordance with the laws of Florida, United States, without regard to
    its conflict of law provisions.</p>

<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
    provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms
    will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede
    and replace any prior agreements we might have had between us regarding the Service.</p>

<p><strong>Changes</strong></p>

<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material
    we will provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change
    will be determined at our sole discretion.</p>

<p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised
    terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

<p><strong>Contact Us</strong></p>

<p>If you have any questions about these Terms, please contact us.</p>

<h2>Privacy Policy</h2>

<p>Last updated: June 21, 2016</p>

<p>Jot Technologies LLC ("us", "we", or "our") operates the www.jot.academy website and the Jot mobile application (the
    "Service").</p>

<p>This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you
    use our Service.</p>

<p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>

<p>We use your Personal Information for providing and improving the Service. By using the Service, you agree to the
    collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
    terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>

<p><strong>Information Collection And Use</strong></p>

<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be
    used to contact or identify you. Personally identifiable information may include, but is not limited to, your email
    address, name, other information ("Personal Information").
    We collect this information for the purpose of providing the Service, identifying and communicating with you,
    responding to your requests/inquiries, servicing your purchase orders, and improving our services.</p>

<p><strong>Log Data</strong></p>

<p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service
    by or through a mobile device ("Log Data").</p>

<p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser
    version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and
    other statistics.</p>

<p>When you access the Service by or through a mobile device, this Log Data may include information such as the type of
    mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating
    system, the type of mobile Internet browser you use and other statistics.</p>

<p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of
    information in order to increase our Service's functionality. These third party service providers have their own
    privacy policies addressing how they use such information.</p>

<p>Please see the section regarding Location Information below regarding the use of your location information and your
    options.</p>

<p><strong>Location Information</strong></p>

<p>We may use and store information about your location depending on the permissions you have set on your device. We use
    this information to provide features of our Service, to improve and customize our Service. You can enable or disable
    location services when you use our Service at anytime, through your mobile device settings.</p>

<p><strong>Cookies</strong></p>

<p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to
    your browser from a web site and transferred to your device. We use cookies to collect information in order to
    improve our services for you.</p>

<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. The Help feature on
    most browsers provide information on how to accept cookies, disable cookies or to notify you when receiving a new
    cookie.</p>

<p>If you do not accept cookies, you may not be able to use some features of our Service and we recommend that you leave
    them turned on.</p>

<p><strong>DoubleClick Cookie</strong></p>

<p>Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie
    enables it and its partners to serve ads to our users based on their visit to our Service or other web sites on the
    Internet.</p>

<p>You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads
    Settings web page: http://www.google.com/ads/preferences/</p>

<p><strong>Behavioral Remarketing</strong></p>

<p>Jot Technologies LLC uses remarketing services to advertise on third party web sites to you after you visited our
    Service. We, and our third party vendors, use cookies to inform, optimize and serve ads based on your past visits to
    our Service.</p>
<ul>
    <li>
        <p>Google</p>

        <p>Google AdWords remarketing service is provided by Google Inc.</p>

        <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by
            visiting the Google Ads Settings page: http://www.google.com/settings/ads</p>

        <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on -
            https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being collected and used by Google
            Analytics.</p>

        <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:
            http://www.google.com/intl/en/policies/privacy/</p>
    </li>
    <li>
        <p>Twitter</p>

        <p>Twitter remarketing service is provided by Twitter Inc.</p>

        <p>You can opt-out from Twitter's interest-based ads by following their instructions:
            https://support.twitter.com/articles/20170405</p>

        <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page:
            https://twitter.com/privacy</p>
    </li>
    <li>
        <p>Facebook</p>

        <p>Facebook remarketing service is provided by Facebook Inc.</p>

        <p>You can learn more about interest-based advertising from Facebook by visiting this page:
            https://www.facebook.com/help/164968693837950</p>

        <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook:
            https://www.facebook.com/about/ads/#568137493302217</p>

        <p>Facebook adheres the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital
            Advertising Alliance. You can also opt-out from Facebook and other participating companies through the
            Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance
            of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in
            Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.</p>

        <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:
            https://www.facebook.com/privacy/explanation</p>
    </li>
    <li>
        <p>AdRoll</p>

        <p>AdRoll remarketing service is provided by Semantic Sugar, Inc.</p>

        <p>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page:
            http://info.evidon.com/pub_info/573?v=1&nt=1&nw=false</p>

        <p>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page:
            http://www.adroll.com/about/privacy</p>
    </li>
</ul>
<p><strong>Do Not Track Disclosure</strong></p>

<p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform
    websites that you do not want to be tracked.</p>

<p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

<p><strong>Service Providers</strong></p>

<p>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf,
    to perform Service-related services and/or to assist us in analyzing how our Service is used.</p>

<p>These third parties have access to your Personal Information only to perform specific tasks on our behalf and are
    obligated not to disclose or use your information for any other purpose.</p>

<p><strong>Communications</strong></p>

<p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other
    information that may be of interest to you. You may opt out of receiving any, or all, of these communications from
    us by following the unsubscribe link or instructions provided in any email we send.</p>

<p><strong>Compliance With Laws</strong></p>

<p>We will disclose your Personal Information where required to do so by law or subpoena or if we believe that such
    action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security
    or integrity of our Service.</p>

<p><strong>Business Transaction</strong></p>

<p>If Jot Technologies LLC is involved in a merger, acquisition or asset sale, your Personal Information may be
    transferred as a business asset. In such cases, we will provide notice before your Personal Information is
    transferred and/or becomes subject to a different Privacy Policy.</p>

<p><strong>Security</strong></p>

<p>The security of your Personal Information is important to us, and we strive to implement and maintain reasonable,
    commercially acceptable security procedures and practices appropriate to the nature of the information we store, in
    order to protect it from unauthorized access, destruction, use, modification, or disclosure.</p>

<p>However, please be aware that no method of transmission over the internet, or method of electronic storage is 100%
    secure and we are unable to guarantee the absolute security of the Personal Information we have collected from
    you.</p>

<p><strong>International Transfer</strong></p>

<p>Your information, including Personal Information, may be transferred to — and maintained on — computers located
    outside of your state, province, country or other governmental jurisdiction where the data protection laws may
    differ than those from your jurisdiction.</p>

<p>If you are located outside United States and choose to provide information to us, please note that we transfer the
    information, including Personal Information, to United States and process it there.</p>

<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that
    transfer.</p>

<p><strong>Links To Other Sites</strong></p>

<p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you
    will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you
    visit.</p>

<p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third
    party sites or services.</p>

<p><strong>Children's Privacy</strong></p>

<p>Only persons age 18 or older have permission to access our Service. Our Service does not address anyone under the age
    of 13 ("Children").</p>

<p>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or
    guardian and you learn that your Children have provided us with Personal Information, please contact us. If we
    become aware that we have collected Personal Information from a children under age 13 without verification of
    parental consent, we take steps to remove that information from our servers.</p>

<p><strong>Changes To This Privacy Policy</strong></p>

<p>This Privacy Policy is effective as of June 21, 2016 and will remain in effect except with respect to any changes in
    its provisions in the future, which will be in effect immediately after being posted on this page.</p>

<p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
    periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page
    will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified
    Privacy Policy.</p>

<p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have
    provided us, or by placing a prominent notice on our website.</p>

<p><strong>Contact Us</strong></p>

<p>If you have any questions about this Privacy Policy, please contact us.</p>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default TermsConditions;