import React, { useEffect } from 'react'
import { Grid } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loader from "../Tools/Loader"
import SnackbarAlert from "../Tools/SnackbarAlert";
import { useStyles } from "../../assets/styles/materialStyles";
import { useStateValue } from "../../store/StateProvider";
import Chat from "./Chat";
import { useTranslation } from "react-i18next";

const Chats = () => {
  const classes = useStyles();
  const [{ snack }] = useStateValue();
  const [{ title }, dispatchSetTitle] = useStateValue();
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{chatEventsCount}, dispatchChatEventsCount] = useStateValue();
  const { t } = useTranslation();

  useEffect(() => {
    dispatchSetTitle({
      type: "SET_TITLE",
      title: t("Chat"),
    });
    dispatchChatEventsCount(
      {
        type: 'CHAT_EVENTS_COUNT',
        count: 0
      });
  }, []);
    return (
        <Grid item xs={12}>
      <Switch>
      <Route exact path={"*/chats"} component={Chat} />
        <Route exact path={"*/chat"} component={Chat} />
        <Route exact path={"*/chat/:chatId?"} component={Chat} />
      </Switch>
      <SnackbarAlert />
      {loading ? <Loader active={true} /> : null}
    </Grid>
    )
}

export default Chats
