import React, {useState, useEffect} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Card, Typography, FormControl, Select, FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'; 
import * as moment from 'moment'; 
import { setDate } from 'date-fns';

const AddClass = ({ onClose, reloadClasses, mode="add", data={} }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [className, setClassName] = useState('');
  const [classLocation, setClassLocation] = useState('');
  const [classStartTime, setClassStartTime] = useState(new Date());
  const [classEndTime, setClassEndTime] = useState(new Date());
  const [classDayOfWeek, setClassDayOfWeek] = useState('Monday');
  const [scheduling, setScheduling] = useState([]);

  useEffect(()=>{
    if(data.name){
      setClassName(data.name);
      setClassLocation(data.location);
      if(data.schedulingFront.length>0){
        let tempSchedule=[];
        data.schedulingFront.map((schedule)=>{
          tempSchedule.push({
            dayOfWeek: schedule.dayOfWeek,
            endTime: schedule.endTime,
            startTime: schedule.startTime
          });
        });
        setScheduling(tempSchedule);
      }
    }
  },[data]);

  const handleClassStartTimeChange = (date) => {
    setClassStartTime(date);
  };

  const handleClassEndTimeChange = (date) => {
    setClassEndTime(date);
  };

  const handleClassDayOfWeek = (event) => {
    setClassDayOfWeek(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      if(mode=="add"){
        let classData={
          name: className,
          location: classLocation,
          scheduling
        }
        axios.post(API_URL+'class', classData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          setClassName('');
          setClassLocation('');
          setClassStartTime(new Date());
          setClassEndTime(new Date());
          setScheduling([]);
          reloadClasses();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
        })
        .catch((error) => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }else{
        let classData={
          id: data.id,
          name: className,
          location: classLocation,
          scheduling
        }
        axios.put(API_URL+'class', classData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          reloadClasses();
          if(response.status==202){
            dispatchSnack({
              type: 'SET_SNACK',
              snack: {
                flag: true,
                message: t('MSG_CLASS_CHANGE')
              }
            });
          }else{
            dispatchSnack({
              type: 'SET_SNACK',
              snack: {
                flag: true,
                message: t('MSG_SUCCESS')
              }
            });
          }
        })
        .catch((error) => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
      }
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(className==''){
      formIsValid = false;
      errorsData["className"] = t('MSG_EMPTY_TITLE');
    }

    if(className!=''&&className.length<3){
      formIsValid = false;
      errorsData["className"] = t('MSG_SHORT_TITLE');
    }

    if(className!=''&&className.length>50){
      formIsValid = false;
      errorsData["className"] = t('MSG_LONG_TITLE');
    }

    if(classStartTime=='' || classStartTime==null){
        formIsValid = false;
        errorsData["classStartTime"] = t('MSG_INVALID_DATE');
      }

    if(classLocation==''){
        formIsValid = false;
        errorsData["classLocation"] = t('MSG_EMPTY_LOCATION');
      }
  
      if(classLocation!=''&&classLocation.length<3){
        formIsValid = false;
        errorsData["classLocation"] = t('MSG_SHORT_LOCATION');
      }
  
      if(classLocation!=''&&classLocation.length>50){
        formIsValid = false;
        errorsData["classLocation"] = t('MSG_LONG_LOCATION');
      }

    setErrors(errorsData);
    return formIsValid;
  }

  const addToSchedule = () => {
    let errorsData = {};
    if(moment(classEndTime).isValid() && moment(classStartTime).isValid()){
      let tempSchedule={
          dayOfWeek: classDayOfWeek,
          endTime: classEndTime.toLocaleTimeString([], {timeStyle: 'short'}),
          startTime: classStartTime.toLocaleTimeString([], {timeStyle: 'short'})
      };
      setScheduling([
          ...scheduling,
          tempSchedule
      ])
    }else{
      if(!moment(classEndTime).isValid()){
        errorsData["classEndTime"] = t('MSG_INVALID_TIME');
      }
      if(!moment(classStartTime).isValid()){
        errorsData["classStartTime"] = t('MSG_INVALID_TIME');
      }
      setErrors(errorsData);
    }
  }

  const handleRemoveFromSchedule = (entry) => {
    setScheduling(
      scheduling => scheduling.filter(item => item !== entry)
    )
  };

  return (
    <div>
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={className}
                onChange={(e)=>setClassName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="className"
                placeholder={t('CLASS_NAME')}
                name="className"
                autoComplete="className"
                error={errors["className"]}
                helperText={errors["className"]}
                autoFocus
              />
              <TextField
                value={classLocation}
                onChange={(e)=>setClassLocation(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="classLocation"
                placeholder={t('CLASS_LOCATION')}
                name="classLocation"
                autoComplete="classLocation"
                error={errors["classLocation"]}
                helperText={errors["classLocation"]}
                className="mb-4"
              />
              <Card className="class">
                <div className="card-header">
                    {t('CLASS_SCHEDULE')}
                </div>
                <div className="contentContainer">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                value={classDayOfWeek}
                                onChange={handleClassDayOfWeek}
                                required
                                id="classDayOfWeek"
                                name="classDayOfWeek"
                                autoComplete="classDayOfWeek"
                                error={errors["classDayOfWeek"]}
                                className="mb-1"
                                >
                                    <option value="Monday">{t('MONDAY')}</option>
                                    <option value="Tuesday">{t('TUESDAY')}</option>
                                    <option value="Wednesday">{t('WEDNESDAY')}</option>
                                    <option value="Thursday">{t('THURSDAY')}</option>
                                    <option value="Friday">{t('FRIDAY')}</option>
                                    <option value="Saturday">{t('SATURDAY')}</option>
                                    <option value="Sunday">{t('SUNDAY')}</option>
                                </Select>
                                <FormHelperText error>{errors["classId"]}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                label={t('TIME_START')}
                                fullWidth
                                inputVariant="outlined"
                                margin="normal"
                                error={errors["classStartTime"]}
                                helperText={errors["classStartTime"]}
                                id="classStartTime"
                                name="classStartTime"
                                placeholder={t('TIME_START')}
                                value={classStartTime}
                                onChange={handleClassStartTimeChange}
                                className="m0"
                            />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    label={t('TIME_END')}
                                    fullWidth
                                    inputVariant="outlined"
                                    margin="normal"
                                    error={errors["classEndTime"]}
                                    helperText={errors["classEndTime"]}
                                    id="classEndTime"
                                    name="classEndTime"
                                    placeholder={t('TIME_END')}
                                    value={classEndTime}
                                    onChange={handleClassEndTimeChange}
                                    className="m0"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Button
                    onClick={addToSchedule}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="blueButton mt-2"
                        >
                        {t('CLASS_ADD_TIME')}
                    </Button>
                    {scheduling.length>0 &&
                    <TableContainer className="mt-2">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>{t('CLASS_DAY_WEEK')}</TableCell>
                                <TableCell>{t('TIME_START')}</TableCell>
                                <TableCell>{t('TIME_END')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {scheduling.map((schedule, i) => (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {schedule.dayOfWeek}
                                    </TableCell>
                                    <TableCell>{schedule.startTime}</TableCell>
                                    <TableCell>{schedule.endTime}</TableCell>
                                    <TableCell><IconButton aria-label="delete" onClick={()=>handleRemoveFromSchedule(schedule)}>
                                    <DeleteIcon />
                                  </IconButton>
                                  </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                      </TableContainer>
                    }
                </div>
              </Card>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {(mode=="add"?t('CLASS_ADD'):t('BTN_UPDATE'))}
            </Button>
          </form>
        </div>
      </div>
);
}

export default AddClass;