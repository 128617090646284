import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useStateValue } from '../../store/StateProvider';

const SnackbarAlert = () => {
  const [{snack}, dispatchSnack] = useStateValue();

  const handleSnack = () => {
    dispatchSnack({
      type: 'SET_SNACK',
      snack: {
        flag: false,
        message: undefined
      }
    });
  };
  
  return (
    <Snackbar
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        open={snack.flag}
        autoHideDuration={6000}
        onClose={handleSnack}
        message={snack.message}
        action={
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnack}>
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnack}>
            <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
        }
    />
  );
}

export default SnackbarAlert;