import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { split } from 'lodash'

const DateShow = ({ currentDate, previousDate = null }) => {
  const { t } = useTranslation()
  const [diff, setDiff] = useState(0)
  const [dayOfMessage, setDayOfMessage] = useState(0)
  const [monthOfMessage, setMonthOfMessage] = useState(0)

  useEffect(() => {
    datesall()
  }, [diff])

  const datesall = () => {
    console.log(`previousDate`, previousDate)
    if (previousDate && previousDate != '0000-00-00T00:00:00.000+00:00') {
      let pd = split(previousDate, '-')

      pd = pd[2].substring(0, 2)
      let cd = split(currentDate, '-')
      setMonthOfMessage(cd[1])
      cd = cd[2].substring(0, 2)
      setDayOfMessage(cd)
      if (parseInt(cd) - parseInt(pd) != 0) {
        setDiff(1)
      }
    } else {
      debugger
      setDiff(0)
    }
  }

  const getDescriptiveDate = () => {
    const today = new Date().getDate()
    const currentMonth = new Date().getMonth() + 1

    if (parseInt(monthOfMessage) === currentMonth) {
      const diff = today - dayOfMessage
      if (!diff) {
        return t('TODAY')
      } else if (diff === 1) {
        return t('YESTERDAY')
      } else {
        return moment(currentDate).format(t('chat_date'))
      }
    } else {
      return moment(currentDate).format(t('chat_date'))
    }
  }

  const getDateRow = () => (
    <Row>
      <Col lg='5' sm='4' xs='4' md='5' className='dateFirstRow'>
        <hr></hr>
      </Col>

      <Col lg='2' sm='4' xs='4' md='2' className='mt-1 dateShow text-center'>
        {getDescriptiveDate()}
      </Col>

      <Col lg='5' sm='4' xs='4' md='5' className='dateSecondRow'>
        <hr></hr>
      </Col>
    </Row>
  )

  return (
    <>
      {diff > 0
        ? getDateRow()
        : previousDate == '0000-00-00T00:00:00.000+00:00'
        ? getDateRow()
        : ''}
    </>
  )
}

export default DateShow
