import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, Grid, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  
import DeleteIcon from '@material-ui/icons/Delete';
import Rating from '@material-ui/lab/Rating';

const Feedback = ({ onClose, open }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  //const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //setSuccess(false);
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      let userFeedbackData={
        feedback: feedback,
        stars: rating
      };
      const headers = axiosConfig(user.token);
      axios.post(API_URL+'user/feedback', userFeedbackData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setEmail('');
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('FEEDBACK_SENT')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(feedback==''){
      formIsValid = false;
      errorsData["feedback"] = t('MSG_EMPTY_FEEDBACK');
    }
    if(feedback!=''&&feedback.length<10){
      formIsValid = false;
      errorsData["feedback"] = t('MSG_SHORT_NAME');
    }

    if(feedback!=''&&feedback.length>300){
      formIsValid = false;
      errorsData["feedback"] = t('MSG_LONG_NAME');
    }

    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-class" open={open}>
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
    <DialogTitle>{t('PROFILE_FEEDBACK')}</DialogTitle>
    <DialogContent className="modalContent">
        <Grid>
            {serverError!=""?
            <Alert severity="error" className="alertBox">
            {serverError}
            </Alert>
            :null}
            <Rating name="size-large" defaultValue={rating} size="large" onChange={(event, newValue) => {
                setRating(newValue);
                }} style={{marginTop: "1vh",marginBottom: "1vh"}}/>
            <TextField
            value={feedback}
            onChange={(e)=>setFeedback(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="feedback"
            placeholder={t('PROFILE_ENTER_FEEDBACK')}
            name="feedback"
            autoComplete="feedback"
            error={errors["feedback"]}
            helperText={errors["feedback"]}
            autoFocus
            className="mb-2"
            />        
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-2"
            >
              {t('BTN_SEND')}
            </Button>
        </Grid>
    </DialogContent>
    </form>
    </Dialog>
);
}

export default Feedback;