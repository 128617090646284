import React,{useEffect,useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
  } from "@material-ui/core";
  import {useStateValue} from "../../store/StateProvider"
import { useTranslation } from "react-i18next"
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  



const BlockUser = ({open,onClose,userBlockData,reloadTableData,reloadSuperAdminTableData,universityId}) => {
    const { t } = useTranslation();
    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [{user}] = useStateValue();
    const headersWithToken = axiosConfig(user.token);


    const deleteUser= () =>{
        dispatchSetLoading({
            type: 'SET_LOADING',
            loading: true
          });
          let status = false;
          if(userBlockData.blocked==true){
            status = false;
          }
          if(userBlockData.blocked==false){
            status = true;
          }
         
        axios.put(API_URL+'administrator/user/block/'+userBlockData.id+"/"+status,null, headersWithToken)
        .then(response => {
            // dispatchSetLoading({
            //     type: 'SET_LOADING',
            //     loading: false
            //   });
              if(universityId){
                reloadSuperAdminTableData();
              }else{
                reloadTableData();
              }

        onClose();
        if(status==true){
          dispatchSnack({
              type: 'SET_SNACK',
              snack: {
                flag: true,
                message: t('USER_BLOCKED_STATUS')
              }
            });
        }
        if(status==false){
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('USER_UNBLOCKED_STATUS')
            }
          });
        }
        
        }).catch((error)=>{
            dispatchSetLoading({
                type: 'SET_LOADING',
                loading: false
              });
        });

    }

    return (
         <Dialog
        open={open}
        aria-labelledby="class-exit-confirmation"
        >
        <DialogTitle>
          {(userBlockData.blocked==true) ? `${t("USER_UNBLOCK")}`: `${t("USER_BLOCK")}`}
        </DialogTitle>
        <DialogContent>
        {(userBlockData.blocked==true) ? `${t("UNBLOCK_USER_CONFIRMATION")}`: `${t("BLOCK_USER_CONFIRMATION")}`}
        </DialogContent>
        <DialogActions>
            <Button
            autoFocus
            onClick={onClose}
            color="primary"
            >
            {t("BTN_CANCEL")}
            </Button>
            <Button onClick={deleteUser} color="primary">
            {t("YES")}
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default BlockUser
