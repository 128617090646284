import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../../assets/styles/materialStyles";

const DataFolder = ({ folderData, openFolder,classId,
  currentFolder }) => {
  const classes = useStyles();

 
  return (
    <>
      <div className="contentContainer">
        {folderData && folderData.name &&
        // <Grid item>
          <Card
            className={classes.cardroot}
            onClick={() => openFolder(folderData.id,classId)}
          >
            <CardHeader
              className="folderHeader"
              avatar={
                <Avatar
                  aria-label="recipe"
                  className={`${classes.avatar}  ${classes.cardAva}`}
                >
                  <FontAwesomeIcon className="" icon={faFolder} />
                </Avatar>
              }
              title={folderData.name}
            />
          </Card>
        // </Grid>
        }
      </div>
    </>
  );
};

export default DataFolder;
