import React, {useEffect, useState} from 'react';
import { forwardRef } from 'react';
import { makeStyles, Grid, Paper, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {API_URL, axiosConfigWithoutToken, axiosConfig} from '../../api/index';
import {useStyles} from '../../assets/styles/materialStyles';

import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import AddStudent from "./AddStudent";
import MaterialTable from 'material-table';
import Datatable from "../../commonComponents/datatable/index";
import Alert from '@material-ui/lab/Alert';
// import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// /datatable
require('es6-promise').polyfill();
require("isomorphic-fetch");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function validateEmail(email){
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const api = axios.create({
  baseURL: `https://reqres.in/api`
})

 const ViewStudents = () => {

  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  // const [data, setData]=useState({});
  const [searchText, setSearchText]=useState('');
  const [searchFlag, setSearchFlag]=useState(false);
  const [searchResults, setSearchResults]=useState({});
  const [{title}, dispatchSetTitle] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();
  const headersWithToken = axiosConfig(user.token);
  const [addStudentOpen, setAddStudentOpen] = useState(false);
  const [{assignmentParam}] = useStateValue();
  //
  // for datatble
  const[data,setData] = useState([]);
  const[q,setQ] = useState("");
  const[searchColumns,setSearchColumns] = useState(["firstname", "lastname"])

  useEffect(()=>{
    // fetch("https://devmentor.live/api/examples/contacts?api_key=40d26dcf")
    // .then((response)=>response.json())
    // .then((json) => 
    // // setData(json)
    // // co/
    // );

  },[]);


  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('ASSIGNMENTS')
    });
    fetchStudentsData();
  }, []);


  const fetchStudentsData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    const findStudents = axios.get(API_URL + 'student?token='+user.token, headers);
    const findCLasses = axios.get(API_URL + 'class?token='+user.token, headers);
    axios.all([findStudents, findCLasses])
    .then((response)=>{
      setData({
        assignmentClasses: response[0].data,
        classes: response[1].data
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }
  const handleAddStudentOpen = () => {
    console.log('open modal');
    setAddStudentOpen(true);
  };

  const handleAddStudentClose = () => {
    setAddStudentOpen(false);
  };

  const handleSearch = () => {
    if(searchText!=''){
      setSearchFlag(true);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      axios.get(API_URL + 'student/search?query='+searchText, headersWithToken)
      .then((response)=>{
        setSearchResults(response.data);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
      .catch((errors)=>{
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
    }
  }


  function search (rows){
    const columns = rows[0] && Object.keys(rows[0]);
    return rows.filter(row => 
     columns.some((column) => row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
      )
  }

  var columns = [
    {title: "id", field: "id", hidden: true},
    // {title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.first_name} />  },
    {title: "First Name", field: "first_name"},
    {title: "Last Name", field: "last_name"},
    {title: "Parent Name", field: "first_name"},
    {title: "Email", field: "email"}
  ]
  // const [data, setData] = useState([]); //table data
  //for error handling
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => { 
    api.get("/users")
        .then(res => {               
            setData(res.data.data)
         })
         .catch(error=>{
             console.log("Error")
         })
  }, [])

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = []
    if(newData.first_name === ""){
      errorList.push("Please enter first name")
    }
    if(newData.last_name === ""){
      errorList.push("Please enter last name")
    }
    if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }
    if(errorList.length < 1){
      api.patch("/users/"+newData.id, newData)
      .then(res => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);
        resolve()
        setIserror(false)
        setErrorMessages([])
      })
      .catch(error => {
        setErrorMessages(["Update failed! Server error"])
        setIserror(true)
        resolve()
      })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = []
    if(newData.first_name === undefined){
      errorList.push("Please enter first name")
    }
    if(newData.last_name === undefined){
      errorList.push("Please enter last name")
    }
    if(newData.email === undefined || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }
    if(errorList.length < 1){ //no error
      api.post("/users", newData)
      .then(res => {
        let dataToAdd = [...data];
        dataToAdd.push(newData);
        setData(dataToAdd);
        resolve()
        setErrorMessages([])
        setIserror(false)
      })
      .catch(error => {
        setErrorMessages(["Cannot add data. Server error!"])
        setIserror(true)
        resolve()
      })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    api.delete("/users/"+oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"])
        setIserror(true)
        resolve()
      })
  }
    return (
        <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}  justify="space-between">
           
            <Grid item xs={12} lg={8} sm={8} className="searchBottom ">
              <TextField
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch()
                    }
                  }}
                onChange={(e)=>setSearchText(e.target.value)}
                id="search"
                // placeholder={t('STUDENT_SEARCH')}
                name="search"
                placeholder={t('STUDENT_SEARCH')} variant="outlined" fullWidth
                 className="postInput mb-2 hsearch" InputProps={{endAdornment: <Link onClick={handleSearch}><FontAwesomeIcon className="textFieldSearchIcon" icon={faSearch} /></Link>}} />
            </Grid>
            <Grid item xs={12} lg={2} sm={2} className="mb-3">
              <Button onClick={handleAddStudentOpen}  variant="contained" className="yellowButton">{t('STUDENT_ADD_BTN')}</Button>
            </Grid>
            <Grid item xs={12} lg={2} sm={2} className="mb-3">
              <Button onClick={handleAddStudentOpen}  variant="contained" className="yellowButton">{t('STUDENT_DOWNLOAD_BTN')}</Button>
              <AddStudent open={addStudentOpen} onClose={handleAddStudentClose} data={data} reloadStudents={fetchStudentsData}/>
            </Grid>
            
          </Grid>
          {/*  */}
          {/* <div>
            <input type="text" value={q} onChange={(e) => setQ(e.target.value)}/>
          </div>
          <Datatable data={search(data)}/> */}
       {/* <div className="" style={{width: '100%'}}> */}
      <Grid container spacing={1}>
          {/* <Grid item xs={3}></Grid> */}
          <Grid item xs={12}>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable
            options={{actionsColumnIndex: -1}}
              title="Students Information"
              columns={columns}
              data={data}
              icons={tableIcons}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    handleRowAdd(newData, resolve)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              }}
            />
          </Grid>
          {/* <Grid item xs={3}></Grid> */}
        </Grid>
    {/* </div> */}
      {/*  */}
            </Paper>
            </Grid>
         
    )
}

export default ViewStudents;
