import React, {useEffect, useState} from 'react';
import { makeStyles, Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import ClassesHome from './ClassesHome';
import EditClass from './EditClass';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";

const Classes = () => {
  const classes = useStyles();
  const [{loading}] = useStateValue();
  const [{snack}] = useStateValue();
  const [{title}, dispatchSetTitle] = useStateValue();
  const { t } = useTranslation();

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('CLASSES')
    });
  }, []);

  return (
    <Grid item xs={12}>
      <Switch>
        <Route exact path={'*/classes/'} component={ClassesHome}/>
        <Route path={'*/classes/edit-class/:classId'} component={EditClass}/>
      </Switch>
      <SnackbarAlert />
      {loading ?
      <Loader active={true} />
      :
      null}
    </Grid>
  );
}

export default Classes;