import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPen, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {useStyles} from '../../assets/styles/materialStyles';
import ReactPlayer from 'react-player';

const VideoPreview = ({videoPreviewOpen, setVideoPreviewOpen, noteVideo, handleVideoPreviewClose}) => {
  const classes = useStyles();
  const [confirmExit, setConfirmExit] = useState(false);
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [{user}] = useStateValue();
  const { t } = useTranslation();

  useEffect(()=>{
    console.log(noteVideo);
  }, [videoPreviewOpen]);

  return (
    <Dialog onClose={handleVideoPreviewClose} aria-labelledby="note-video" open={videoPreviewOpen}>
        <DialogTitle>{noteVideo.noteName}</DialogTitle>
        <DialogContent>
            {(noteVideo && noteVideo.note && noteVideo.note.fileUrl) &&
                <div className="flexCenter">
                    <ReactPlayer url={noteVideo.note.fileUrl} controls={true} width="100%" height="auto" className="classBoardVideo"/>
                </div>
            }
        </DialogContent>
    </Dialog>
  );
}
export default VideoPreview;