import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { GridList, List, ListItem, ListItemAvatar, IconButton, Tooltip, TextField } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {useStyles} from '../../assets/styles/materialStyles';
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import UserAvatar from '../User/UserAvatar';

const ClassBoard = ({classId}) => {
  const classes = useStyles();
  const [data, setData]=useState();
  const [postToClassBoard, setPostToClassBoard] = useState('');
  const [postToClassBoardError, setPostToClassBoardError] = useState(false);
  const [postToClassBoardErrorMessage, setPostToClassBoardErrorMessage] = useState('');
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const headers = axiosConfig(user.token);

  useEffect(()=>{
    fetchClassboardData();
  }, []);

  const fetchClassboardData=()=>{
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.get(API_URL + 'post/class/'+classId, headers)
    .then((response) => {
      setData(response.data);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch(errors => {
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_PROCESS_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })      
  }

  //Class Board Text
  const classBoardContent = (feed) => {
    let dateToday = new Date().setHours(0,0,0,0);
    let today = new Date(dateToday);
    let yesterday = new Date(dateToday);
    yesterday=yesterday.setDate(yesterday.getDate() - 1);

    let content=[];
    //CREATE_ASSIGNMENT
    if(user.id == feed.creator.id){
      content.push(
        <div><strong>{t('YOU')}</strong></div>
      );
    }else{
      content.push(
        <div><strong>{feed.creator.firstName} {feed.creator.lastName}</strong></div>
      );
    }

    if(user.id == feed.creator.id && !user.semester.past){
      content.push(
        <span className="italic">{feed.text}</span>
      );
    }else if(user.id == feed.creator.id && user.semester.past){
      content.push(
        <span className="italic">{feed.text}</span>
      );
    }else if(user.id != feed.creator.id){
      content.push(
        <span className="italic">{feed.text}</span>
      );
    }

    if(feed.createdDate < yesterday){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {moment(feed.createdDate).format(t('DATE_POSTED_SHORT'))}</div>
      )}
    else if(feed.createdDate < today && feed.createdDate > yesterday){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {t('DATE_POSTED_YESTERDAY')} {moment(feed.createdDate).format(t('TIME'))}</div>
      )}
    else if(feed.createdDate > today){
      content.push(
      <div className="pt-3"><FontAwesomeIcon className="clockIcon" icon={faClock} /> {t('DATE_POSTED_TODAY')} {moment(feed.createdDate).format(t('TIME'))}</div>
      )}
    return (content);
  }

  //Post to Class Boards
  const handleSubmitPostToClassBoard = () => {
    if(postToClassBoard.length<10){
      setPostToClassBoardError(true);
      setPostToClassBoardErrorMessage(t('MSG_INVALID_POST_CLASS_BOARD'));
    }else{
      setPostToClassBoardError(false);
      setPostToClassBoardErrorMessage(t(''));
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      //const headers = axiosConfig(user.token);
      let headers={
        headers: {
          'jot-token': user.token,
          'text': postToClassBoard,
          'classId': classId,
          'postToWall': false
        },
        timeout: 20000,
        validateStatus: (status) => status >= 200 && status < 300
      };
      let postData=postToClassBoard;
      axios.post(API_URL+'post', postData, headers)
      .then(()=>{
          setPostToClassBoard('');
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_SUCCESS')
            }
          });
          fetchClassboardData();
      })
      .catch((errors)=>{
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_ERROR')
          }
        });
      })
    }
  }
  return (
    <GridList cellHeight={250} cols={1}>
      <List>
        {!user.semester.past &&
        <ListItem divider className="listItem" key={"classPost"}>
            <TextField
            value={postToClassBoard}
            onChange={(e)=>setPostToClassBoard(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleSubmitPostToClassBoard(); // here was the mistake
              }
            }}
            // id={classId}
            id="postToClassBoard"
            placeholder={t('CLASS_POST_BOARD')}
            name="postToClassBoard"
            error={postToClassBoardError}
            helperText={postToClassBoardErrorMessage}
            disabled={user.semester.past}
            placeholder={t('CLASS_POST_BOARD')} variant="outlined" fullWidth className="postInput" InputProps={{endAdornment: <Link onClick={handleSubmitPostToClassBoard}><FontAwesomeIcon className="textFieldChevronIcon" icon={faChevronCircleRight} /></Link>}} />
        </ListItem>
        }
        {(data && data.length>0) ?
        (data.map((feed, i) => (
          <ListItem key={i} divider={((i+1)!=data.length)}>
            <ListItemAvatar>
              <UserAvatar profile={feed.creator} />
            </ListItemAvatar>
            <div>
              {classBoardContent(feed)}
            </div>
          </ListItem>
        )))
        :
        <ListItem className="listItem">
            {t('MSG_NO_POSTS_CLASS_BOARD')}
        </ListItem>
        }
      </List>
    </GridList>
  );
}

export default ClassBoard;