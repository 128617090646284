import React, { useState, useEffect, useContext } from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, makeStyles, Container, Fade } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import Footer from '../Includes/Footer/Footer';
import Loader from '../Tools/Loader';
import { useTranslation } from "react-i18next";
import { useStateValue } from '../../store/StateProvider';
import { useHistory } from "react-router-dom";
import * as moment from 'moment';
import {API_URL, axiosConfigWithoutToken, SIGNIN_URL} from '../../api/index';
import axios from 'axios';
import AddNewSchool from './AddNewSchool';
import TermsConditions from './TermsConditions';
import SnackbarAlert from '../Tools/SnackbarAlert';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Registration = ({showLogin, page, setPage}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  //Set States
  const [errors, setErrors] = useState({});
  const [{user}, dispatch] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [registrationEmail, setRegistrationEmail] = useState('');
  const [registrationPassword, setRegistrationPassword] = useState('');
  const [registrationConfirmPassword, setRegistrationConfirmPassword] = useState('');
  const [isTeacher, setIsTeacher] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [school, setSchool] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [serverError, setServerError] = useState('');
  const [addNewSchool, setAddNewSchool] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [schools, setSchools] = useState([]);
  const headersWithoutToken = axiosConfigWithoutToken();
  const [success, setSuccess] = useState(false);

  const handleRegistration = (e) => {
    e.preventDefault();
    if(validateRegistration()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
        let userData={
          firstName,
          lastName,
          email: registrationEmail,
          professor: isTeacher,
          password: registrationPassword,
          passwordConfirmation:registrationConfirmPassword,
          termsAndConditions: isAgreed,
          universityId: schoolId
        }
        axios.post(API_URL+'user', userData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setFirstName('');
          setLastName('');
          setRegistrationEmail('');
          setRegistrationPassword('');
          setRegistrationConfirmPassword('');
          setIsTeacher(false);
          setIsAgreed(false);
          setSchool('');
          setSchoolId('');
          setServerError('');
          //onClose();
          setSuccess(true);
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('RECORD_PROCESSED_SUCCESS')
            }
          });
        })
        .catch((error) => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
    }
  }

  const loadUniversities = () => {
    axios.get(API_URL + 'university/search?name='+school, headersWithoutToken)
    .then((response)=>{
        setSchools(response.data);
        console.log(response.data);
    })
    .catch((errors)=>{
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
        }
        });
        //console.log(errors);
    })
  }

  useEffect(()=>{
    loadUniversities();
  }, [school]);

  const validateRegistration = () => {
    let errorsData = {};
    let formIsValid = true;

    if(firstName==''){
        formIsValid = false;
        errorsData["firstName"] = t('MSG_EMPTY_FIRST_NAME');
      }
  
      if(firstName!=''&&firstName.length<2){
        formIsValid = false;
        errorsData["firstName"] = t('MSG_SHORT_FIRST_NAME');
      }
  
      if(firstName!=''&&firstName.length>50){
        formIsValid = false;
        errorsData["firstName"] = t('MSG_LONG_FIRST_NAME');
      }
  
      if(lastName==''){
        formIsValid = false;
        errorsData["lastName"] = t('MSG_EMPTY_LAST_NAME');
      }

      if(schoolId==''){
        formIsValid = false;
        errorsData["school"] = t('MSG_EMPTY_SCHOOL');
      }
  
      if(lastName!=''&&lastName.length<2){
        formIsValid = false;
        errorsData["lastName"] = t('MSG_SHORT_LAST_NAME');
      }
  
      if(lastName!=''&&lastName.length>50){
        formIsValid = false;
        errorsData["lastName"] = t('MSG_LONG_LAST_NAME');
      }

    if(registrationEmail==''){
      formIsValid = false;
      errorsData["registrationEmail"] = t('MSG_EMPTY_EMAIL');
    }

    if(registrationEmail!=''){
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(reg.test(registrationEmail.toLowerCase())==false){
        formIsValid = false;
        errorsData["registrationEmail"] = t('MSG_INVALID_EMAIL');
      }
    }

    if(registrationPassword==''){
      formIsValid = false;
      errorsData["registrationPassword"] = t('MSG_EMPTY_PASSWORD');
    }

    if(registrationConfirmPassword==''){
        formIsValid = false;
        errorsData["registrationConfirmPassword"] = t('MSG_EMPTY_CONFIRM_PASSWORD');
      }

      if((registrationPassword!='')&&(registrationConfirmPassword!='')&&(registrationConfirmPassword!=registrationPassword)){
          formIsValid = false;
          errorsData["registrationConfirmPassword"] = t('MSG_PASSWORD_NOT_MATCH');
        }

      if(!isAgreed){
          formIsValid = false;
          errorsData["isAgreed"] = t('IS_AGREED_ERROR_MSG');;
        }

    setErrors(errorsData);
    return formIsValid;
  }

  const handleAddnewSchoolOpen = () => {
    setAddNewSchool(true)
  }
  const handleAddnewSchoolClose = () => {
    setAddNewSchool(false)
  }
  const handleShowTermsOpen = () => {
    setShowTerms(true)
  }
  const handleShowTermsClose = () => {
    setShowTerms(false)
  }
  
  return (
    <div>
        {serverError!=""?
        <Alert severity="error" className={classes.errorAlert}>
        {serverError}
        </Alert>
        :null}
        {success?
          <Alert severity="success" className="alertBox">
            {t('REGISTRATION_SUCCESS')}
          </Alert>
          :null}
        <form className={classes.form} noValidate onSubmit={handleRegistration}>
            <TextField
            value={firstName}
            onChange={(e)=>setFirstName(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label={t('PROFILE_FIRST_NAME')}
            placeholder={t('PROFILE_FIRST_NAME')}
            name="firstName"
            autoComplete="firstName"
            error={errors["firstName"]}
            helperText={errors["firstName"]}
            autoFocus
            />
            <TextField
            value={lastName}
            onChange={(e)=>setLastName(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label={t('PROFILE_LAST_NAME')}
            placeholder={t('PROFILE_LAST_NAME')}
            name="lastName"
            autoComplete="lastName"
            error={errors["lastName"]}
            helperText={errors["lastName"]}
            />
            <TextField
            value={registrationEmail}
            onChange={(e)=>setRegistrationEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="registrationEmail"
            label={t('EMAIL_ADDRESS')}
            placeholder={t('EMAIL_ADDRESS')}
            name="registrationEmail"
            autoComplete="registrationEmail"
            error={errors["registrationEmail"]}
            helperText={errors["registrationEmail"]}
            />
            <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAddnewSchoolOpen}
            className={classes.submit}
            >
            {t('ADD_NEW_SCHOOL')}
            </Button>
            <AddNewSchool  open={addNewSchool} onClose={handleAddnewSchoolClose}/>
            <Autocomplete className="auto-cross"
              id="combo-box-demo"
              options={schools}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSchoolId(newValue.id);
              }}
              renderInput={(params) => <TextField onChange={(e)=>setSchool(e.target.value)} 
              error={errors["school"]}
              fullWidth
              helperText={errors["school"]} {...params} label={t('SCHOOLS_COLLEGES_UNIVERSITIES')} variant="outlined" />}
            />
            <div className="flexStart">
                <FormControlLabel
                control={<Checkbox onChange={(e)=>{setIsTeacher(e.target.checked)}} name="isTeacher" />} checked={isTeacher} />
                <span className="label text-left">{t('PROFESSOR_I_AM')}</span>
            </div>
            {isTeacher!=""?
            <Alert severity="error" className="alertBox align-left">
            <div>{t('PROFESSOR_MSG_1')}</div>
            <div>{t('PROFESSOR_MSG_2')}</div>
            </Alert>
            :null}
            <TextField
            value={registrationPassword}
            onChange={(e)=>setRegistrationPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="registrationPassword"
            label={t('PASSWORD')}
            type="password"
            placeholder={t('PASSWORD')}
            // type="registrationPassword"
            id="registrationPassword"
            autoComplete="current-registrationPassword"
            error={errors["registrationPassword"]}
            helperText={errors["registrationPassword"]}
            />
            <TextField
            value={registrationConfirmPassword}
            onChange={(e)=>setRegistrationConfirmPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="registrationConfirmPassword"
            label={t('PROFILE_CONFIRM_PASSWORD')}
            placeholder={t('PROFILE_CONFIRM_PASSWORD')}
            type="password"
            // type="registrationConfirmPassword"
            id="registrationConfirmPassword"
            autoComplete="current-registrationConfirmPassword"
            error={errors["registrationConfirmPassword"]}
            helperText={errors["registrationConfirmPassword"]}
            />
            <div className="flexStart mb-1">
                <FormControlLabel
                control={<Checkbox onChange={(e)=>{setIsAgreed(e.target.checked)}} name="isAgreed" />} checked={isAgreed}
                error={errors["isAgreed"]}
                helperText={errors["isAgreed"]}
                />
                <span className="label text-left">{t('I_AGREE_TO')} <Link href="#" onClick={handleShowTermsOpen}>{t('TERMS_AND_PRIVACY')}</Link></span>
            </div>
            <TermsConditions open={showTerms} onClose={handleShowTermsClose} />
            <div>
              {(!isAgreed && errors["isAgreed"] && errors["isAgreed"]!="") &&
                <Alert severity="error" className={classes.errorAlert}>
                {errors["isAgreed"]}
                </Alert>
                }
            </div>
            <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            >
            {t('REGISTER_ME')}
            </Button>
            <Grid container>
            <Grid item xs={12}>
            <Link href="/login" onClick={showLogin} variant="body2">
                {t('SIGN_IN')}
                </Link>
            </Grid>
            </Grid>
            <Box mt={8}>
            <Footer />
            </Box>
        </form>
          <SnackbarAlert />
          {loading ?
          <Loader active={true} />
          :
          null}
    </div>
  );
}

export default Registration;