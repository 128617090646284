import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Badge, Menu, MenuItem,Tooltip } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import {NavLink} from 'react-router-dom';
import {useStateValue} from '../../../store/StateProvider';
import Avatar from '@material-ui/core/Avatar';
import HeaderNotifcations from './HeaderNotifications';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      width: 50
    },
    profileImage:{
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    }
  }));

const Header = ({toggleDrawerOpen, t, drawerOpen}) => {
  const [{title, user}] = useStateValue();
  const classes = useStyles();
  const [profileMenu, setProfileMenu] = React.useState(false);
  const [notificationsMenu, setNotificationsMenu] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [{inviteEventsCount, addNoteEventsCount, shareNoteEventsCount, addAssignmentEventsCount, shareAssignmentEventsCount, feedbackEventsCount, addPostEventsCount, chatEventsCount}] = useStateValue();

  useEffect(()=>{
  },[inviteEventsCount, addNoteEventsCount, shareNoteEventsCount, addAssignmentEventsCount, shareAssignmentEventsCount, feedbackEventsCount, addPostEventsCount, chatEventsCount]);

  //Mobile Menu Open
  const handleMobileMenuOpen = (event) => {
    setMobileMenu(event.currentTarget);
  };

  //Mobile Menu Close
  const handleMobileMenuClose = () => {
    setMobileMenu(false);
  };
  
  //Profile Menu Open
  const handleProfileMenuOpen = (event) => {
    setProfileMenu(event.currentTarget);
  };

  //Profile Menu Close
  const handleProfileMenuClose = () => {
    setProfileMenu(false);
    handleMobileMenuClose();
  };

  //Notifications Menu Open
  const handleNotificationsMenuOpen = (event) => {
    setNotificationsMenu(event.currentTarget);
  };

  //Notifications Menu Close
  const handleNotificationsMenuClose = () => {
    setNotificationsMenu(false);
    handleMobileMenuClose();
  };
  
  //Profile Menu
  const menuId = 'primary-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={profileMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={menuId}
      keepMounted
      open={profileMenu}
      onClose={handleProfileMenuClose}
    >
      <MenuItem component={NavLink} to="/profile" onClick={handleProfileMenuClose}>{t('PROFILE')}</MenuItem>
      <MenuItem component={NavLink} to="/logout" onClick={handleProfileMenuClose}>{t('MENU_LOGOUT')}</MenuItem>
    </Menu>
  );

  let imageIconMobile=[];
  if(user.professor === false){

    if(user.profileImageThumbnail){
      imageIconMobile.push(
      
        <div style={{border:'2px solid',color:'#dddddd', borderRadius:'50%'}}>
        <div style={{border:'2px solid',color:'#ffffff', borderRadius:'50%'}}>
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
        </div>
        </div>
        );

    }else{
      imageIconMobile.push(
        <AccountCircle />
              );
    }
    
  }
  if(user.professor === true){

    if(user.profileImageThumbnail){
      imageIconMobile.push(
        <div style={{border:'2px solid',color:'#e86129', borderRadius:'50%'}}>
        <div style={{border:'0.5px solid',color:'#ffffff', borderRadius:'50%'}}>
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
      </div>
      </div>
        );

    }else{
      imageIconMobile.push(
        <AccountCircle />
              );
    }
   
  }else if(user.professor === ''){

    if(user.profileImageThumbnail){
      imageIconMobile.push(
      
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
      
        );

    }else{
      imageIconMobile.push(
        <AccountCircle />
              );
    }
    
  }

  let imageIcon=[];
  if(user.professor === false){

    if(user.profileImageThumbnail){
      imageIcon.push(
      
        <div style={{border:'2px solid',color:'#dddddd', borderRadius:'50%'}}>
        <div style={{border:'2px solid',color:'#ffffff', borderRadius:'50%'}}>
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
        </div>
        </div>
        );

    }else{
      imageIcon.push(
        <AccountCircle />
              );
    }
    
  }
  if(user.professor === true){

    if(user.profileImageThumbnail){
      imageIcon.push(
        <div style={{border:'2px solid',color:'#e86129', borderRadius:'50%'}}>
        {/* <div style={{border:'0.5px solid',color:'#ffffff', borderRadius:'50%'}}> */}
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
      {/* </div> */}
      </div>
        );

    }else{
      imageIcon.push(
        <AccountCircle />
              );
    }
   
  }else if(user.professor === ''){

    if(user.profileImageThumbnail){
      imageIcon.push(
      
        <Avatar alt="Profile Image" src={user.profileImageThumbnail} className={classes.profileImage} />
      
        );

    }else{
      imageIcon.push(
        <AccountCircle />
              );
    }
    
  }
    



  //Mobile Menu
  const mobileMenuId = 'primary-account-menu-mobile';
  const renderMobileMenu = (
   
 
    <Menu
      anchorEl={mobileMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={mobileMenuId}
      keepMounted
      open={mobileMenu}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleNotificationsMenuOpen}>
        <IconButton aria-label={t('NOTIFICATIONS')}  color="inherit">
          <Badge badgeContent={inviteEventsCount, addNoteEventsCount, shareNoteEventsCount, addAssignmentEventsCount, shareAssignmentEventsCount, feedbackEventsCount, addPostEventsCount, chatEventsCount} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {t('NOTIFICATIONS')}
      </MenuItem>
      <Tooltip title={user.firstName + " " + user.lastName +" "+ ((user.professor === true) ? 'Teacher' : 'Student')} arrow>
      <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {imageIconMobile}
            {/* {user.profileImageThumbnail?
            <Avatar alt={user.firstName+" "+user.lastName} src={user.profileImageThumbnail} className={classes.profileImage} />
            :
            <AccountCircle />
            } */}
          </IconButton>
        {t('ACCOUNT')}
      </MenuItem>
    </Tooltip>
    </Menu>
  );

 
    
 

    return (
        <div>
            <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="drawerOpen drawer"
                    onClick={toggleDrawerOpen}
                >
                    <MenuIcon />
                </IconButton>
                <Typography className={classes.title} variant="h6" noWrap>
                    {t(title)}
                </Typography>
                {!drawerOpen &&
                <Typography variant="h6" noWrap>
                    <img src={require('../../../assets/images/logoWhite.png')} className={classes.logo} />
                </Typography>
                }
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    <IconButton aria-label={t('NOTIFICATIONS')} color="inherit" onClick={handleNotificationsMenuOpen}>
                    <Badge badgeContent={inviteEventsCount, addNoteEventsCount, shareNoteEventsCount, addAssignmentEventsCount, shareAssignmentEventsCount, feedbackEventsCount, addPostEventsCount, chatEventsCount} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                    </IconButton>
                    {/*  tooltip start */}
                    <Tooltip title={user.firstName + " " + user.lastName +" "+ ((user.professor === true) ? 'Teacher' : 'Student')} arrow>
                    <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"  >
                      {/*  */}

                     {imageIcon}

                      {/*  */}
                    </IconButton>
                    </Tooltip>
                    {/*  tooltip end */}
                </div>
                <div className={classes.sectionMobile}>
                   {/*  tooltip start */}
                    <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                    >
                    <MoreIcon />
                    </IconButton>
                </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <HeaderNotifcations notificationsMenu={notificationsMenu} handleNotificationsMenuClose={handleNotificationsMenuClose} handleMobileMenuClose={handleMobileMenuClose} />
        </div>
    );
}

export default Header;