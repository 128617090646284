import React,{useEffect,useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
  } from "@material-ui/core";
  import {useStateValue} from "../../store/StateProvider"
import { useTranslation } from "react-i18next"
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  




const DeleteFolder = ({open,onClose,goBack,reloadFolders=null,currentFolder,openedFolderId}) => {
    const { t } = useTranslation();
    const[deleteFolderId,setDeleteFolderId] = useState('');
    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [{user}] = useStateValue();
    const headers = axiosConfig(user.token);



    useEffect(()=>{
        setDeleteFolderId(currentFolder.id);
    },[currentFolder])



  const deleteFolder = () =>{
   
      axios.delete(API_URL+ 'folder/'+deleteFolderId,headers)
      .then((response)=>{
        if(!openedFolderId){
         
       }
        dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_DELETED')
            }
          });
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
           
            onClose();
            goBack();
      }).catch((errors)=>{
          onClose();
        dispatchSnack({
            type: 'SET_SNACK',
            snack: {
                flag: true,
                message: t('MSG_LOAD_DATA_ERROR')
            }
            });
           
            dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
      });

  }



    return (
        <Dialog
        open={open}
        aria-labelledby="class-exit-confirmation"
        >
        <DialogTitle>{t("DELETE_FOLDER")}</DialogTitle>
        <DialogContent>
            {t("DELETE_MSG_CONFIRMATION")}
        </DialogContent>
        <DialogActions>
            <Button
            autoFocus
            onClick={onClose}
            color="primary"
            >
            {t("BTN_CANCEL")}
            </Button>
            <Button onClick={deleteFolder} color="primary">
            {t("YES")}
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default DeleteFolder
