import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import UserAvatar from '../User/UserAvatar';

const DashboardSummary = ({dataCount}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.welcomeCard}>
            <Grid>
              <UserAvatar profile={user} size="large" containerStyles="mr-3" />
            </Grid>
            <Grid>
              <div>
                <Typography variant="h5">
                  {t('HOME_SD_WELCOME')+" "+user.firstName+" "+user.lastName}
                </Typography>
              </div>
              <div>
                <Typography color="secondary" variant="h6">
                  {user.university.name}
                </Typography>
              </div>
            </Grid>
          </Paper>
        </Grid>
        {/*  */}
        {/* <Grid item xs={12} sm={4}>
          <Paper className={classes.infoCard1}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.upcomingClassesCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
              {(dataCount.upcomingClassesCount>1)?t('HOME_SD_UPCOMING_CLASSES'):t('HOME_SD_UPCOMING_CLASS')}
            </Typography>
          </Paper>
        </Grid> */}
        {/* // */}
        {/* <Grid item xs={12} sm={4}>
          <Paper className={classes.infoCard2}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.dueAssignmentsCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
            {(dataCount.dueAssignmentsCount>1)?t('HOME_SD_ASSIGNMENTS_DUE'):t('HOME_SD_ASSIGNMENT_DUE')}
            </Typography>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12} sm={4}>
          <Paper className={classes.infoCard3}>
            <Typography variant="h3" className={classes.bigFont}>
              {dataCount.dueTasksCount}
            </Typography>
            <Typography variant="h6" className={classes.bigFont}>
            {(dataCount.dueTasksCount>1)?t('HOME_SD_TASKS_DUE'):t('HOME_SD_TASK_DUE')}
            </Typography>
          </Paper>
        </Grid> */}
      </Grid>
    </Paper>
  );
}

export default DashboardSummary;