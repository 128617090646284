import React, {useState,useEffect,useRef} from 'react';
import { Card, Typography, Checkbox, FormControlLabel, AppBar, Tabs, Tab } from '@material-ui/core';
import axios from 'axios';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import {API_URL, axiosConfig} from '../../api/index';
import $ from "jquery"; 
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Tasks = ({reloadEvents, tasksList, eventsCount, totalEvents}) => {
  const classes = useStyles();

  const [{user}] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const[{specificTaskId}] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [serverError, setServerError] = useState('');
  const { t } = useTranslation();
  const [tab, setTab] = useState('all');
  const headers = axiosConfig(user.token);
  
 

  const handleChange = (event, id) => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
      axios.put(API_URL+"event/" + id + "?complete=" + event.target.checked, null, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        reloadEvents();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
  };

  


  useEffect(()=>{
    if(specificTaskId){
      if(document.getElementById(specificTaskId)){
        document.getElementById(specificTaskId).scrollIntoView({behavior: 'smooth',  block: 'nearest'});
        // document.getElementById(specificTaskId).scrollIntoView({behavior: 'smooth',  block: 'nearest'});
      }
    }
   
  },[specificTaskId])

  return (
    <Card>
        <div className="header" >
            <Typography variant="h6">
                {t('EVENT_TODO')}
            </Typography>
        </div>
        <div className="contentContainer">
            {(tasksList && tasksList.length)>0
            ?
            <div>
                <AppBar position="static">
                <Tabs aria-label="tabs" value={tab} onChange={(e, val)=>setTab(val)} TabIndicatorProps={{ className: classes.indicator }}>
                <Tab label={t('EVENT_ALL')} value="all" />
                <Tab label={t('EVENT_ACTIVE')} value="active" />
                <Tab label={t('EVENT_COMPLETED')} value="completed" />
                </Tabs>
            </AppBar>
            {
            tasksList.map((task, i)=>(
                (task.completed)?
                (
                (tab=="all" || tab=="completed") &&
                <div className="completedTask bb task "    id={task.id} >
                    <FormControlLabel
                    control={<Checkbox onChange={(e)=>{handleChange(e, task.id)}} name={"cbox_"+i} />} checked={task.completed} />
                    <span className="task-name">{task.name}</span>
                </div>
                )
                :
                (
                (tab=="all" || tab=="active") &&
                <div className="uncompletedTask bb task "   id={task.id}
               
                >
                  <FormControlLabel
                    control={<Checkbox checked={task.completed} onChange={(e)=>{handleChange(e, task.id)}} name={"cbox_"+i}  />} />
                    <span className="task-name">{task.name}</span><br />
                    <span className="task-name"><strong>{t('STARTS_ON')}:</strong> {moment(task.startTime).format(t('DATE_POSTED'))}</span><br />
                    {(task.location && task.location!="") &&
                    <span>
                      <span className="task-name"><strong>{t('LOCATION')}</strong> {task.location}</span><br />
                    </span>}
                    {(task.location && task.location!="") &&
                    <span>
                      <span className="task-name"><strong>{t('DESCRIPTION')}</strong> {task.location}</span>
                      <br />
                    </span>
                  }
                      <span className="task-name"><strong>{t('DUE_ON')}:</strong> {moment(task.endTime).format(t('DATE_POSTED'))}</span>
                </div>
                
                )
            ))
            }
            
            <div className="bb task"  >
                <strong>{eventsCount + " " + t('OF') + " " + totalEvents}</strong> {t('EVENT_COMPLETED')}
            </div>
            </div>
            :
            t('EVENT_NO_ITEMS')
            }
        </div>
    </Card>
  );
}

export default Tasks;