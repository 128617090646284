//API
// export const API_URL = 'http://68.183.117.250:8080/';
export const API_URL = process.env.REACT_APP_API_URL
//export const API_URL = 'https://jot.academy:8443/jot-api/';
export const SOCKET_API = process.env.REACT_APP_SOCKET_API
//export const SOCKET_API = "wss://jot.academy:8443/jotChat";
export const SIGNIN_URL = API_URL + 'user/login'
export const REGISTER_URL = API_URL + 'user'
export const SIGNIN_TOKEN_URL = API_URL + 'user/loginByToken'

export const axiosConfig = (token) => {
  return {
    headers: {
      'jot-token': token,
    },
    timeout: 35000,
    validateStatus: (status) => status >= 200 && status < 300,
  }
}

export const axiosConfigWithoutToken = () => {
  return {
    timeout: 35000,
    validateStatus: (status) => status >= 200 && status < 300,
  }
}
