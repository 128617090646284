import React, {useEffect, useState} from 'react';
import { makeStyles, Grid, Paper, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {API_URL, axiosConfigWithoutToken, axiosConfig} from '../../api/index';
import Assignment from './Assignment';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddAssignment from './AddAssignment';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';

const ClassAssignments = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [data, setData]=useState({});
  const [searchText, setSearchText]=useState('');
  const [searchFlag, setSearchFlag]=useState(false);
  const [searchResults, setSearchResults]=useState({});
  const [{title}, dispatchSetTitle] = useStateValue();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();
  const headersWithToken = axiosConfig(user.token);
  const [addAssignmentOpen, setAddAssignmentOpen] = useState(false);
  const [{assignmentParam}] = useStateValue();

  let { assignmentId } = useParams();

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('ASSIGNMENTS')
    });
    fetchAssignmentsData();
  }, []);
  const fetchAssignmentsData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    const findAssignmentClasses = axios.get(API_URL + 'assignment?token='+user.token, headers);
    const findCLasses = axios.get(API_URL + 'class?token='+user.token, headers);
    axios.all([findAssignmentClasses, findCLasses])
    .then((response)=>{
      setData({
        assignmentClasses: response[0].data,
        classes: response[1].data
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }
  const handleAddAssignmentOpen = () => {
    setAddAssignmentOpen(true);
  };

  const handleAddAssignmentClose = () => {
    setAddAssignmentOpen(false);
  };

  const handleSearch = () => {
    if(searchText!=''){
      setSearchFlag(true);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      axios.get(API_URL + 'assignment/search?query='+searchText, headersWithToken)
      .then((response)=>{
        setSearchResults(response.data);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
      .catch((errors)=>{
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
    }
  }
 
 
  const isExpanded = (i, id) => {
    if(!assignmentParam && i==0){
        return true;
      }else{
        if(assignmentParam==id){
          return true;
        }
      }
      return false;
  }
  // const isExpanded = (i, id) => {
  //   if(!assignmentId && i==0){
  //       return true;
  //     }else{
  //       if(assignmentId==id){
  //         return true;
  //       }
  //     }
  //     return false;
  // }
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container spacing={3} className={classes.root}  justify="space-between">
          <Grid item xs={12} lg={8} sm={8} className="searchBottom">
            <TextField
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()
                  }
                }}
              onChange={(e)=>setSearchText(e.target.value)}
              id="search"
              placeholder={t('ASSIGNMENT_SEARCH')}
              name="search"
              placeholder={t('ASSIGNMENT_SEARCH')} variant="outlined" fullWidth
               className="postInput mb-2 hsearch" InputProps={{endAdornment: <Link onClick={handleSearch}><FontAwesomeIcon className="textFieldSearchIcon" icon={faSearch} /></Link>}} />
          </Grid>
          <Grid item xs={12} lg={4} sm={4} className="mb-3">
            <Button onClick={handleAddAssignmentOpen}  variant="contained" className="yellowButton">{t('ASSIGNMENT_ADD_BTN')}</Button>
            <AddAssignment open={addAssignmentOpen} onClose={handleAddAssignmentClose} data={data} reloadAssignments={fetchAssignmentsData}/>
          </Grid>
        </Grid>
        {(searchFlag && !loading && searchText!='') &&
          <Accordion className="mb-3" className="searchResultDivider" defaultExpanded={true}>
            <div className="accordionHeader accordiansearch">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"searchResults-content"}
                id={"searchResults"}
                className="searchAccordianColor"
              >
                <div className="searchLink">
                <Link to="#"><Typography variant="h6" className="searchAccordianTextColor">{t('SEARCH_RESULTS')}</Typography></Link>
                </div>
              </AccordionSummary>
            </div>
              {searchResults.length>0?
                <Grid item xs={12}>
                  <div className="contentContainer">
                      <Grid container spacing={3}>
                        {searchResults.map((assignment, i)=>(
                          <Assignment data={assignment} classProfessor={assignment.professor} />
                        ))}
                      </Grid>
                  </div>
                </Grid>
              :
                <Grid item xs={12}>
                  <div className="contentContainer">
                    <div>{t('NO_SEARCH_RESULTS')}</div>
                  </div>
                </Grid>
              }
          </Accordion>
        }
          {data && data.assignmentClasses && data.assignmentClasses.length>0 &&
            (data.assignmentClasses.map((classData, i)=>(
              (classData.assignments.length>0 &&
              <Accordion defaultExpanded={isExpanded(i, classData.id)} className="mb-3">
                <div className="accordionHeader">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={classData.id+"-content"}
                    id={classData.id}
                  >
                    <Link to="#"><Typography variant="h6">{classData.name}</Typography></Link>
                  </AccordionSummary>
                </div>
                <Grid item xs={12}>
                  <div className="contentContainer">
                      <Grid container spacing={3}>
                        {classData.assignments.map((assignment, i)=>(
                          <Assignment data={assignment} classProfessor={classData.professor} />
                        ))}
                      </Grid>
                  </div>
                </Grid>
              </Accordion>
              )
            )))
          }
          <SnackbarAlert />
          {loading ?
          <Loader active={true} />
          :
          null}
      </Paper>
    </Grid>
  );
}

export default ClassAssignments;