import React, {useEffect, useState} from 'react';
import { IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import AddTerm from './AddTerm';

const Term = ({ onClose, open, data, reloadProfile, addTerm=true,loadSemesterData }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [semesters, setSemesters] = useState({});
  const [addTermOpen, setAddTermOpen] = useState(false);

  

  const handleAddTermOpen = () => {
    setAddTermOpen(true);
  };
  
  const handleAddTermClose = () => {
    setAddTermOpen(false);
  };

  return (
    <div>
        <div className="flexJustifySpaceBetween p-3">
          {/* <div>
            <div className="orange"><strong>Term 2020-2021 [Current Term]</strong></div>
            <div>July 17, 2020 - December 21, 2020</div>
          </div> */}
          <div>
            {(user.semester && user.semester.id == data.id)?
            <div>
              <div className={(data.current)?"orange":""}> <strong>{data.name} {(data.current)?t('PROFILE_CURRENT_SEMESTER'):t('PROFILE_VIEW_SEMESTER')}</strong></div>
              <div>{moment(data.startDate).format(t('ONLY_DATE_POSTED'))} - {moment(data.endDate).format(t('ONLY_DATE_POSTED'))}</div>
            </div>
            :
            <div>
              <div> <strong>{data.name} {(data.current)?t('PROFILE_CURRENT_SEMESTER'):null}</strong></div>
              <div>{moment(data.startDate).format(t('ONLY_DATE_POSTED'))} - {moment(data.endDate).format(t('ONLY_DATE_POSTED'))}</div>
            </div>
            }
          </div>
          <div>
            <IconButton className="headerIconButton" onClick={handleAddTermOpen}>
              <FontAwesomeIcon className="editIcon" icon={faPen} />
            </IconButton>
          </div>
        </div>
        <AddTerm open={addTermOpen} onClose={handleAddTermClose} reloadProfile={reloadProfile} data={data} loadSemesterData={loadSemesterData} />
    </div>
);
}

export default Term;