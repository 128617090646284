import React, {useState, useEffect} from 'react';
import { Paper,Menu, MenuItem, List, ListItem, ListItemAvatar, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions,Tooltip,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig, axiosConfigWithoutToken} from '../../api/index';
import { useHistory } from "react-router-dom";
import UserAvatar from '../User/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment'; 
import VideoPreview from './VideoPreview';
import {Row,Col} from "reactstrap";
import Iframe from "react-iframe";
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


const DashboardNotifications = ({notificationsMenu, handleNotificationsMenuClose, notificationsMenuId, setDashboardNotificationsCount}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [videoPreviewOpen, setVideoPreviewOpen] = useState(false);
  const [noteVideo, setNoteVideo] = useState(false);
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [serverError, setServerError] = useState('');
  const { t } = useTranslation();
  const headers = axiosConfig(user.token);
  const headersWOToken = axiosConfigWithoutToken();
  const [sc, setSc]=useState(null);
  const [data, setData]=useState([]);
  const [dataCount, setDataCount]=useState([]);
  const history = useHistory();
  const [iframeOpen, setiframeOpen] = useState(false);
  const [noteId,setNoteId] = useState();


  const windowOpen = (id) => {
    setNoteId(id)
    setiframeOpen(true);
  };

  const windowClose = () => {
    setiframeOpen(false);
  };

  useEffect(()=>{
    fetchClassBoardNotifications();
  }, []);

  const fetchClassBoardNotifications = ()=>{
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    const findInvitesToClasses = axios.get(API_URL + "invite?token="+user.token, headersWOToken);
    const findNotesSharesByUser = axios.get(API_URL + 'note/share', headers);
    const findAssignmentsShares = axios.get(API_URL + 'assignment/share', headers);
    const findFeedbacks = axios.get(API_URL + 'home/feedback', headers);

    axios.all([findInvitesToClasses, findNotesSharesByUser, findAssignmentsShares, findFeedbacks])
    .then(axios.spread((...response) => {
      setData({
        invites: response[0].data,
        noteShares: response[1].data,
        assignmentsShares: response[2].data,
        feedbacks: response[3].data
      });
      setDataCount({
        invitesCount: response[0].data.length,
        noteSharesCount: response[1].data.length,
        assignmentsSharesCount: response[2].data.length,
        feedbacksCount: response[3].data.length
      });
      
      setDashboardNotificationsCount(response[0].data.length+response[1].data.length+response[2].data.length+response[3].data.length)

      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })).catch(errors => {
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
  }

  const executeAcceptOnInvite = (id, type) => {
    dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
    });
    axios.post(API_URL+'invite/class/accept/'+ id + "?type=" + type, null, headers)
    .then(response => {
        fetchClassBoardNotifications();
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_SUCCESS')
        }
        });
    })
    .catch((error) => {
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
    });
  }
  const executeRejectOnInvite = (id, type) => {
    dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
    });
    axios.post(API_URL+'invite/class/reject/'+ id + "?type=" + type, null, headers)
    .then(response => {
        fetchClassBoardNotifications();
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_SUCCESS')
        }
        });
    })
    .catch((error) => {
       
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
    });
  }
  const executeActionOnNoteShare = (id, type) => {
    dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
    });
    axios.post(API_URL+'note/share/'+ type + "/" + id, null, headers)
    .then(response => {
        fetchClassBoardNotifications();
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_SUCCESS')
        }
        });
    })
    .catch((error) => {
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
    });
  }
  const showVideoPreview = (note) => {
    setNoteVideo(note);
    setVideoPreviewOpen(true);
  }
  const goToNote = (id) => {
    history.push('/notes/view-note/'+id);
  }
  const executeActionOnAssignmentShare = (id, type) => {
    dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
    });
    axios.post(API_URL+'assignment/share/'+ type + "/" + id, null, headers)
    .then(response => {
        fetchClassBoardNotifications();
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_SUCCESS')
        }
        });
    })
    .catch((error) => {
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
    });
  }
  const executeActionOnChangeFeedback = (id, type) => {
    dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
    });
    axios.put(API_URL+"home/feedback?feedbackId=" + id + "&action=" + type, null, headers)
    .then(response => {
        fetchClassBoardNotifications();
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        dispatchSnack({
        type: 'SET_SNACK',
        snack: {
            flag: true,
            message: t('MSG_SUCCESS')
        }
        });
    })
    .catch((error) => {
        dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
    });
  }

  const handleVideoPreviewClose = () => {
    setVideoPreviewOpen(false);
  };

  return (
      <>
      
    <Menu
      anchorEl={notificationsMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={notificationsMenuId}
      keepMounted
      open={notificationsMenu}
      onClose={handleNotificationsMenuClose}
      > 
      {(dataCount.invitesCount>0) &&
              data.invites.map((invite, i) => (
              <MenuItem className="nowhitespace" key={i} divider>
                <ListItemAvatar>
                  <UserAvatar profile={invite.fromUser} />
                </ListItemAvatar>
                <div>
                <div>
                    {(invite.type == 'professor') &&
                    <div>
                    {(invite.userAsProfessor && (user.id == invite.userAsProfessor.id)) &&
                    <div>
                    <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong> {t('HOME_INVITED_TO_CLASS')}
                        <strong className="orange ml-1">{invite.className}</strong>
                    </div>
                    }
                    {(invite.userAsProfessor && (user.id != invite.userAsProfessor.id)) &&
                    <div>
                    <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong>
                    {t('HOME_INVITED')} <strong>{invite.userAsProfessor.firstName} {invite.userAsProfessor.lastName}</strong> {t('HOME_PROFESSOR_CLASS')}
                    <strong className="orange ml-1">{invite.className}</strong>
                    </div>
                    }
                    </div>
                    }
                    {(invite.type == 'student') &&
                    <div>
                        <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong> {t('HOME_INVITED_TO_CLASS')}
                        <strong className="orange ml-1">{invite.className}</strong>
                    </div>
                    }

                    {(invite.type == 'approveAsProfessor') &&
                    <div>
                        {(invite.userAsProfessor && user.id == invite.userAsProfessor.id) &&
                        <div>
                            <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong> {t('HOME_INVITED_TO_CLASS')}
                            <strong className="orange ml-1">{invite.className}</strong>
                        </div>
                        }
                        {(user.id != invite.userAsProfessor.id) &&
                        <div>
                            <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong>
                            {t('HOME_INVITED')} <strong>{invite.userAsProfessor.firstName} {invite.userAsProfessor.lastName}</strong> {t('HOME_PROFESSOR_CLASS')}
                            <strong className="orange ml-1">{invite.className}</strong>
                        </div>
                        }
                    </div>
                    }
                    {
                    (invite.type == 'requestToBecomeClassProfessor') &&
                    <div>
                        <strong>{invite.fromUser.firstName} {invite.fromUser.lastName}</strong> {t('HOME_WANT_PROFESSOR_CLASS')}
                        <strong className="orange ml-1">{invite.className}</strong>
                    </div>
                    }
                </div>
                <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={3}><FontAwesomeIcon className="clockIcon" icon={faClock} />{invite.timeAgo}</Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Button onClick={()=>executeAcceptOnInvite(invite.id, invite.type)} variant="contained" className="greenButton w80 mr-1">{t('BTN_ACCEPT')}</Button>
                <Button onClick={()=>executeRejectOnInvite(invite.id, invite.type)} variant="contained" className="yellowButton w80">{t('BTN_REJECT')}</Button>
                </Grid>
                </div>
              </MenuItem>
              ))
            }

            {(dataCount.noteSharesCount>0) &&
              data.noteShares.map((noteShare, i) => (
              <MenuItem className="nowhitespace" key={i} divider>
                <ListItemAvatar>
                  <UserAvatar profile={noteShare.fromUser} />
                </ListItemAvatar>
                <div>
                <div>
                    <strong>{noteShare.fromUser.firstName} {noteShare.fromUser.lastName}</strong> {t('HOME_SHARE_NOTE')} <strong>{noteShare.noteName}</strong> {t('HOME_IN_CLASS')}
                    <strong className="orange ml-1">{noteShare.className}</strong>
                </div>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={3}><FontAwesomeIcon className="clockIcon" icon={faClock} />{noteShare.timeAgo}</Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Button onClick={()=>executeActionOnNoteShare(noteShare.id, 'accept')} variant="contained" className="greenButton w80 mr-1 mb-1" >{t('BTN_ACCEPT')}</Button>
                <Button onClick={()=>executeActionOnNoteShare(noteShare.id, 'reject')} variant="contained" className="yellowButton w80 mr-1 mb-1">{t('BTN_REJECT')}</Button>
                {noteShare.note.type == 'VIDEO' &&
                <Button onClick={()=>{showVideoPreview(noteShare)}} variant="contained" className="blueButton w80 mr-1 mb-1">{t('BTN_PREVIEW')}</Button>
                }
               
                {noteShare.note.type == 'DOCUMENT' &&
                <>
                <Button onClick={()=>windowOpen(noteShare.note.id)} variant="contained" className="blueButton w80 mr-1 mb-1">{t('BTN_PREVIEW')}</Button>
                </>  
                }
                </Grid>
                </div>
              </MenuItem>
              ))
            }
            {(dataCount.assignmentsSharesCount>0) &&
              data.assignmentsShares.map((assignmentShare, i) => (
              <MenuItem className="nowhitespace" key={i} divider>
                <ListItemAvatar>
                  <UserAvatar profile={assignmentShare.fromUser} />
                </ListItemAvatar>
                <div>
                <div>
                    <strong>{assignmentShare.fromUser.firstName} {assignmentShare.fromUser.lastName}</strong> {t('HOME_SHARE_ASSIGNMENT')} <strong>{assignmentShare.assignmentName}</strong> {t('HOME_IN_CLASS')}
                    <strong className="orange ml-1">{assignmentShare.className}</strong>
                </div>
                <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={3}><FontAwesomeIcon className="clockIcon" icon={faClock} />{assignmentShare.timeAgo}</Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Button onClick={()=>executeActionOnAssignmentShare(assignmentShare.id,'accept')} variant="contained" className="greenButton w80 mr-1">{t('BTN_ACCEPT')}</Button>
                <Button onClick={()=>executeActionOnAssignmentShare(assignmentShare.id,'reject')} variant="contained" className="yellowButton w80">{t('BTN_REJECT')}</Button>
                        {/* <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}><Button onClick={()=>executeActionOnAssignmentShare(assignmentShare.id, assignmentShare.type)} variant="contained" className="greenButton w80">{t('BTN_ACCEPT')}</Button></Grid>
                            <Grid item xs={12} sm={3}><Button onClick={()=>executeActionOnAssignmentShare(assignmentShare.id, assignmentShare.type)} variant="contained" className="yellowButton w80">{t('BTN_REJECT')}</Button></Grid>
                        </Grid> */}
                </Grid>
                </div>
              </MenuItem>
              ))
            }

            {(dataCount.feedbacksCount>0) &&
              data.feedbacks.map((feedback, i) => (
              <MenuItem className="nowhitespace" key={i} divider>
                <ListItemAvatar>
                  <UserAvatar profile={feedback.from} />
                </ListItemAvatar>
                <div>
                <div>
                <strong>{feedback.from.firstName} {feedback.from.lastName}</strong> {t('HOME_CHANGE_FEEDBACK')} {feedback.entity}
                <strong className="orange ml-1">{feedback.prevState.name}</strong>
                
                <div>
                    {(feedback.newState.name != feedback.prevState.name) && 
                        <div>
                            <em>{t('SEMESTER_NAME')} </em>
                            <code>{feedback.newState.name}</code>
                        </div>
                    }
                  {(feedback.newState.startDate != feedback.prevState.startDate) && 
                    <div>
                        <em>{t('DATE_START')}:</em>
                        <code>  <span className="dateStyleOne">{moment(feedback.newState.endDate).format(t('DATE_POSTED_SHORT_MONTH'))}</span></code>
                        {/* <code>{feedback.newState.startDate} | {Date()}</code> */}
                    </div>
                    }
                    {(feedback.newState.endDate != feedback.prevState.endDate) &&
                        <div> 
                            <em>{t('DATE_END')}:</em>
                            <code> <span className="dateStyleOne">{moment(feedback.newState.endDate).format(t('DATE_POSTED_SHORT_MONTH'))}</span></code>
                            {/* <code>{feedback.newState.endDate} | {Date()}ok</code> */}
                        </div>
                    } 
                </div>
                <div>
                    {(feedback.newState.name != feedback.prevState.name) &&
                    <div>
                        <em>{t('CLASS_NAME')}</em>
                        <code>{feedback.newState.name}</code>
                    </div>
                    }

                    {(feedback.newState.location != feedback.prevState.location) && 
                    <div>
                        <em>{t('CLASS_LOCATION')}: </em>
                        <code>{feedback.newState.location}</code>
                    </div>
                    }
                        {(feedback.newState.timings) &&   
                    <table className="table ">
                        <thead >
                            <tr className="trStyleOne trfont" >
                                <th  className="tableth" >{t('CLASS_DAY_WEEK')}</th>
                                <th className="tableth" >{t('TIME_START')}</th>
                                <th className="tableth" >{t('TIME_END')}</th>
                                {/* <th className="tableth align-middle"  >{t('STATUS')}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {feedback.newState.timings.map((timing,i) => {
                            return (
                            <tr  className="trfont" >
                                <td className="tabletd" >{timing.dayOfWeek}</td>
                                <td className="tabletd" >{timing.startTime}</td>
                                <td className="tabletd" >{timing.endTime}</td>
                                {/* <td className="tabletd" > 
                                    {(timing.status == 'still') && <span className="badge badge-info">{t('NO_CHANGE')}</span>}
                                    {(!timing.status) && <span className="badge badge-success">{t('ADDED')} </span>}
                                    {(timing.status == 'deleted') && <span className="badge badge-danger">{t('DELETED')}</span>}
                                </td> */}
                            </tr>
                            )})}
                        </tbody>
                    </table>
                     }
                </div>
                </div>
                    <Grid item xs={12} sm={3}><FontAwesomeIcon className="clockIcon" icon={faClock} />{feedback.timeAgo}</Grid>
                <Grid item xs={12} sm={9} spacing={1}>
                <Button onClick={()=>executeActionOnChangeFeedback(feedback.id, 'accept')} variant="contained" className="greenButton w80 mr-1">{t('BTN_ACCEPT')}</Button>
                <Button onClick={()=>executeActionOnChangeFeedback(feedback.id, 'reject')} variant="contained" className="yellowButton w80" >{t('BTN_REJECT')}</Button>
                 </Grid>
                </div>
              </MenuItem>
              ))
            }

            {(dataCount && (dataCount.invitesCount==0 && dataCount.noteSharesCount==0 && dataCount.assignmentsSharesCount==0 && dataCount.feedbacksCount==0)) &&
                <MenuItem className="nowhitespace" onClick={handleNotificationsMenuClose}>{t('MSG_NO_NOTIFICATIONS')}</MenuItem>
            }
            <VideoPreview videoPreviewOpen={videoPreviewOpen} setVideoPreviewOpen={setVideoPreviewOpen} noteVideo={noteVideo} handleVideoPreviewClose={handleVideoPreviewClose} />
          </Menu>
          <Dialog
           fullScreen 
                    className="forWidth"
                    contentStyle={{
                      width: "80%",
                      maxWidth: "none",
                    }}
                    onClose={windowClose}
                    open={iframeOpen}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                  >
                   
                     <DialogActions className="draggable">
                    <Tooltip title={t('CLOSE_NOTE')}  arrow>
                    <IconButton className={"MyCustomButton"} onClick={()=>windowClose()}>
                      <CloseIcon />
                    </IconButton>
                    </Tooltip>
                  </DialogActions>
                
                    <DialogContent className="DialogIframe">
                      <Iframe
                        url={
                          "https://rc.jot.academy/flowpaper/#/view-note/" +
                          noteId +
                          "/" +
                          user.token
                        }
                        width="100%"
                        height="520px"
                        id="myId"
                        className="iFrameDesign"
                        display="initial"
                        position="relative"
                      />
                    </DialogContent>
                  </Dialog>
          </>
  );
}

export default DashboardNotifications;


{/* <button uib-popover-template="dynamicNotificationPopover.templateUrl"
popover-placement="right" data-toggle="popover" popover-trigger="outsideClick"
class="btn btn-bell" type="button">
<i class="fa fa-bell"></i>
<span ng-if="invites.length > 0 || noteShares.length > 0 || assignmentsShares.length > 0 || feedbacks.length > 0" class="badge hidden-sm-down">
    {{feedbacks.length + noteShares.length + invites.length + assignmentsShares.length}}
</span>
<span ng-if="invites.length > 0 || assignmentsShares.length > 0 || feedbacks.length > 0"class="badge hidden-md-up">
    {{feedbacks.length + invites.length + assignmentsShares.length}}
</span>
</button> */}

{/* <script type="text/ng-template" id="dynamicNotificationPopover.html">
    <div class="form-group m-b-0" ng-controller="HomeController">
        <div ng-hide="homeItems" class="cloak-loading"><img src="img/loading-cloak.gif"></div>
        <label ng-if="invites.length == 0 && noteShares.length == 0 && assignmentsShares.length == 0 && feedbacks.length == 0">{{translation.MSG_NO_NOTIFICATIONS}}</label>
        <div ng-cloak class="media" ng-repeat="invite in invites">
            <div class="media-left media-middle hidden-xs-down">
                <div class='img-jot thumb' ng-class="{'prof': invite.fromUser.professor}">
                    <div class="show-profile" ng-click="showUserProfile(invite.fromUser.id)">
                        <figure ng-style="invite.fromUser.profileImageThumbnail && {'background-image' : 'url(' + invite.fromUser.profileImageThumbnail + ')'}"></figure>
                    </div>
                </div>
            </div>
            <div class="media-body">
                <div ng-if="invite.type == 'professor'">
                    <div ng-if="userProfile.id == invite.userAsProfessor.id">
                        <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong> {{translation.HOME_INVITED_TO_CLASS}}
                        <strong className="orange ml-1">{{invite.className}}</strong>
                    </div>
                    <div ng-if="userProfile.id != invite.userAsProfessor.id">
                        <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong>
                        {{translation.HOME_INVITED}} <strong>{{invite.userAsProfessor.firstName}} {{invite.userAsProfessor.lastName}}</strong> {{translation.HOME_PROFESSOR_CLASS}}
                        <strong className="orange ml-1">{{invite.className}}</strong>
                    </div>
                </div>
                <div ng-if="invite.type == 'student'">
                    <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong> {{translation.HOME_INVITED_TO_CLASS}}
                    <strong className="orange ml-1">{{invite.className}}</strong>
                </div>
                <div ng-if="invite.type == 'approveAsProfessor'">
                    <div ng-if="userProfile.id == invite.userAsProfessor.id">
                        <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong> {{translation.HOME_INVITED_TO_CLASS}}
                        <strong className="orange ml-1">{{invite.className}}</strong>
                    </div>
                    <div ng-if="userProfile.id != invite.userAsProfessor.id">
                        <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong>
                        {{translation.HOME_INVITED}} <strong>{{invite.userAsProfessor.firstName}} {{invite.userAsProfessor.lastName}}</strong> {{translation.HOME_PROFESSOR_CLASS}}
                        <strong className="orange ml-1">{{invite.className}}</strong>
                    </div>
                </div>
                <div ng-if="invite.type == 'requestToBecomeClassProfessor'">
                    <strong>{{invite.fromUser.firstName}} {{invite.fromUser.lastName}}</strong> {{translation.HOME_WANT_PROFESSOR_CLASS}}
                    <strong className="orange ml-1">{{invite.className}}</strong>
                </div>

                <div style="width: 100%; color: rgba(24, 23, 21, 0.44); padding: 0 10px 0 5px;">{{invite.timeAgo}} <i class="fa fa-envelope"></i>

                <button ng-click="executeAcceptOnInvite(invite.id, invite.type)" type="button" class="btn btn-success btn-sm">{{translation.BTN_ACCEPT}}</button>
                <button ng-click="executeRejectOnInvite(invite.id, invite.type)" type="button" class="btn btn-secondary btn-sm active">{{translation.BTN_REJECT}}</button>
                </div>
            </div>
        </div>

        <div class="media hidden-sm-down" ng-repeat="noteShare in noteShares">
            <div class="media-left media-middle hidden-xs-down">
                <div class='img-jot thumb' ng-class="{'prof': noteShare.fromUser.professor}">
                    <div class="show-profile" ng-click="showUserProfile(noteShare.fromUser.id)">
                        <figure ng-style="noteShare.fromUser.profileImageThumbnail && {'background-image' : 'url(' + noteShare.fromUser.profileImageThumbnail + ')'}"></figure>
                    </div>
                </div>
            </div>
            <div class="media-body">
                <strong>{{noteShare.fromUser.firstName}} {{noteShare.fromUser.lastName}}</strong> {{translation.HOME_SHARE_NOTE}} <strong>{{noteShare.noteName}}</strong> {{translation.HOME_IN_CLASS}}
                <strong className="orange ml-1">{{noteShare.className}}</strong>

                <div style="width: 100%; color: rgba(24, 23, 21, 0.44); padding: 0 10px 0 5px;">{{noteShare.timeAgo}} <i class="fa fa-envelope"></i>
                    <button type="button" ng-click="executeActionOnNoteShare(noteShare.id, 'accept')" class="btn btn-success btn-sm">{{translation.BTN_ACCEPT}}</button>
                    <button type="button" ng-click="executeActionOnNoteShare(noteShare.id, 'reject')" class="btn btn-secondary btn-sm active">{{translation.BTN_REJECT}}</button>
					<button ng-if="noteShare.note.type == 'VIDEO'" type="button" ng-click="showVideoPreview(noteShare.note)" class="btn btn-info btn-sm">{{translation.BTN_PREVIEW}}</button>
                    <button ng-if="noteShare.note.type == 'DOCUMENT'" type="button" ng-click="goToNote(noteShare.note.id)" class="btn btn-info btn-sm">{{translation.BTN_PREVIEW}}</button>
                </div>
             </div>
        </div>

        <div class="media" ng-repeat="assignmentShare in assignmentsShares">
            <div class="media-left media-middle hidden-xs-down">
                <div class='img-jot thumb' ng-class="{'prof': assignmentShare.fromUser.professor}">
                    <div class="show-profile" ng-click="showUserProfile(assignmentShare.fromUser.id)">
                        <figure ng-style="assignmentShare.fromUser.profileImageThumbnail && {'background-image' : 'url(' + assignmentShare.fromUser.profileImageThumbnail + ')'}"></figure>
                    </div>
                </div>
            </div>
            <div class="media-body">
                <strong>{{assignmentShare.fromUser.firstName}} {{assignmentShare.fromUser.lastName}}</strong> {{translation.HOME_SHARE_ASSIGNMENT}} <strong>{{assignmentShare.assignmentName}}</strong> {{translation.HOME_IN_CLASS}}
                <strong className="orange ml-1">{{assignmentShare.className}}</strong>

                <div style="width: 100%; color: rgba(24, 23, 21, 0.44); padding: 0 10px 0 5px;">{{assignmentShare.timeAgo}} <i class="fa fa-envelope"></i>

                    <button ng-click="executeActionOnAssignmentShare(assignmentShare.id, 'accept')" type="button" class="btn btn-success btn-sm">{{translation.BTN_ACCEPT}}</button>
                    <button ng-click="executeActionOnAssignmentShare(assignmentShare.id, 'reject')" type="button" class="btn btn-secondary btn-sm active">{{translation.BTN_REJECT}}</button>
                </div>
            </div>
        </div>

        <div class="media" ng-repeat="feedback in feedbacks">
            <div class="media-left media-middle hidden-xs-down">
                <div class='img-jot thumb' ng-class="{'prof': feedback.from.professor}">
                    <div class="show-profile" ng-click="showUserProfile(feedback.from.id)">
                        <figure ng-style="feedback.from.profileImageThumbnail && {'background-image' : 'url(' + feedback.from.profileImageThumbnail + ')'}"></figure>
                    </div>
                </div>
            </div>
            <div class="media-body">
                <strong>{{feedback.from.firstName}} {{feedback.from.lastName}}</strong> {{translation.HOME_CHANGE_FEEDBACK}} {{feedback.entity}}
                <strong className="orange ml-1">{{feedback.prevState.name}}</strong>
                
                <div ng-show="feedback.entity == 'Term'" class="p-l-1">
                    <div ng-if="feedback.newState.name != feedback.prevState.name" style="margin-bottom: 3px;"><em>{{translation.SEMESTER_NAME}}: </em><code>{{feedback.newState.name}}</code></div>
                    <div ng-if="feedback.newState.startDate != feedback.prevState.startDate" style="margin-bottom: 3px;"><em>{{translation.DATE_START}}: </em><code>{{feedback.newState.startDate | date:'mediumDate'}}</code></div>
                    <div ng-if="feedback.newState.endDate != feedback.prevState.endDate" style="margin-bottom: 3px;"><em>{{translation.DATE_END}}: </em><code>{{feedback.newState.endDate | date:'mediumDate'}}</code></div>
                </div>
                <div ng-show="feedback.entity == 'Class'" class="p-l-1">
                    <div ng-if="feedback.newState.name != feedback.prevState.name" style="margin-bottom: 3px;"><em>{{translation.CLASS_NAME}}: </em><code>{{feedback.newState.name}}</code></div>
                    <div ng-if="feedback.newState.location != feedback.prevState.location" style="margin-bottom: 3px;"><em>{{translation.CLASS_LOCATION}}: </em><code>{{feedback.newState.location}}</code></div>
                    <table ng-if="feedback.scheduleChange > 0" class="table" style="margin-bottom: 3px; white-space: nowrap; width: auto;">
                        <thead class="thead-default">
                            <tr>
                                <th class="p-y-0">Day of week</th>
                                <th class="p-y-0">Start time</th>
                                <th class="p-y-0">End time</th>
                                <th class="p-y-0">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-repeat="timing in feedback.schedule">
                                <td class="p-y-0">{{timing.dayOfWeek}}</td>
                                <td class="p-y-0">{{timing.startTime}}</td>
                                <td class="p-y-0">{{timing.endTime}}</td>
                                <td class="p-y-0">
                                    <span ng-if="timing.status == 'still'" class="label label-info">no change</span>
                                    <span ng-if="!timing.status" class="label label-success">addedd</span>
                                    <span ng-if="timing.status == 'deleted'" class="label label-danger">deleted</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="width: 100%; color: rgba(24, 23, 21, 0.44); padding: 0 10px 0 5px;">{{feedback.timeAgo}} <i class="fa fa-envelope"></i>

                    <button ng-click="executeActionOnChangeFeedback(feedback.id, 'accept')" type="button" class="btn btn-success btn-sm">{{translation.BTN_ACCEPT}}</button>
                    <button ng-click="executeActionOnChangeFeedback(feedback.id, 'reject')" type="button" class="btn btn-secondary btn-sm active">{{translation.BTN_REJECT}}</button>
                </div>
            </div>
        </div>

    </div>
</script> */}



// $scope.findInvitesTypesToClasses();
// $scope.findNotesSharesByUser();
// $scope.findAssignmentsSharesByUser();
// $scope.findFeedbacksByUser();

// $scope.findInvitesTypesToClasses = function () {
//   fixHeight();
//   inviteService.findInvitesToClasses(userLogedIn).then(
//       function (response) {
//           $rootScope.invites = response.data;
//       },
//       function (response) {
//       }
//   );
// };

// $scope.findNotesSharesByUser = function () {
//   noteService.findNotesSharesByUser(userLogedIn).then(
//       function (response) {
//           $rootScope.noteShares = response.data;
//       },
//       function (response) {
//       }
//   );
// };

// $scope.findAssignmentsSharesByUser = function () {
//   assignmentService.findAssignmentsShares(userLogedIn).then(
//       function (response) {
//           $rootScope.assignmentsShares = response.data;
//       },
//       function (response) {
//       }
//   );
// };

// $scope.findFeedbacksByUser = function () {
//   homeService.findFeedbacks(userLogedIn).then(
//       function (response) {
//           $rootScope.feedbacks = response.data;
//           angular.forEach($rootScope.feedbacks, function (feedback) {
//               if (angular.equals(feedback.entity, 'Class')) {
//                   feedback.schedule = feedback.prevState.timings;
//                   for (var i = 0; i < feedback.schedule.length; i++) {
//                       feedback.schedule[i].status = "deleted";
//                       for (var j = 0; j < feedback.newState.timings.length; j++) {
//                           if (feedback.schedule[i].dayOfWeek == feedback.newState.timings[j].dayOfWeek && feedback.schedule[i].startTime == feedback.newState.timings[j].startTime && feedback.schedule[i].endTime == feedback.newState.timings[j].endTime) {
//                               feedback.newState.timings.splice(j, 1);
//                               feedback.schedule[i].status = "still";
//                               break;
//                           }
//                       }
//                   }
//                   feedback.schedule = feedback.schedule.concat(feedback.newState.timings);

//                   var checkContains = $.grep(feedback.schedule, function (e) {
//                       return e.status != "still";
//                   });
//                   feedback.scheduleChange = checkContains.length;
//               }
//           });

//       },
//       function (response) {
//       }
//   );
// };


// Services

// function findInvitesToClasses(userLogedIn) {
//   return $http.get(jotAppConfig.apiUrl + "invite?token=" + userLogedIn.get().token);
// }

// function findNotesSharesByUser(userLogedIn) {
//   return $http.get(jotAppConfig.apiUrl + 'note/share', {headers: {"jot-token": userLogedIn.get().token}});
// }

// function findAssignmentsShares(userLogedIn) {
//   return $http.get(jotAppConfig.apiUrl + 'assignment/share', {headers: {"jot-token": userLogedIn.get().token}});
// }

// function findFeedbacks(userLogedIn) {
//   return $http.get(jotAppConfig.apiUrl + 'home/feedback', {headers: {"jot-token": userLogedIn.get().token}});
// }