import React, {useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios'; 

const AddStudent = ({ onClose, open, data, reloadAssignments }) => {
    const classes = useStyles();
    const [{user}] = useStateValue();
    const { t } = useTranslation();
    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const headers = axiosConfig(user.token);
    const [name,setName] = useState('');
    const [dob,setDob] = useState(0);
    const [address,setAddress] = useState('');
    const [age,setAge] = useState('');
    const [sex,setSex] = useState('');
    const [phoneNumber,setPhoneNumber] = useState(0);
    const [parentsName,setParentsName] = useState('');
    const [parentsPhone,setParentsPhone] = useState('');
    const [parentsEmail,setParentsEmail] = useState('');
    const [assignmentName, setAssignmentName] = React.useState('');
    const [dueDate, setDueDate] = React.useState(new Date());
    const [description, setDescription] = React.useState('');
    const [classId, setClassId] = React.useState('');
   

    const handleDueDateChange = (date) => {
        setDob(date);
      };
    
      const handleClassChange = (event) => {
        setClassId(event.target.value);
      };

      
  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      let assignmentData={
        name: assignmentName,
        classId: classId,
        dueDate: dueDate.setHours(12, 0, 0, 0),
        description: description
      }
      axios.post(API_URL+'assignment', assignmentData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setAssignmentName('');
        setDueDate(new Date());
        setClassId('');
        setDescription('');
        reloadAssignments();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_SUCCESS')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }

  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(assignmentName==''){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_EMPTY_TITLE');
    }

    if(assignmentName!=''&&assignmentName.length<3){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_SHORT_TITLE');
    }

    if(assignmentName!=''&&assignmentName.length>50){
      formIsValid = false;
      errorsData["assignmentName"] = t('MSG_LONG_TITLE');
    }

    if(classId==''){
      formIsValid = false;
      errorsData["classId"] = t('MSG_EMPTY_CLASS');
    }

    if(dueDate=='' || dueDate==null){
        formIsValid = false;
        errorsData["dueDate"] = t('MSG_INVALID_DATE');
      }

    if(description!=''&&description==''){
        formIsValid = false;
        errorsData["description"] = t('MSG_EMPTY_DESCRIPTION');
      }
  
      if(description!=''&&description.length<10){
        formIsValid = false;
        errorsData["description"] = t('MSG_SHORT_DESCRIPTION');
      }
  
      if(description!=''&&description.length>3000){
        formIsValid = false;
        errorsData["description"] = t('MSG_LONG_DESCRIPTION');
      }

    setErrors(errorsData);
    return formIsValid;
  }

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }
    return (
        <Dialog fullWidth onClose={onClose} aria-labelledby="add-assignment" open={open}>
        <DialogTitle>{t('STUDENT_ADD')}</DialogTitle>
        <DialogContent className="modalContent">
          <div>
            {serverError!=""?
            <Alert severity="error" className="alertBox">
              {serverError}
            </Alert>
            :null}
          </div>
          <div>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  label="Name"
                  required
                  fullWidth
                  id="name"
                  placeholder={t('STUDENT_NAME')}
                  name="name"
                  autoComplete="name"
                  error={errors["name"]}
                  helperText={errors["name"]}
                  autoFocus
                  className="mb-4"
                />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  minDate={new Date()}
                  shouldDisableDate={disableWeekends}
                  fullWidth
                  inputVariant="outlined"
                  label="Date of Birth"
                  margin="normal"
                  error={errors["dob"]}
                  helperText={errors["dob"]}
                  id="dob"
                  name="dob"
                  placeholder={t('STUDENT_DOB')}
                  format="MMMM dd, yyyy"
                  value={dob}
                  onChange={handleDueDateChange}
              />
              </MuiPickersUtilsProvider>
                 <TextField
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                  variant="outlined"
                  label="Address"
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  placeholder={t('STUDENT_ADDRESS')}
                  name="address"
                  autoComplete="address"
                  error={errors["address"]}
                  helperText={errors["address"]}
                  autoFocus
                  className="mb-4"
                />
                 <TextField
                  value={age}
                  onChange={(e)=>setAge(e.target.value)}
                  variant="outlined"
                  label="Age"
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="age"
                  placeholder={t('STUDENT_AGE')}
                  name="age"
                  autoComplete="age"
                  error={errors["age"]}
                  helperText={errors["age"]}
                  autoFocus
                  className="mb-4"
                />
                 <TextField
                  value={sex}
                  onChange={(e)=>setSex(e.target.value)}
                  variant="outlined"
                  label="Sex"
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="sex"
                  placeholder={t('STUDENT_SEX')}
                  name="sex"
                  autoComplete="sex"
                  error={errors["sex"]}
                  helperText={errors["sex"]}
                  autoFocus
                  className="mb-4"
                />
                 <TextField
                  value={phoneNumber}
                  onChange={(e)=>setPhoneNumber(e.target.value)}
                  variant="outlined"
                  label="Phone Number"
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="phoneNumber"
                  placeholder={t('STUDENT_PHONE_NUMBER')}
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  error={errors["phoneNumber"]}
                  helperText={errors["phoneNumber"]}
                  autoFocus
                  className="mb-4"
                />
                 <TextField
                  value={parentsName}
                  onChange={(e)=>setParentsName(e.target.value)}
                  variant="outlined"
                  label="Parent's Name"
                 
                  margin="normal"
                  required
                  fullWidth
                  id="parentsName"
                  placeholder={t('STUDENT_PARENTS_NAME')}
                  name="parentsName"
                  autoComplete="parentsName"
                  error={errors["parentsName"]}
                  helperText={errors["parentsName"]}
                  autoFocus
                  className="mb-4"
                />
          
                 <TextField
                  value={parentsPhone}
                  onChange={(e)=>setParentsPhone(e.target.value)}
                  variant="outlined"
                  label="Parent's Phone"
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="parentsPhone"
                  placeholder={t('STUDENT_PARENTS_PHONE')}
                  name="parentsPhone"
                  autoComplete="parentsPhone"
                  error={errors["parentsPhone"]}
                  helperText={errors["parentsPhone"]}
                  autoFocus
                  className="mb-4"
                />
                 <TextField
                  value={parentsEmail}
                  onChange={(e)=>setParentsEmail(e.target.value)}
                  variant="outlined"
                  label="Parent's Email"
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="parentsEmail"
                  placeholder={t('STUDENT_PARENTS_EMAIL')}
                  name="parentsEmail"
                  autoComplete="parentsEmail"
                  error={errors["parentsEmail"]}
                  helperText={errors["parentsEmail"]}
                  autoFocus
                  className="mb-4"
                />  
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="mt-3"
              >
                {t('STUDENT_ADD')}
              </Button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    )
}

export default AddStudent
