import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';
import { Grid, ListItemAvatar, IconButton, Dialog, DialogContent, DialogTitle, MenuItem, Tooltip } from '@material-ui/core';
import UserAvatar from '../User/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {API_URL, axiosConfig} from '../../api/index';
import {
  Link,
} from "react-router-dom";
import AddMembersToGroup from './AddMembersToGroup';

const ChatHeader = ({ selectedChat, setSelectedChat, setReloadFlag,responsive=false,showSideBar=false, setShowSideBar=false }) => {
    const classes = useStyles();
    const [{ user }] = useStateValue();
    const { t } = useTranslation();
    const [resArrow, setResArrow] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [addMembersToGroup, setAddMembersToGroupOpen] = useState(false);
    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const headers = axiosConfig(user.token);
    const[memberModalOpen,setMemberModalOpen] = useState(false)

    useEffect(() => {
        width < 426 && handleMobileView();
        width > 426 && handleDesktopView();
    }, [width]);

    const handleMobileView = () => {
        setResArrow(true);
    }
    const handleDesktopView = () => {
        setResArrow(false);
    }

    const handleAddMembersToGroupOpen = () => {
        setAddMembersToGroupOpen(true);
    };

    const handleAddMembersToGroupClose = () => {
        setAddMembersToGroupOpen(false);
    };

    const handleMemberModalOpen = () => {
        setMemberModalOpen(true);
      };
    
      const handleMemberModalClose = () => {
        setMemberModalOpen(false);
      };

    const handleDeleteGroup = () => {
        setShowSideBar(true);

        dispatchSetLoading({
            type: 'SET_LOADING',
            loading: true
        });

        axios.delete(API_URL + 'chat/group/' + selectedChat.id, headers)
            .then((response) => {
                setSelectedChat({});
                setReloadFlag();
                dispatchSnack({
                    type: 'SET_SNACK',
                    snack: {
                        flag: true,
                        message: t('MSG_SUCCESS')
                    }
                });

                dispatchSetLoading({
                    type: 'SET_LOADING',
                    loading: false
                });

            })
            .catch((errors) => {
                dispatchSnack({
                    type: 'SET_SNACK',
                    snack: {
                        flag: true,
                        message: t('MSG_LOAD_DATA_ERROR')
                    }
                });

                dispatchSetLoading({
                    type: 'SET_LOADING',
                    loading: false
                });

            })
        //setShowData(false);
    }
   

    return (
        <div>
            <div className="contact-profile">
               
                <div className="img" >
                    <ListItemAvatar>
                        <UserAvatar profile={selectedChat} noChatButton={true} chat status={selectedChat.chatActiveStatus} />

                    </ListItemAvatar>
                </div>
                {/* logic on small screens cut the text and on bigger show full text start */}
                {resArrow ?
                    <><p >{selectedChat.name ? (`${selectedChat.name.substring(0, 19)}...`) : ''}</p></>
                    :
                    <><p>{selectedChat.name}</p></>
                }
                {/* logic on small screens cut the text and on bigger show full text end */}
                <div className="social-media" >
                    {/* add member to the group and delete group buton start */}
                    {(selectedChat.group && selectedChat.creator == user.id) && <>
                        <Tooltip title={t('ADD_MEMBER_GROUP')} arrow>
                            <IconButton onClick={handleAddMembersToGroupOpen} className="socialMediaButtons" size="small" color="primary" ><FontAwesomeIcon icon={faUserPlus} /></IconButton>
                        </Tooltip>
                        <Tooltip title={t('DELETE_GROUP')} arrow>
                            <IconButton className="socialMediaButtons" size="small" color="primary" onClick={handleDeleteGroup} ><FontAwesomeIcon icon={faTimes} /></IconButton>
                        </Tooltip>
                    </>}
                    {/* add member to the group and delete group buton end */}
                </div>

                {/* member modal button  start */}
                <div className="member-modal" >
                    {(selectedChat.group) && <>
                        <Link onClick={handleMemberModalOpen}><div className="italic chatMemberName">{t('CHATS_MEMBERS')}: {selectedChat.users.length}</div></Link></>}
                </div>
                {/* member modal button  end */}
            </div>
            <Dialog fullWidth onClose={handleAddMembersToGroupClose} aria-labelledby="add-class" open={addMembersToGroup}>
                <DialogTitle>{t('CHATS_ADD_USER')} {selectedChat.name}</DialogTitle>
                <DialogContent className="modalContent">
                    <AddMembersToGroup onClose={handleAddMembersToGroupClose} setReloadFlag={setReloadFlag} selectedChat={selectedChat} setSelectedChat={setSelectedChat} setShowSideBar={setShowSideBar}/>
                </DialogContent>
            </Dialog>
            {/* modal logic for group members start */}
            <Dialog fullWidth  aria-labelledby="chat-members" onClose={handleMemberModalClose} open={memberModalOpen}>
                <DialogTitle>{t('CHATS_MEMBERS')}</DialogTitle>
                {(selectedChat.users && selectedChat.users.length > 0) && selectedChat.users.map((user, i)=>(
                    <MenuItem divider>
                    <ListItemAvatar>
                    <UserAvatar profile={user}  memberModalOpen={memberModalOpen} handleMemberModalClose={handleMemberModalClose} />
                    </ListItemAvatar>
                    <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={9} onClick={handleMemberModalOpen} ><strong>{user.firstName+" "+user.lastName}</strong></Grid>
                        <Grid item xs={12} sm={9} className="italic"> {(user.isOnline)?t('CHATS_ONLINE'):t('CHATS_OFFLINE')} </Grid>
                    </Grid>
                    </MenuItem>
                ))}
                </Dialog>
            {/* modal logic for group members end */}
        </div>
    );
}

export default ChatHeader;