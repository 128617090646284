import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f26222',
    },
    info:{
      main: '#3b5998',
    },
    secondary: {
      main: grey[800],
    },
    text: {
      primary: grey[800],
      secondary: '#f26222',
    },
    // grey:{
    //   main:'#424242',
    // },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: '35px'
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none'
          

        },
      },
    },
    MuiButton: {
      root: {
        '&:focus': {
          outline: 'none'
        },
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.122)",
        color: '#f26222',
        paddingBottom: 5,
        paddingLeft: 10
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 20,
        paddingBottom: 20
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 0
      }
    },
  }
});

export default theme;