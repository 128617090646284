import React,{useEffect,useState} from "react";
import { isWebUri } from 'valid-url';

 const UrlDetector = ({url}) =>{
    return (
        <>{(isWebUri(url)) ? <a href={url} target="_blank">{url}</a> : <span>{url}</span>}
        </>
       
    );

}
export default  UrlDetector;
