import React from 'react';
import {Typography, Link} from '@material-ui/core';

const Footer = () => {
    return (
        <Typography variant="body2" color="textPrimary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
            Jot
        </Link>{' '}
        {new Date().getFullYear()}
        </Typography>
    );
}

export default Footer;