import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import Administrator from "./Administrator";
import NewAdministrator from "./NewAdministrator"


const Administrators = () => {
    const classes = useStyles();
    const [{loading}] = useStateValue();
    const [{snack}] = useStateValue();
    const [{title}, dispatchSetTitle] = useStateValue();
    const { t } = useTranslation();

    useEffect(()=>{
        dispatchSetTitle({
          type: 'SET_TITLE',
          title: t('ADMINISTRATOR')
        });
      }, []);

    return (
        <Grid item xs={12}>
      <Switch>
        <Route exact path={'*/new'} component={NewAdministrator}/>
      <Route exact path={'*/'} component={Administrator}/>
      </Switch>
      <SnackbarAlert />
      {loading ?
      <Loader active={true} />
      :
      null}
    </Grid>
    )
}

export default Administrators
