import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,Tooltip
} from "@material-ui/core";
//
import Hidden from "@material-ui/core/Hidden";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import FavoriteIcon from "@material-ui/icons/Favorite";
//
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFolder,
  faPen,
  faTrash,faFileUpload ,faFolderPlus
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL, axiosConfigWithoutToken, axiosConfig } from "../../api/index";
import NoteBrowser from "./NoteBrowser";
import { useStyles } from "../../assets/styles/materialStyles";
import { useStateValue } from "../../store/StateProvider";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from 'lodash';
import Note from "./Note";

const ClassNotes = () => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{ snack }, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();
  const headersWithToken = axiosConfig(user.token);
  let { noteId } = useParams();
  

  useEffect(() => {
    fetchNotesData();
  }, []);

  const fetchNotesData = () => {
    dispatchSetLoading({
      type: "SET_LOADING",
      loading: true,
    });
    axios
      .get(API_URL + "class?token=" + user.token, headers)
      .then((response) => {
        // console.log('response.data');
        // console.log(response.data);
        setData({
          classes: response.data,
        });
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((errors) => {
        dispatchSnack({
          type: "SET_SNACK",
          snack: {
            flag: true,
            message: t("MSG_LOAD_DATA_ERROR"),
          },
        });
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      });
  };

 

  const handleSearch = () => {
    if (searchText != "") {
      setSearchFlag(true);
      dispatchSetLoading({
        type: "SET_LOADING",
        loading: true,
      });
      axios
        .get(API_URL + "note/search?query=" + searchText, headersWithToken)
        .then((response) => {
          setSearchResults(response.data);
          dispatchSetLoading({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((errors) => {
          dispatchSnack({
            type: "SET_SNACK",
            snack: {
              flag: true,
              message: t("MSG_LOAD_DATA_ERROR"),
            },
          });
          dispatchSetLoading({
            type: "SET_LOADING",
            loading: false,
          });
          //console.log(errors);
        });
    }
  };

  const isExpanded = (i, id) => {
   
    if (!noteId && i == 0) {
      return true;
      
    } else {
      if (noteId == id) {
        return true;
        
      }
    }
    return false;
    
  };
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={3}
        className={classes.root}
        justify="space-between"
      >
        <Grid item xs={12} lg={12} sm={12} className="searchBottomNotes">
          <TextField
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            onChange={(e) => setSearchText(e.target.value)}
            id="search"
            placeholder={t("NOTE_SEARCH")}
            name="search"
            placeholder={t("NOTE_SEARCH")}
            variant="outlined"
            fullWidth
            className="postInput mb-2 hsearch"
            size="small"
            InputProps={{
              endAdornment: (
                <Link onClick={handleSearch}>
                  <FontAwesomeIcon
                    className="textFieldSearchIcon"
                    icon={faSearch}
                  />
                </Link>
              ),
            }}
          />
        </Grid>
      </Grid>
      {searchFlag && !loading && searchText != "" && (
        <Accordion
          className="mb-3"
          className="searchResultDivider"
          defaultExpanded={true}
        >
          <div className="accordionHeader accordiansearch">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"searchResults-content"}
              id={"searchResults"}
              className="searchAccordianColor"
            >
              <div className="searchLink">
                <Link to="#">
                  <Typography variant="h6" className="searchAccordianTextColor">
                    {t("SEARCH_RESULTS")}
                  </Typography>
                </Link>
              </div>
            </AccordionSummary>
          </div>
          {searchResults.length > 0 ? (
            <Grid item xs={12}>
              <div className="contentContainer">
                <Grid container spacing={3}>
                  {searchResults.map((note, i) => (
                    <Note data={note} />
                  ))}
                </Grid>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div className="contentContainer">
                <div>{t("NO_SEARCH_RESULTS")}</div>
              </div>
            </Grid>
          )}
        </Accordion>
      )}
      {data &&
        data.classes &&
        data.classes.length > 0 &&
        data.classes.map(
          (classData, i) =>
              <>
                <Accordion
                  defaultExpanded={isExpanded(i, classData.id)}
                  className="mb-3"
                >
                  <div className="accordionHeader">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={classData.id + "-content"}
                      id={classData.id}
                    >
                      <Link to="#">
                        <Typography variant="h6">{classData.name}</Typography>
                      </Link>
                    </AccordionSummary>
                  </div>
                  <Grid item xs={12}>
                    <div className="contentContainer">
                      <Grid container spacing={3}>
                        <NoteBrowser notesData={classData} fetchNotesData={fetchNotesData} />
                      </Grid>
                    </div>
                  </Grid>
                </Accordion>
              </>
        )}
    </Paper>
  );
};

export default ClassNotes;
