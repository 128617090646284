import React, {useEffect, useState} from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles, Grid, Paper, Typography, TextField, Button, Accordion, AccordionSummary } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {API_URL, axiosConfig, axiosConfigWithoutToken} from '../../api/index';
import Class from './Class';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import AddClass from './AddClass';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ClassesHome = () => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const [{title}, dispatchSetTitle] = useStateValue();
  const { t } = useTranslation();
  const [data, setData]=useState();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();
  const headersWithToken = axiosConfig(user.token);
  const [addClassOpen, setAddClassOpen] = useState(false);
  const [searchText, setSearchText]=useState('');
  const [searchFlag, setSearchFlag]=useState(false);
  const [searchResults, setSearchResults]=useState({});

  useEffect(()=>{
    dispatchSetTitle({
      type: 'SET_TITLE',
      title: t('CLASSES')
    });
    fetchClassesData();
  }, []);
  const fetchClassesData = () => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
    axios.get(API_URL + 'class?token='+user.token, headers)
    .then((response)=>{
      setData({
        classes: response.data
      });
      // console.log(response.data);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
    })
    .catch((errors)=>{
      dispatchSnack({
        type: 'SET_SNACK',
        snack: {
          flag: true,
          message: t('MSG_LOAD_DATA_ERROR')
        }
      });
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: false
      });
      //console.log(errors);
    })
  }

  const handleAddClassOpen = () => {
   
    setAddClassOpen(true);
  };

  const handleAddClassClose = () => {
    setAddClassOpen(false);
  };

  const handleSearch = () => {
    if(searchText!=''){
      setSearchFlag(true);
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      axios.get(API_URL + 'class/search?query='+searchText, headersWithToken)
      .then((response)=>{
        setSearchResults(response.data);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
      .catch((errors)=>{
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        //console.log(errors);
      })
    }
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
      <Grid container spacing={3} className={classes.root}  justify="space-between">
      <Grid item xs={12} lg={8} sm={8} className="searchBottom "  >
         <TextField
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()
                  }
                }}
               
                onChange={(e)=>setSearchText(e.target.value)}
                id="search"
                placeholder={t('CLASS_SEARCH')}
                name="search"
                placeholder={t('CLASS_SEARCH')} 
                variant="outlined" 
                fullWidth
                className="postInput mb-2 hsearch" 
                size="small"
                InputProps={{endAdornment: <Link onClick={handleSearch}
                 >
                  <FontAwesomeIcon className="textFieldSearchIcon" icon={faSearch} /></Link>}}
                   />
          </Grid>
          <Grid item xs={12} lg={4} sm={4} className="mb-3">
            <Button onClick={handleAddClassOpen} variant="contained" className="yellowButton ">{t('CLASS_ADD')}</Button>

          <Dialog fullWidth onClose={handleAddClassClose} aria-labelledby="add-class" open={addClassOpen}>
            <DialogTitle>{t('CLASS_ADD')}</DialogTitle>
            <DialogContent className="modalContent">
            <AddClass onClose={handleAddClassClose} data={data} reloadClasses={fetchClassesData}/>
            </DialogContent>
          </Dialog>
          </Grid>
        </Grid>
        {(searchFlag && !loading && searchText!='') &&
          <Accordion className="mb-3" className="searchResultDivider" defaultExpanded={true} >
            <div className="accordionHeader accordiansearch" >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"searchResults-content"}
                id={"searchResults"} className="searchAccordianColor" 
              >
                <div className="searchLink">
                 <Link to="#"><Typography variant="h6" className="searchAccordianTextColor" >{t('SEARCH_RESULTS')}</Typography></Link>
                </div>
               
              </AccordionSummary>
            </div>
              {searchResults.length>0?
                <Grid item xs={12} >
                  <div className="contentContainer" >
                      <Grid container spacing={3}>
                        {searchResults.map((studentClass, i)=>(
                          <Class data={studentClass} searchResult />
                        ))}
                      </Grid>
                  </div>
                </Grid>
              :
                <Grid item xs={12} >
                  <div className="contentContainer" >
                    <div>{t('NO_SEARCH_RESULTS')}</div>
                  </div>
                </Grid>
              }
          </Accordion>
        }
        <Grid container spacing={3} className={classes.root}>
          {data && data.classes && data.classes.length>0 &&
            (data.classes.map((classData, i)=>(
              <Class data={classData} reloadClasses={fetchClassesData} />
            )))
          }
        </Grid>
      </Paper>
    </Grid>
  );
}

export default ClassesHome;