import React, {useState,useEffect} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  

const EditFolder = ({ onClose, open, data, reloadNotes=null, currentFolder }) => {
    const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfig(user.token);
  const [folderName, setFolderName] = React.useState('');
  const [classId, setClassId] = React.useState('');
  const [folderId, setFolderId] = React.useState('');


  useEffect(()=>{
    setFolderName(currentFolder.name);
    setFolderId(currentFolder.id);
  },[currentFolder]);


  const handleSubmit = (e) =>{
    e.preventDefault();
   
    
    if(validateForm()){
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: true
        });
        setErrors({});
        let folderData={
            id: folderId,
            newName: folderName,
          }
          axios.put(API_URL+'folder',folderData, headers)
        //   axios.post(API_URL+'folder/add', formData, headers)
          .then(response => {
           
            dispatchSetLoading({
              type: 'SET_LOADING',
              loading: false
            });
            setServerError('');
            setFolderName('');
            setFolderId('');
            reloadNotes(currentFolder.id);
            onClose();
            dispatchSnack({
              type: 'SET_SNACK',
              snack: {
                flag: true,
                message: t('MSG_SUCCESS')
              }
            });
          })
          .catch((error) => {
            dispatchSetLoading({
              type: 'SET_LOADING',
              loading: false
            });
            if(error.message.includes("507")){
              setServerError(t('NOTE_ADD_ERROR_DISK_QUOTA_1' + " " + t('NOTE_ADD_ERROR_DISK_QUOTA_2')));
            }else if(error.message.includes("415")){
              setServerError(t('NOTE_ADD_INCORRECT_FORMAT'));
            }else if(error.message.includes("503")){
              setServerError(t('NOTE_ADD_SERVICE_UNAVAILABLE_ERROR'));
            }else if(error.message.includes("500")){
              setServerError(t('NOTE_ADD_INTERNAL_SERVER_ERROR'));
            }else{
              setServerError(t('MSG_PROCESS_ERROR'));
            }
          });
        }

  }


  // const handleClassChange = (event) => {
  //   setClassId(event.target.value);
  // };

  // const handleFolderChange = (event) =>{
  //     setFolderId(event.target.value);
  // }

  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(folderName==''){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_EMPTY_NAME');
    }

    if(folderName!=''&&folderName.length<3){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_SHORT_NAME');
    }

    if(folderName!=''&&folderName.length>50){
      formIsValid = false;
      errorsData["folderName"] = t('MSG_LONG_NAME');
    }
   

    setErrors(errorsData);
    return formIsValid;
  }
    return (
        <Dialog fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('FOLDER_EDIT')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                value={folderName}
                onChange={(e)=>setFolderName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="folderName"
                placeholder={t('FOLDER_NAME')}
                name="folderName"
                autoComplete="folderName"
                error={errors["folderName"]}
                helperText={errors["folderName"]}
                autoFocus
                className="mb-4"
              />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('FOLDER_EDIT')}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
    )
}

export default EditFolder
