import React from 'react';
import {Grid, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import UserAvatar from '../User/UserAvatar';

const ClassStudents = ({ data }) => {
    const classes = useStyles();
    const [{user}] = useStateValue();
    const { t } = useTranslation();
    return (
        <div>
        {data && data.professor &&
            <div className="mb-3">
                <div className="flexJustifyCenter">
                    <UserAvatar profile={data.professor} size="large" displayName />
                </div>
            </div>
        }
        {(data && data.students && data.students.length>0) &&
            <Grid container className="flexJustifySpace">
                {data.students.map((student, i)=>(
                    (!student.professor &&
                        <UserAvatar profile={student} displayName />
                    )
                ))}
            </Grid>
        }
        </div>
    );
  }
  
export default ClassStudents;