import React,{useState,useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, CssBaseline, Drawer, Box, Container, Grid, Paper } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Header from './Includes/Header/Header';
import Sidebar from './Includes/Sidebar/Sidebar';
import Footer from './Includes/Footer/Footer';
import Dashboard from './Dashboard/Dashboard';
import Classes from './Classes/Classes';
import InviteStudents from './Classes/InviteStudents';
import InviteProfessor from './Classes/InviteProfessor';
import Notes from './Notes/Notes';
import Assignments from './Assignments/Assignments';
import JOTCalendar from './Calendar/Calendar';
//import ChatsOld from './Chat/ChatsOld';
import Chats from "./Chat/Chats";
import Grades from './Grades/Grades';
import Administrators from "./Administrator/Administrators"
import Profile from './User/Profile';
import Logout from './Auth/Logout';
import { Switch, Route, withRouter  } from 'react-router-dom';
import {useStateValue} from '../store/StateProvider';
import Stomp from 'stompjs';
import {API_URL, SOCKET_API, axiosConfigWithoutToken, axiosConfig} from '../api/index';
import Students from "./Students/Students";
import _ from 'lodash';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const[sct,SetSct] = useState(null)
  const [{user}] = useStateValue();
  const [{sctn}, dispatchSetSctn] = useStateValue();
  const [socketTriggerState,setSocketTriggerState] = useState(false);
  //States
  const [drawerOpen, setDrawerOpen] = React.useState((window.matchMedia('(min-width: 320px) and (max-width: 768px)').matches)?false:true);
  const [width, setWidth] = useState(window.innerWidth);


  

  // screen size responsive logic start
useEffect(() => {
  function handleResize() {
    setWidth(window.innerWidth);
  }
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, [width]);

useEffect(() => {
  width < 770 && handleMobileView();
  width > 770 && handleDesktopView();
 },[width]);

function handleMobileView() {
  setDrawerOpen(false);
 
}
function handleDesktopView() {
  setDrawerOpen(true);
}
// screen size responsive logic end


  let socket = new WebSocket(SOCKET_API);
  let stompClient = Stomp.over(socket);

  
  
  //Toggle Drawer
  const toggleDrawerOpen = () => {
    if(!drawerOpen){
      setDrawerOpen(true);
    }else{
      setDrawerOpen(false);
    }
  };

  useEffect(()=>{
    let headersSocket = {
      token: user.token
    };
  if(!sctn){
    stompClient.connect(headersSocket, ()=>{
      SetSct(stompClient);
      dispatchSetSctn({
        type: 'SOCKET_CONNECT',
        sctn: stompClient
      });
    }, ()=>{
    
    });
  }

  },[]);
  
  
  return (
    <div className={classes.grow}>
    <div className={classes.root}>
      <CssBaseline />
      <Header toggleDrawerOpen={toggleDrawerOpen} t={t} drawerOpen={drawerOpen} />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        ModalProps={{
          keepMounted: true
        }}
        open={drawerOpen}
      >
        <Sidebar />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="100%"  className={classes.container}>
        {/* <Container maxWidth="lg" className={classes.container}> */}
          <Grid container spacing={3}>
         
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/classes" component={Classes} />
              <Route path="/notes" component={Notes} />
              <Route path="/students" component={Students} />
              <Route path="/assignments" component={Assignments} />
              <Route path="/calendar" component={JOTCalendar} />
              <Route path="/chats" component={Chats} />
              <Route path="/grades" component={Grades} />
              <Route path="/administrator" component={Administrators} />
              <Route path="/profile" component={Profile} />
              <Route path="/logout" component={Logout} />
              <Route exact path="/" component={Dashboard} />
            </Switch>
            
          </Grid>
          <Box  >
          {/* <Box pt={1} > */}
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
    </div>
  );
}

export default withRouter(Home);