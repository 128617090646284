import React, {useEffect, useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfigWithoutToken} from '../../api/index';
import axios from 'axios';  

const ForgotPassword = ({ onClose, open }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfigWithoutToken();
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
        let forgotData={
          email: forgotPasswordEmail
        }
        axios.post(API_URL+'user/reset', forgotData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          //onClose();
          setErrors({});
          setSuccess(true);
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('RECORD_PROCESSED_SUCCESS')
            }
          });
        })
        .catch((error) => {
          console.log(error);
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(forgotPasswordEmail==''){
        formIsValid = false;
        errorsData["forgotPasswordEmail"] = t('MSG_EMPTY_EMAIL');
      }
  
      if(forgotPasswordEmail!=''){
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(reg.test(forgotPasswordEmail.toLowerCase())==false){
          formIsValid = false;
          errorsData["forgotPasswordEmail"] = t('MSG_INVALID_EMAIL');
        }
      }

    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-term" open={open}>
      <DialogTitle>{t('FORGOT_PASSWORD?')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
          {success?
          <Alert severity="success" className="alertBox">
            {t('MSG_NEW_PASSWORD_SENT')}
          </Alert>
          :null}
        </div>
        <div>
              <TextField
                value={forgotPasswordEmail}
                onChange={(e)=>setForgotPasswordEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="forgotPasswordEmail"
                placeholder={t('SCHOOL_EMAIL')}
                name="forgotPasswordEmail"
                autoComplete="forgotPasswordEmail"
                error={errors["forgotPasswordEmail"]}
                helperText={errors["forgotPasswordEmail"]}
                autoFocus
                className="mb-2"
              />
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SEND_REQUEST')}
            </Button>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default ForgotPassword;