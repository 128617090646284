import React, {useEffect, useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, ListItemSecondaryAction, Paper, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import axios from 'axios';  
import UserAvatar from '../User/UserAvatar';

const InviteProfessor = ({ onClose, open, data, reloadClasses }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const [professorInvites, setProfessorInvites]=useState();
  const headers = axiosConfig(user.token);
  //const [success, setSuccess] = useState(false);

  useEffect(()=>{
    const fetchProfessorInvites=()=>{
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      axios.get(API_URL + 'user/invite?universityId=' + user.university.id + '&semesterId=' + user.semester.id +
      '&classId=' + data.id + '&type=professor', headers)
      .then((response) => {
        console.log(response);
        setProfessorInvites(response.data);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })
      .catch(errors => {
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_LOAD_DATA_ERROR')
          }
        });
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
      })      
    }
    fetchProfessorInvites();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //setSuccess(false);
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
      let emails={
        emails: [{
          email: email
        }]
      };
      const headers = axiosConfig(user.token);
      axios.post(API_URL+'invite/class/'+data.id+'/emails?type=professor', emails, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError('');
        setEmail('');
        reloadClasses();
        onClose();
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('MSG_INVITE_SENT')
          }
        });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
    }
  }

  const inviteProfessor = (professorId) => {
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      let postData={
        userId: professorId
      };
      axios.post(API_URL+'invite/class/' + data.id + '/professor', postData, headers)
      .then(()=>{
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          reloadClasses();
          onClose();
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('MSG_INVITE_SENT')
            }
          });
      })
      .catch((error) => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setServerError(t('MSG_PROCESS_ERROR'));
      });
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(email==''){
      formIsValid = false;
      errorsData["email"] = t('MSG_EMPTY_EMAIL');
    }

    if(email!=''){
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(reg.test(email.toLowerCase())==false){
        formIsValid = false;
        errorsData["email"] = t('MSG_INVALID_EMAIL');
      }
    }
    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="invite-professor-to-class" open={open}>
      <DialogTitle>{t('INVITE_PROFESSOR')+"  "+data.name}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          <Alert severity="info" className="alertBox">
            {t('INVITE_ONLY_ONE')}
          </Alert>
          {data.professorInvited &&
          <Alert severity="error" className="alertBox">
            {t('INVITE_ALREADY_PENDING_1')}
            {(data.invitedProfessor != null && data.invitedProfessor.id != null)&&
              <strong>
                {data.invitedProfessor.firstName} {data.invitedProfessor.lastName}
              </strong>
            }
            {(data.invitedProfessor != null && data.invitedProfessor.id == null) &&
              <strong>
                {data.invitedProfessor}
              </strong>
            }
            {t('INVITE_ALREADY_PENDING_2')}
          </Alert>
          }
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder={t('EMAIL_ADDRESS')}
              name="email"
              autoComplete="email"
              error={errors["email"]}
              helperText={errors["email"]}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('SEND_INVITE')}
            </Button>
          </form>
        </div>
        {(professorInvites && professorInvites.usersNotInvited && professorInvites.usersNotInvited.length>0) &&
        <div className="mt-3">
        <Paper variant="outlined">
          <List>
            {professorInvites.usersNotInvited.map((professor, i) => (
              <ListItem key={i} divider={((i+1)!=professorInvites.usersNotInvited.length)} className="pl-0 pr-0">
                <ListItemAvatar>
                  <UserAvatar profile={professor} />
                </ListItemAvatar>
                <div>
                  {professor.firstName + " " + professor.lastName}
                </div>
                <ListItemSecondaryAction>
                  <Button variant="contained" className="yellowButton mr-3" onClick={()=>{inviteProfessor(professor.id)}}>{t('INVITE')}</Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        </div>
        }
      </DialogContent>
    </Dialog>
);
}

export default InviteProfessor;