import React, {useEffect, useState} from 'react';
import {Avatar, Button ,Badge, Menu, MenuItem, Hidden, Paper, makeStyles, Grid, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, TextField, IconButton, Card, LinearProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import {useStyles} from '../../assets/styles/materialStyles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SchoolIcon from '@material-ui/icons/School';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {API_URL, axiosConfig} from '../../api/index';
import {DISK_LIMIT} from '../../config/index';
import axios from 'axios';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { Alert } from '@material-ui/lab'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import InviteToJot from './InviteToJot';
import Loader from '../Tools/Loader';
import SnackbarAlert from '../Tools/SnackbarAlert';
import Feedback from './Feedback';
import AddTerm from './AddTerm';
import JoinTerm from './JoinTerm';
import Term from './Term';
import * as moment from 'moment';


const Profile = () => {
  const [open, setOpen] = useState(false);
  const [{user}, dispatch] = useStateValue();

  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const { t } = useTranslation();

  const headers = axiosConfig(user.token);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteFriends, setInviteFriends] = useState(false);
  const [universityId, setUniversityId] = useState({});
  const [errors, setErrors] = useState({});
  const [semesters, setSemesters] = useState({});
  const [semesterToEdit, setSemesterToEdit]=useState({});
  const [serverError, setServerError] = useState('');
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [addTermOpen, setAddTermOpen] = useState(false);
  const [joinTermOpen, setJoinTermOpen] = useState(false);
  const [data, setData]=useState({});
  const [profileImg, setProfileImg] = useState(user.profileImageThumbnail);
  let diskLimit=parseFloat(DISK_LIMIT);
 const [colorBorder,setColorBorder] = useState('') ;
 const [ fieldEnable,setFieldEnable] = useState(false);





useEffect(()=>{
 
  //fetchUserProfile(0);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setUniversityId(user.university.id);
   
    //to set avatar border color
    if(user.professor ===true){
      setColorBorder('#e86129');
    }
    else if(user.professor ===false){
       setColorBorder('#dddddd');
     }
     else {
      setColorBorder('#ffffff');
     }
   loadSemesterData();

}, []);


const loadSemesterData = () =>{
   dispatchSetLoading({
    type: 'SET_LOADING',
    loading: true
  });
  axios.get(API_URL + 'semester?offset='+encodeURIComponent(moment().format('ZZ')), headers)
  .then((response)=>{
    setSemesters(response.data);
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
  })
  .catch((errors)=>{
    dispatchSnack({
      type: 'SET_SNACK',
      snack: {
        flag: true,
        message: t('MSG_LOAD_DATA_ERROR')
      }
    });
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
    //console.log(errors);
  })

}


const fetchUserProfile = () => {
  dispatchSetLoading({
    type: 'SET_LOADING',
    loading: true
  });
  axios.get(API_URL + 'user/login', headers)
  .then((response)=>{
    setData(response.data);
    setFirstName(response.data.firstName);
    setLastName(response.data.lastName);
    setEmail(response.data.email);
    setUniversityId(response.data.university.id)
    // console.log(response.data);
    localStorage.setItem('user', JSON.stringify(response.data));
    dispatch({
      type: 'PROFILE_UPDATE',
      user: response.data
    });
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
  })
  .catch((errors)=>{
    dispatchSnack({
      type: 'SET_SNACK',
      snack: {
        flag: true,
        message: t('MSG_LOAD_DATA_ERROR')
      }
    });
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
  })
}

const handleFeedbackOpen = () => {
  setFeedbackOpen(true);
};

const handleFeedbackClose = () => {
  setFeedbackOpen(false);
};

const handleAddTermOpen = () => {
    // for add term make field enabled start
    setFieldEnable(true);
    // for add term make field enabled end
  setAddTermOpen(true);
};

const handleAddTermClose = () => {
  setAddTermOpen(false);
};

const handleJoinTermOpen = () => {
  setJoinTermOpen(true);
};

const handleJoinTermClose = () => {
  setJoinTermOpen(false);
};

const editTerm = (semester) => {
  setSemesterToEdit(semester);
}

const handleInviteOpen = () => {
  setInviteFriends(true)
}
const handleInviteClose = () => {
  setInviteFriends(false)
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    position: 'absolute',
    width: theme.spacing(100),
    height: theme.spacing(40),
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
const classes = useStyles();

const handleProfileSubmit = (event) => {

  event.preventDefault()
  if(validateForm()){
  dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
  });
  let userProfileData = {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
    universityId: universityId,
    professor: user.professor,
    semesterId: user.semester ? user.semester.id : null
  };
  axios.put(API_URL+'user', userProfileData, headers)
  .then(response => {
    fetchUserProfile();
    dispatchSnack({
      type: 'SET_SNACK',
      snack: {
        flag: true,
        message: t('MSG_SUCCESS')
      }
    });
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
  })
  .catch((error) => {
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: false
    });
    setServerError(t('MSG_PROCESS_ERROR'));
  });
  }
}
const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(firstName==''){
      formIsValid = false;
      errorsData["firstName"] = t('MSG_EMPTY_FIRST_NAME');
    }

    if(firstName!=''&&firstName.length<2){
      formIsValid = false;
      errorsData["firstName"] = t('MSG_SHORT_FIRST_NAME');
    }

    if(firstName!=''&&firstName.length>50){
      formIsValid = false;
      errorsData["firstName"] = t('MSG_LONG_FIRST_NAME');
    }

    if(lastName==''){
      formIsValid = false;
      errorsData["lastName"] = t('MSG_EMPTY_LAST_NAME');
    }

    if(lastName!=''&&lastName.length<2){
      formIsValid = false;
      errorsData["lastName"] = t('MSG_SHORT_LAST_NAME');
    }

    if(lastName!=''&&lastName.length>50){
      formIsValid = false;
      errorsData["lastName"] = t('MSG_LONG_LAST_NAME');
    }

    if(email==''){
      formIsValid = false;
      errorsData["email"] = t('MSG_EMPTY_EMAIL');
    }

    if(email!=''){
      let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(reg.test(email.toLowerCase())==false){
        formIsValid = false;
        errorsData["email"] = t('MSG_INVALID_EMAIL');
      }
    }

    if(universityId==''){
      formIsValid = false;
      errorsData["universityId"] = t('MSG_EMPTY_UNIVERSITY');
    }

    // if(password==''){
    //   formIsValid = false;
    //   errorsData["password"] = t('MSG_EMPTY_PASSWORD');
    // }

    setErrors(errorsData);
    return formIsValid;
  }

  const imageHandler = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
        if(reader.readyState === 2) {
            setProfileImg(reader.result);
            // axios.post(API_URL + 'media/profileImage', 
            // new FormData(document.getElementById('uploadProfileImageForm'))
            // , {'jot-token': user.token, "Content-Type": undefined})
            dispatchSetLoading({
              type: 'SET_LOADING',
              loading: true
            });
            setErrors({});
            let img=new FormData(document.getElementById('uploadProfileImageForm'));
            const headers = axiosConfig(user.token);
            axios.post(API_URL+'media/profileImage', img, headers)
            .then(response => {
              fetchUserProfile();
              dispatchSetLoading({
                type: 'SET_LOADING',
                loading: false
              });
              setServerError('');
              dispatchSnack({
                type: 'SET_SNACK',
                snack: {
                  flag: true,
                  message: t('MSG_SUCCESS')
                }
              });
            })
            .catch((error) => {
              dispatchSetLoading({
                type: 'SET_LOADING',
                loading: false
              });
              setServerError(t('MSG_PROCESS_ERROR'));
            });
            
        }
    }
    if (event.target.files[0]){
    reader.readAsDataURL(event.target.files[0])
    }
  }

    return(
      <Grid item xs={12}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
          <Card>
            <div className="header">
              <Typography variant="h6">
                {t('PROFILE')}
              </Typography>
            </div>
            <div className="contentContainer">
            <form className={classes.form} noValidate onSubmit={handleProfileSubmit}><div>
                {serverError!=""?
                <Alert severity="error" className="alertBox">
                  {serverError}
                </Alert>
                :null}
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <div className="mb-3 flexCenter">
                  <div className="profileAvatarOuterBorder" style={{color:`${colorBorder}`}}>
                  <div className="profileAvatarInnerBorder" >
                    <Avatar alt={t('PROFILE')} src={profileImg} className="profileAvatar" />
                    </div>
                    </div>
                  </div>
                  <div>
                    <div>
                    <form id="uploadProfileImageForm">
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      name="file"
                      multiple
                      type="file"
                      onChange={imageHandler}
                      hidden
                    />
                    <label htmlFor="contained-button-file" style={{width: "100%"}}>
                      <Button variant="contained" component="span" className="greenButton mb-2">
                      {t('PROFILE_CHANGE_PHOTO')}    
                      </Button>
                    </label>
                    </form>
                      <Button variant="contained" className="orangeButton mb-3" onClick={handleInviteOpen}>{t('INVITE_JOT')}</Button>
                      <Button variant="contained" className="blueButton mb-3" onClick={handleFeedbackOpen}>{t('FEEDBACK')}</Button>
                      <div>
                        <div> 
                          <strong>{t('CURRENT_DISK_USAGE')}: {user.diskQuota} {t('OF')} {diskLimit} {t('MB')} {t('USED')}</strong>
                        </div>
                        <LinearProgress variant="determinate" value={user.diskQuota * 100 / diskLimit} />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Feedback onClose={handleFeedbackClose} open={feedbackOpen} />
                <Grid item xs={12} sm={8}>
                  <TextField
                    value={firstName}
                    onChange={(e)=>setFirstName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    placeholder={t('PROFILE_FIRST_NAME')}
                    name="firstName"
                    autoComplete="firstName"
                    error={errors["firstName"]}
                    helperText={errors["firstName"]}
                    autoFocus
                    className="mb-2"
                  />
                  <TextField
                    value={lastName}
                    onChange={(e)=>setLastName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    placeholder={t('PROFILE_LAST_NAME')}
                    name="lastName"
                    autoComplete="off"
                    // autoComplete="lastName"
                    error={errors["lastName"]}
                    helperText={errors["lastName"]}
                    className="mb-2"
                  />
                  <TextField
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    placeholder={t('PROFILE_EMAIL')}
                    name="email"
                    autoComplete="email"
                    error={errors["email"]}
                    helperText={errors["email"]}
                    className="mb-2"
                    disabled
                  />
                  <TextField
                    value={user.university.name}
                    onChange={(e)=>setUniversityId(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="universityId"
                    placeholder={t('UNIVERSITY')}
                    name="universityId"
                    autoComplete="universityId"
                    error={errors["universityId"]}
                    helperText={errors["universityId"]}
                    className="mb-2"
                    disabled
                  />
                  <TextField
                    onChange={(e)=>setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    type="password"
                    id="password"
                    placeholder={t('PROFILE_PASSWORD')}
                    name="password"
                    autoComplete="password"
                    error={errors["password"]}
                    helperText={errors["password"]}
                    className="mb-2"
                  />
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="mt-3 mb-3"
                    >
                      {t('SAVE')}
                  </Button>
                  <div>
                    <Card>
                      <div className="header">
                        <Typography variant="h6">
                          {t('PROFILE_YOUR_SEMESTERS')}
                        </Typography>
                      </div>
                      {(semesters.length>0)?
                        semesters.map((semester, i) => (
                          <div key={i}>
                            <Term data={semester} reloadProfile={fetchUserProfile} loadSemesterData={loadSemesterData}/>
                          </div>
                        ))
                        :
                        null
                      }
                      <div className="flexJustifySpaceBetween p-3">
                        <Button onClick={handleJoinTermOpen} variant="contained" className="greenButton mb-3 mr-2">{t('SEMESTER_JOIN')}</Button>
                        {user.role=="ADMIN"  && <>  <Button onClick={handleAddTermOpen} variant="contained" className="orangeButton mb-3">{t('SEMESTER_ADD')}</Button> </>}
                        {user.role=="SUPERADMIN" && <>  <Button onClick={handleAddTermOpen} variant="contained" className="orangeButton mb-3">{t('SEMESTER_ADD')}</Button> </>}
                      </div>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </form>
            </div>
          </Card>
          </Grid>
          </Grid>
          <SnackbarAlert />
          <InviteToJot open={inviteFriends} onClose={handleInviteClose}/>
          <AddTerm open={addTermOpen} onClose={handleAddTermClose} fieldEnable={fieldEnable} reloadProfile={fetchUserProfile} loadSemesterData={loadSemesterData}/> 
          <JoinTerm open={joinTermOpen} onClose={handleJoinTermClose} reloadProfile={fetchUserProfile} loadSemesterData={loadSemesterData} />
          {loading ?
          <Loader active={true} />
          :
          null}
      </Grid>
    )
}




export default Profile;