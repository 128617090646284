import React, {useEffect, useState} from 'react';
import { Dialog, DialogContent, DialogTitle, Paper, Button, Grid, Accordion, AccordionSummary, Typography, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import axios from 'axios';
import {API_URL, axiosConfigWithoutToken, axiosConfig} from '../../api/index';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {
  useParams, useHistory, Link
} from "react-router-dom";
import AddGradeCriteria from './AddGradeCriteria';
import AddGrade from './AddGrade';
import AddStudentGrades from './AddStudentGrades';

const Grade = ({data}) => {
    const classes = useStyles();
    const [grades, setGrades]=useState({});
    const [gradeTotal, setGradeTotal]=useState(0);
    const [totalGradesSum, setTotalGradesSum]=useState(0);
    const [editData, setEditData]=useState({});
    const [gradeScore, setGradeScore]=useState('');
    const [{user}] = useStateValue();
    const { t } = useTranslation();
    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [addGradeCriteriaOpen, setAddGradeCriteriaOpen] = useState(false);
    const [editGradeCriteriaOpen, setEditGradeCriteriaOpen] = useState(false);
    const [addGradeOpen, setAddGradeOpen] = useState(false);
    const [editGradeOpen, setEditGradeOpen] = useState(false);
    const [studentsGradeOpen, setStudentsGradeOpen] = useState(false);
    const headersWithToken = axiosConfig(user.token);

    const handleAddGradeCriteriaOpen = () => {
      setAddGradeCriteriaOpen(true);
    };
  
    const handleAddGradeCriteriaClose = () => {
      setAddGradeCriteriaOpen(false);
    };

    const handleEditGradeCriteriaOpen = () => {
        setEditGradeCriteriaOpen(true);
    };

    const handleEditGradeCriteriaClose = () => {
        setEditGradeCriteriaOpen(false);
    };

    const handleAddGradeOpen = () => {
        setAddGradeOpen(true);
    };

    const handleAddGradeClose = () => {
        setAddGradeOpen(false);
    };

    const handleEditGradeOpen = () => {
        setEditGradeOpen(true);
    };

    const handleEditGradeClose = () => {
        setEditGradeOpen(false);
    };

    const handleStudentsGradeClose = () => {
        setStudentsGradeOpen(false);
    };

    const handleEditEntity = (editData) => {
        setEditData(editData);
        setEditGradeOpen(true);
    }

    const handleEditStudentsGrade = (editData) => {
        setEditData(editData);
        setStudentsGradeOpen(true);
    }
    
    const handleRemoveEntity = (entityId) => {
        dispatchSetLoading({
            type: 'SET_LOADING',
            loading: true
        });
        axios.delete(API_URL + 'grades/class/'+data.id+'/assignment/'+entityId, headersWithToken)
        .then(()=>{
            dispatchSnack({
            type: 'SET_SNACK',
            snack: {
                flag: true,
                message: t('MSG_DELETED')
            }
            });
            dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
            fetchGradeData();
        })
        .catch((errors)=>{
            dispatchSnack({
            type: 'SET_SNACK',
            snack: {
                flag: true,
                message: t('MSG_LOAD_DATA_ERROR')
            }
            });
            dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
            //console.log(errors);
        })
    }

    useEffect(()=>{
        fetchGradeData();
    }, []);

    const fetchGradeData = () =>{
        dispatchSetLoading({
            type: 'SET_LOADING',
            loading: true
        });
        axios.get(API_URL + 'grades/class/'+data.id, headersWithToken)
        .then((response)=>{
            setGrades(response.data);
            if(response.data.entities && response.data.entities.length>0){
                // let score=response.data.entities.reduce((pScore, cScore)=>{
                //     (pScore+(cScore.userTotalScore/cScore.totalScore)*cScore.totalGrades)});
                let score=0;
                let tgSum=0;
                response.data.entities.map((grade)=>{
                    if(grade.userTotalScore && grade.totalScore && grade.totalGrades){
                        score+=((grade.userTotalScore/grade.totalScore)*grade.totalGrades);
                        tgSum+=grade.totalGrades;
                    }
                });
                setGradeTotal(score);
                setTotalGradesSum(tgSum);
                if(response.data.criteria && response.data.criteria.length>0){
                    let ts=((score/tgSum)*100);
                    let grade="-";
                    response.data.criteria.map((gc)=>{
                        if((ts>=gc.percentageFrom && ts<=gc.percentageTo) || (ts>=gc.percentageTo && ts<=gc.percentageFrom)){
                            grade=gc.grade;
                        }
                    });
                    setGradeScore(grade);
                }
            }
            dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
        })
        .catch((errors)=>{
            dispatchSnack({
            type: 'SET_SNACK',
            snack: {
                flag: true,
                message: t('MSG_LOAD_DATA_ERROR')
            }
            });
            dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
            });
            //console.log(errors);
        })
    }

    return (
        <div>
            <Card className="mb-3">
                <div className="header">
                    <Typography variant="h6">
                        {t('GRADES_CRITERIA')}
                    </Typography>
                    <Grid>
                        {(data.professor != null && grades && grades.criteria && grades.criteria.length<1 && user.id==data.professor.id) &&
                        <div>
                            <Button onClick={handleAddGradeCriteriaOpen} variant="contained" className="yellowButton">{t('GRADES_CRITERIA_ADD')}</Button>
                            <Dialog fullWidth onClose={handleAddGradeCriteriaClose} aria-labelledby="add-class" open={addGradeCriteriaOpen}>
                                <DialogTitle>{t('GRADES_ADD_GRADES_CRITERIA')}</DialogTitle>
                                <DialogContent className="modalContent">
                                    <AddGradeCriteria reloadGrades={fetchGradeData} data={data} onClose={handleAddGradeCriteriaClose} />
                                </DialogContent>
                            </Dialog>
                        </div>
                        }
                        {(data.professor != null && user.id==data.professor.id && grades && grades.criteria && grades.criteria.length>0) &&
                        <div>
                            <Button disabled={user.semester.past} onClick={handleEditGradeCriteriaOpen} variant="contained" className="yellowButton">{t('GRADES_CRITERIA_EDIT')}</Button>
                            <Dialog fullWidth onClose={handleEditGradeCriteriaClose} aria-labelledby="edit-class" open={editGradeCriteriaOpen}>
                                <DialogTitle>{t('GRADES_EDIT_GRADES_CRITERIA')}</DialogTitle>
                                <DialogContent className="modalContent">
                                    <AddGradeCriteria reloadGrades={fetchGradeData} data={data} grades={grades} onClose={handleEditGradeCriteriaClose} />
                                </DialogContent>
                            </Dialog>
                        </div>
                        }
                    </Grid>
                </div>
                <div className="contentContainer">
                    {grades && grades.criteria && grades.criteria.length>0 &&
                        (grades.criteria.map((gc, i)=>(
                            <span className="mr-2"><strong>{gc.grade}</strong> ({gc.percentageFrom}% - {gc.percentageTo}%)</span>
                        )))
                    }
                </div>
            </Card>
            <Card>
                <div className="header">
                    <Typography variant="h6">
                        {t('GRADES_RESULTS')}
                    </Typography>
                    <Grid>
                        {(data.professor != null && user.id==data.professor.id) &&
                        <div>
                            <Button disabled={user.semester.past} onClick={handleAddGradeOpen} variant="contained" className="yellowButton">{t('GRADES_ADD')}</Button>
                            <Dialog fullWidth onClose={handleAddGradeClose} aria-labelledby="add-score" open={addGradeOpen}>
                                <DialogTitle>{t('GRADES_ADD')}</DialogTitle>
                                <DialogContent className="modalContent">
                                    <AddGrade grades={grades} reloadGrades={fetchGradeData} data={data} onClose={handleAddGradeClose} />
                                </DialogContent>
                            </Dialog>
                        </div>
                        }
                    </Grid>
                </div>
                <div className="contentContainer">
                    {grades && grades.entities && grades.entities.length>0 &&
                    ((data.professor != null && user.id!=data.professor.id)?
                    <TableContainer className="mt-2">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>{t('GRADES_TITLE')}</TableCell>
                                <TableCell>{t('GRADES_SCORE')}</TableCell>
                                <TableCell>{t('GRADES_PERCENTAGE')}</TableCell>
                                <TableCell>{t('GRADES_GRADE')}</TableCell>
                                <TableCell>{t('GRADES_PERC_TOTAL_GRADE')}</TableCell>
                                <TableCell>{t('GRADES_TOTAL')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {grades.entities.map((score, i) => (
                                ((score.userTotalScore>=0 && score.totalScore>0) &&
                                <TableRow>
                                    <TableCell>{score.name}</TableCell>
                                    <TableCell>{score.userTotalScore}/{score.totalScore}</TableCell>
                                    <TableCell>{(((score.userTotalScore/ score.totalScore))*100).toFixed(2)}%</TableCell>
                                    <TableCell>{score.calculateGrade}</TableCell>
                                    <TableCell>{score.totalGrades}%</TableCell>
                                    <TableCell>{((score.userTotalScore/score.totalScore)*score.totalGrades).toFixed(2)}%</TableCell>
                                </TableRow>
                                )
                                ))
                                }
                                {(gradeTotal<=100) &&
                                    <TableRow>
                                        <TableCell colSpan="5" align="right"><strong>{t('GRADES_TOTAL')}</strong></TableCell>
                                        <TableCell><strong>{gradeTotal.toFixed(2)}%</strong></TableCell>
                                    </TableRow>
                                }
                                {(gradeTotal<=100 && gradeScore!='') &&
                                    <TableRow>
                                        <TableCell colSpan="5" align="right"><strong>{t('GRADES_OVERALL_GRADE')}</strong></TableCell>
                                        <TableCell><strong>{gradeScore}</strong></TableCell>
                                    </TableRow>
                                }
                                {(gradeTotal>100) &&
                                    <TableRow>
                                        <TableCell colSpan="6" align="right"></TableCell>
                                        <TableCell><strong>{t('GRADES_TOTAL_ABOVE_CENT')}</strong></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <div>
                    <TableContainer className="mt-2">
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>{t('GRADES_TITLE')}</TableCell>
                                <TableCell>{t('GRADES_PERC_TOTAL_GRADE')}</TableCell>
                                <TableCell>{t('GRADES_TOTAL_SCORE')}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {grades.entities.map((score, i) => (
                                ((score.userTotalScore>=0 && score.totalScore>0) &&
                                <TableRow>
                                    <TableCell>{score.name}</TableCell>
                                    <TableCell>{score.totalGrades}</TableCell>
                                    <TableCell>{score.totalScore}</TableCell>
                                    <TableCell><IconButton aria-label="studentsGrade" onClick={()=>handleEditStudentsGrade(score)}>
                                    <PeopleAlt />
                                    </IconButton></TableCell>
                                    <TableCell><IconButton aria-label="edit" onClick={()=>handleEditEntity(score)}>
                                    <EditIcon />
                                    </IconButton></TableCell>
                                    <TableCell><IconButton aria-label="delete" onClick={()=>handleRemoveEntity(score.id)}>
                                    <DeleteIcon />
                                  </IconButton></TableCell>
                                </TableRow>
                                )
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog fullWidth onClose={handleEditGradeClose} aria-labelledby="edit-score" open={editGradeOpen}>
                        <DialogTitle>{t('GRADES_EDIT')}</DialogTitle>
                        <DialogContent className="modalContent">
                            <AddGrade grades={grades} reloadGrades={fetchGradeData} data={data} onClose={handleEditGradeClose} mode="edit" editData={editData} />
                        </DialogContent>
                    </Dialog>
                    <Dialog fullWidth onClose={handleStudentsGradeClose} aria-labelledby="students-score" open={studentsGradeOpen}>
                        <DialogTitle>{t('GRADES_ENTER_STUDENT_SCORES')}</DialogTitle>
                        <DialogContent className="modalContent">
                            <AddStudentGrades grades={grades} reloadGrades={fetchGradeData} data={data} onClose={handleStudentsGradeClose} editData={editData} />
                        </DialogContent>
                    </Dialog>
                    </div>
                    )
                    }
                </div>
            </Card>
        </div>
    );
}

export default Grade;