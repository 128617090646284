import React, {useState, useEffect} from 'react';
import {Avatar, Dialog, DialogTitle, DialogContent, Grid, Button, Badge} from '@material-ui/core';
import { useStateValue } from '../../store/StateProvider';
import {
  Link,useHistory
} from "react-router-dom";

import {useStyles} from '../../assets/styles/materialStyles';
import { useTranslation } from "react-i18next";
import {SOCKET_API} from "../../api/index";
import Stomp from 'stompjs';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ViewUser from "../Administrator/ViewUser"

const UserAvatar = ({size, displayName, containerStyles, userClasses, profile={}, profileName="", noModal=false,viewUserModal=false, chat=false, status,handleMemberModalClose,memberModalOpen,noChatButton=false}) => {
  const [{user}] = useStateValue();
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [viewUserModalOpen, setViewUserModalOpen] = useState(false);
  const history = useHistory();
  const [{chatWithParam}, dispatchSetChatWithParam] = useStateValue();
  const [{singleChatLoader}, dispatchSetChatsingleChatLoader] = useStateValue();

  const largeAvatar=(size=="large")?classes.avatarLarge:null;
  const smallAvatar=(size=="small")?classes.avatarSmall:null;

  

  const handleModalOpen = () => {
  
    if(!noModal){
      // logic to open view usermodal start
      if(viewUserModal){
        setViewUserModalOpen(true);
      }
      // logic to open view usermodal end
      //logic to open modals other than usermodal start
      else{
        setModalOpen(true);
      }
      //logic to open modals other than usermodal end
    }
   
  };

  // logic to close view user modal start
  const handleuserViewModalClose = () =>{
    setViewUserModalOpen(false);
  }
  // logic to close view user modal end


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const goToSpecificChat = (profile=null) =>{
    

    // groupchat member list modal close logic
    if(memberModalOpen){
      handleModalClose();
      handleMemberModalClose();
    }

    dispatchSetChatWithParam
    ({
      type: 'CHAT_WITH_PARAM', 
      chatWithParam
      : profile.id
    });
    
    dispatchSetChatsingleChatLoader
    ({
      type: 'SINGLE_CHAT_LOADER', 
      singleChatLoader
      : true
    });
    
    history.push('/chats');
  }



 

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: (status)?'#44b700':'#cc0029',
      color: (status)?'#44b700':'#cc0029',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  if((profile.id!=user.id) && (profile.professor === false) ){
    return (
      <>
        <div className={containerStyles}>
            <div className="flexJustifyCenter">
                {chat && status!=undefined?
                <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                  <Link onClick={handleModalOpen}>
                  <div className="userAvatarOuterBorderStudent" >
              <div className="useAvatarInnerBorder">
                    <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
                    </div></div>
                    </Link>
                </StyledBadge>
                :
                <Link onClick={handleModalOpen}>
                  <div className="userAvatarOuterBorderStudent" >
              <div className="useAvatarInnerBorder">
              <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
                  </div></div>
                  </Link>
                }
                <Dialog fullWidth onClose={handleModalClose} aria-labelledby="add-assignment" open={modalOpen}>
                  <DialogTitle>{(profile.professor)?t('PROFESSOR'):t('STUDENT')} {t('PROFILE')}</DialogTitle>
                  <DialogContent className="modalContent">
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                      <div className="userAvatarOuterBorderStudent" style={{width:'108px'}}>
                     <div className="useAvatarInnerBorder">
                        <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className="userAvatarLarge" />
                      </div></div>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <table className="table table-borderless noPaddingTable">
                        <tr>
                          <td><strong className="orange">{t('PROFILE_FIRST_NAME')}</strong></td>
                          <td>{profile.firstName}</td>
                        </tr>
                        <tr>
                          <td><strong className="orange">{t('PROFILE_LAST_NAME')}</strong></td>
                          <td>{profile.lastName}</td>
                        </tr>
                        {/* <tr>
                          <td><strong className="orange">{t('WHAT_CLASSES_TAKING_TOGETHER')}</strong></td>
                          <td>
                            {JSON.stringify(user.classes)}
                            {userClasses && userClasses.map((userClass, i) => (
                              <div>
                                - {userClass.profile.firstName + " " + profile.lastName}
                              </div>
                            ))}
                          </td>
                        </tr> */}
                      </table>
                      </Grid>
                      {noChatButton ? '' : 
                      <Grid item xs={12} className="flexJustifyCenter mt-2">
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="mt-3"
                          onClick={()=>goToSpecificChat(profile)}
                        >
                          {t('CHAT_WITH')} {profile.firstName + " " + profile.lastName}
                        </Button>
                      </Grid>}
                      
                    </Grid>
                  </DialogContent>
                </Dialog>
            </div>
            {displayName &&
            ((profileName!="")?
            <div className="flexJustifyCenter">
                <Link><strong>{profileName}</strong></Link>
            </div>
            :
            <div className="flexJustifyCenter">
                <Link><strong>{profile.firstName + " " + profile.lastName}</strong></Link>
            </div>
            )
            }
        </div>

        {/* view usermodal from administrator page start */}
        <ViewUser
    open={viewUserModalOpen}
    onClose={handleuserViewModalClose}
    userViewData={profile} />
        {/* view usermodal from administrator page end */}

        </>
    )
  }
  if((profile.id!=user.id) && (profile.professor === true) ){
    return (
      <>
        <div className={containerStyles}>
            <div className="flexJustifyCenter">
                {chat && status!=undefined?
                <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                  <Link onClick={handleModalOpen}>
                  <div className="userAvatarOuterBorderProfessor" >
                  <div className="useAvatarInnerBorder">
                    <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
                    </div>
                    </div>

                    </Link>
                </StyledBadge>
                :
                <Link onClick={handleModalOpen}>
                  <div className="userAvatarOuterBorderProfessor" >
              <div className="useAvatarInnerBorder">
              <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
                  </div></div>
                  </Link>
                }
                <Dialog fullWidth onClose={handleModalClose} aria-labelledby="add-assignment" open={modalOpen}>
                  <DialogTitle>{(profile.professor)?t('PROFESSOR'):t('STUDENT')} {t('PROFILE')}</DialogTitle>
                  <DialogContent className="modalContent">
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                  <div className="userAvatarOuterBorderProfessor" style={{width:'108px'}} >
                  <div className="useAvatarInnerBorder">
                        <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className="userAvatarLarge" />
                    </div>
                    </div>
                    
                     </Grid>
                      <Grid item xs={12} sm={6}>
                      <table className="table table-borderless noPaddingTable">
                        <tr>
                          <td><strong className="orange">{t('PROFILE_FIRST_NAME')}</strong></td>
                          <td>{profile.firstName}</td>
                        </tr>
                        <tr>
                          <td><strong className="orange">{t('PROFILE_LAST_NAME')}</strong></td>
                          <td>{profile.lastName}</td>
                        </tr>
                        {/* <tr>
                          <td><strong className="orange">{t('WHAT_CLASSES_TAKING_TOGETHER')}</strong></td>
                          <td>
                            {JSON.stringify(user.classes)}
                            {userClasses && userClasses.map((userClass, i) => (
                              <div>
                                - {userClass.profile.firstName + " " + profile.lastName}
                              </div>
                            ))}
                          </td>
                        </tr> */}
                      </table>
                      </Grid>
                      {noChatButton ? '' :
                      <Grid item xs={12} className="flexJustifyCenter mt-2">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="mt-3"
                        onClick={()=>goToSpecificChat(profile)}
                      >
                        {t('CHAT_WITH')} {profile.firstName + " " + profile.lastName}
                      </Button>
                    </Grid>
                      }
                      
                    </Grid>
                  </DialogContent>
                </Dialog>
            </div>
            {displayName &&
            ((profileName!="")?
            <div className="flexJustifyCenter">
                <Link><strong>{profileName}</strong></Link>
            </div>
            :
            <div className="flexJustifyCenter">
                <Link><strong>{profile.firstName + " " + profile.lastName}</strong></Link>
            </div>
            )
            }
        </div>

         {/* view usermodal from administrator page start */}
         <ViewUser
    open={viewUserModalOpen}
    onClose={handleuserViewModalClose}
    userViewData={profile} />
        {/* view usermodal from administrator page end */}
        </>
    )
  }
  if(profile.professor === true){
    return (
      <>
      <div className={containerStyles}>
      
          <div className="flexJustifyCenter">
          <div className="userAvatarOuterBorderProfessor" >
              <div className="useAvatarInnerBorder">
              <Avatar  alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
          </div>
          </div>
        </div>
          {displayName &&
          <div className="flexJustifyCenter">
              <strong>{profile.firstName + " " + profile.lastName}</strong>
          </div>
          }
      </div>
      </>
    )
}
// else{
if(profile.professor === false){
          return (
            <>
            <div className={containerStyles}>
            
                <div className="flexJustifyCenter">
                <div className="userAvatarOuterBorderStudent" >
              <div className="useAvatarInnerBorder">
                    <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
                </div>
                </div>
              </div>
                {displayName &&
                <div className="flexJustifyCenter">
                    <strong>{profile.firstName + " " + profile.lastName}</strong>
                </div>
                }
            </div>
            </>
          )
              }
    else{
    return (
      <>
        <div className={containerStyles}>
            <div className="flexJustifyCenter">
                <Avatar alt={profile.firstName + " " + profile.lastName} src={profile.profileImageThumbnail} className={`${largeAvatar} ${smallAvatar}`} />
           </div>
            {displayName &&
            <div className="flexJustifyCenter">
                <strong>{profile.firstName + " " + profile.lastName}</strong>
            </div>
            }
        </div>
        </>
      )
  }
}

export default UserAvatar;