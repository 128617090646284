import React from 'react'
import PropTypes from 'prop-types';
import {TableHead,TableSortLabel,TableCell,TableRow} from '@material-ui/core';

const headCells = [
    { id: 'profile',   label: 'Profile' },
    { id: 'firstName',   label: 'First Name' },
    { id: 'lastName',  label: 'Last Name' },
    { id: 'email',  label: 'Email' },
    { id: 'teacher',  label: 'Teacher' },
    { id: 'verified',  label: 'Verified' },
    { id: 'administrator',  label: 'Administrator' },
    { id: 'action',  label: 'Action' },
   
  ];

const TableEnhancedHead = (props) => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
      <TableRow className="mainRow">
        {headCells.map((headCell) => (
          <TableCell className="headBold"
            key={headCell.id}
            align={headCell.numeric ? '' : ''}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false} >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}  >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    )
}

TableEnhancedHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

export default TableEnhancedHead
