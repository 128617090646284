import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';

const ChatFooter = ({selectedChat, messageEl}) => {
    const classes = useStyles();
    const [{ user }] = useStateValue();
    const { t } = useTranslation();
    const [chatMessage, setChatMessage] = useState('');
    const [{ sctn }] = useStateValue();

    const handleClickSendMessage = () => {
        let path = selectedChat.group ? '/jotApp/add/group' : '/jotApp/add';
        let param = selectedChat.group ? 'chatGroupId' : 'toUserId';
    
        let obj = {};
        obj['token'] = user.token;
        obj[param] = selectedChat.id;
        obj['message'] = chatMessage;
        sctn.send(path, {}, JSON.stringify(obj));
         //used for new messages directly start
        if(messageEl && chatMessage){
          messageEl.current.addEventListener('DOMNodeInserted', event => {
            const { currentTarget: target } = event;
            target.scroll({ bottom: target.scrollHeight, behavior: 'smooth' });
          }, {once: true});
        }
    
        var objDiv = document.getElementById("chatDataId");
        objDiv.scrollTop = objDiv.offsetTop;
        //used for new messages directly end
        setChatMessage('');
      }

    return (
        <div className="message-input">
            <div className="wrap">
                <input className="messageBox"
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            handleClickSendMessage();
                        }
                    }}
                    type="text"
                    id="chatMessage"
                    placeholder={t('CHATS_MESSAGE')}
                    name="chatMessage"
                    autoComplete="off"
                />
                <button className="submit" onClick={handleClickSendMessage}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
            </div>
        </div>
    );
}

export default ChatFooter;