import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Badge, List, ListItem, ListItemText, ListItemIcon, Divider,Tooltip} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faStickyNote, faEdit, faCalendar, faComment, faTable, faCog, faPowerOff, faTachometerAlt, faCalendarAlt, faCalendarDay, faCalendarCheck,faGraduationCap,faArchive,faProjectDiagram,faBusinessTime,faUserCog} from '@fortawesome/free-solid-svg-icons';
import { useStateValue } from '../../../store/StateProvider';

const useStyles = makeStyles((theme) => ({
  list: {
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar,
  },
  logo: {
    width: 40
  },
  sidebarIcon: {
    fontSize: 18
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& path': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  listItem:{
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& path': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      }
    }
  }
}));

const Sidebar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
const [{singleChatLoader}, dispatchSetChatsingleChatLoader] = useStateValue();
const [{chatWithParam}, dispatchSetChatWithParam] = useStateValue();
const [{chatEventsCount}] = useStateValue();
const [{ user }] = useStateValue();



const singleChatLoaderMethod = () =>{
   dispatchSetChatsingleChatLoader
  ({
    type: 'SINGLE_CHAT_LOADER', 
    singleChatLoader
    : false
  });
  dispatchSetChatWithParam
  ({
    type: 'CHAT_WITH_PARAM', 
    chatWithParam
    : ''
  });
}
 
  return (
  <div>
    <div className={classes.logoContainer}>
      <img src={require('../../../assets/images/logo.png')} className={classes.logo} />
    </div>
    <Divider />
    <List className={classes.list}>
      <ListItem button component={NavLink} to="/dashboard"  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_DASHBOARD')}  arrow>
        <ListItemIcon>
        <FontAwesomeIcon className={classes.sidebarIcon} icon={faTachometerAlt} />
        </ListItemIcon>
      </Tooltip>
        <ListItemText primary={t('MENU_DASHBOARD')} />
      </ListItem>
      <ListItem button component={NavLink} to="/classes"  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_CLASSES')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faSchool} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_CLASSES')} />
      </ListItem>
       {/* <ListItem button component={NavLink} to="/students"  activeClassName={classes.active} className="listItem">
       <Tooltip title={t('MENU_STUDENTS')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faGraduationCap} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_STUDENTS')} />
      </ListItem> */}
      <ListItem button component={NavLink} to="/notes" activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_NOTES')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faStickyNote} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_NOTES')} />
      </ListItem>

      {/* saved  */}
      {/* <ListItem button component={NavLink} to="/notestest" activeClassName={classes.active} className="listItem">
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faStickyNote} />
        </ListItemIcon> 
        <ListItemText primary={t('MENU_NOTES')} />
      </ListItem> */}
      {/* saved  */}

      <ListItem button component={NavLink} to="/assignments"  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_ASSIGNMENTS')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faEdit} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_ASSIGNMENTS')} />
      </ListItem>
      <ListItem button component={NavLink} to="/calendar"  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_EVENTS')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faCalendarCheck} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_EVENTS')} />
      </ListItem>
      {/* <ListItem button component={NavLink} to="/chatold" onClick={() => singleChatLoaderMethod()}  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_CHAT')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faComment} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Old Chat" />
      </ListItem> */}
      {/* chat whatsapp start*/}
      <ListItem button component={NavLink} to="/chats" onClick={() => singleChatLoaderMethod()}  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_CHAT')} arrow>
        <ListItemIcon>
          <Badge badgeContent={chatEventsCount} color="primary" variant="dot">
            <FontAwesomeIcon className={classes.sidebarIcon} icon={faComment} />
          </Badge>
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_CHAT')} />
        {/* <ListItemText primary={t('MENU_CHAT')} /> */}
      </ListItem>
      {/* chat whatsapp end*/}
      <ListItem button component={NavLink} to="/grades"  activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_GRADES')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon={faTable} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_GRADES')} />
      </ListItem>
      {/* Administration */}
      {user.role=="ADMIN"  && <>
      <ListItem button component={NavLink} to="/administrator"   activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_ADMINISTRATOR')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon=
          {faUserCog} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_ADMINISTRATOR')} />
      </ListItem>  </>}

      {/* {user.role=="ADMIN"  && <>
      <ListItem button component={NavLink} to="/administrator/new"   activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_ADMINISTRATOR')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon=
          {faUserCog} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary="New administrator" />
      </ListItem>  </>} */}
      {user.role=="SUPERADMIN" && <>
      <ListItem button component={NavLink} to="/administrator"   activeClassName={classes.active} className="listItem">
      <Tooltip title={t('MENU_ADMINISTRATOR')} arrow>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.sidebarIcon} icon=
          {faUserCog} />
        </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t('MENU_ADMINISTRATOR')} />
      </ListItem>  </>}
     
    </List>
  </div>
  );
}

export default Sidebar;