import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/App.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './assets/styles/theme';
import * as serviceWorker from './serviceWorker';
import App from '../src/components/App';
import './i18n';
import { StateProvider } from '../src/store/StateProvider';
import reducer, {initialState} from '../src/store/Reducer';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
