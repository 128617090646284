import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../assets/styles/materialStyles';
import {  IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import ChatSidebar from './ChatSidebar';
import ChatContent from './ChatContent';

const Chat = () => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [selectedChat, setSelectedChat] = useState({});
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [chatDetailData, setChatDetailData] = useState([]);
  const [reloadFlag, setReloadFlag]=useState(false);
  const [page, setPage]=useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const[responsive,setResponsive] = useState(false);
  const[showSideBar,setShowSideBar] = useState(true);
  const [showContent,setShowContent] = useState(false);

  // screen size responsive logic start
useEffect(() => {
  function handleResize() {
    setWidth(window.innerWidth);
  }
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, [width]);

useEffect(() => {
  width < 426 && handleMobileView();
  width > 426 && handleDesktopView();
 },[width]);

function handleMobileView() {
  // keep sidebar div hidden on mobile if content div is populated start
  if(showContent && responsive){
    setShowSideBar(false);
}
  // keep sidebar div hidden on mobile if content div is populated end

  setResponsive(true);
}

function handleDesktopView() {
   // keep sidebar div display on desktop if content div is not populated start
  if(!showContent && !responsive){
    setShowSideBar(true);
  }
   // keep sidebar div display on desktop if content div is not populated end

   
  setResponsive(false);
}
const goToParticipant = () =>{
  setShowSideBar(true);
}
// screen size responsive logic end

  return (
    <div>
      {responsive && !showSideBar && <IconButton  onClick={goToParticipant} className="responsiveBackButton" size="small" color="primary" ><FontAwesomeIcon icon={faArrowAltCircleLeft} /></IconButton>} 
      <div className="mainFrame">
        <div id="frame">
          <ChatSidebar selectedChat={selectedChat} setSelectedChat={setSelectedChat} setReceivedMessage={setReceivedMessage} setChatDetailData={setChatDetailData} setReloadFlag={setReloadFlag} reloadFlag={reloadFlag} page={page} setPage={setPage} responsive={responsive} setResponsive={setResponsive} showSideBar={showSideBar} setShowSideBar={setShowSideBar} showContent={showContent} setShowContent={setShowContent}/>
          <ChatContent selectedChat={selectedChat}  setSelectedChat={setSelectedChat} receivedMessage={receivedMessage} chatDetailData={chatDetailData} setChatDetailData={setChatDetailData} setReloadFlag={setReloadFlag} reloadFlag={reloadFlag} page={page} setPage={setPage} responsive={responsive} setResponsive={setResponsive} showSideBar={showSideBar} setShowSideBar={setShowSideBar}   />
        </div>
      </div>
    </div>
  );
}

export default Chat;