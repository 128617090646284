import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../store/StateProvider';
import { useHistory } from "react-router-dom";
import Loader from '../Tools/Loader';

const Logout = () => {
  const [loading, setLoading] = useState(false);
  const [{user}, dispatch] = useStateValue();
  let history = useHistory();

  useEffect(()=>{
    setLoading(true);
    dispatch({
        type: 'LOGOUT'
    });
    localStorage.setItem('user', undefined);
    setLoading(false);
    history.push('./login');
  }, []);

  return (
    <div>
        {loading ?
        <Loader active={true} />
        :
        null}
    </div>
  );
}

export default Logout;