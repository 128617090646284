import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,Tooltip
} from "@material-ui/core";
//
import Hidden from "@material-ui/core/Hidden";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import FavoriteIcon from "@material-ui/icons/Favorite";
//
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFolder,
  faPen,
  faTrash,faFileUpload ,faFolderPlus, faBackward,faArrowCircleLeft
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL, axiosConfigWithoutToken, axiosConfig } from "../../api/index";
import Note from "./Note";
import { useStyles } from "../../assets/styles/materialStyles";
import { useStateValue } from "../../store/StateProvider";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddNote from "./AddNote";
import AddFolder from "./AddFolder";
import EditFolder from "./EditFolder";
import { Home } from "@material-ui/icons";
import DataFolder from "./DataFolder";
import _ from 'lodash';
import DeleteFolder from "./DeleteFolder"

const NoteBrowser = ({notesData, fetchNotesData}) => {
  const classes = useStyles();
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const [{ loading }, dispatchSetLoading] = useStateValue();
  const [{ snack }, dispatchSnack] = useStateValue();
  const headers = axiosConfigWithoutToken();
  const headersWithToken = axiosConfig(user.token);
  const [currentFolder, setCurrentFolder] = useState(notesData);


  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [addFolderOpen, setAddFolderOpen] = useState(false);
  const [editFolderOpen, setEditFolderOpen] = useState(false);
  const [deleteFolderOpen,setDeleteFolderOpen]= useState(false);
  const [folderEditDeleteId, setFolderEditDeleteId] = useState("");
  const [parentFolderId,setParentFolderId]= useState('');
  const[openedFolderId,setOpenedFolderId] = useState('');
  const[rootClassId,setRootClassId]= useState(notesData.id);



  useEffect(()=>{
    //fetchFolder();
  }, [notesData, currentFolder]);

  const fetchFolder = (folderId=null,classId=null) => {
 
    // to load root folders
   if(rootClassId && !parentFolderId && !folderId){
    dispatchSetLoading({
      type: 'SET_LOADING',
      loading: true
    });
      axios
      .get(API_URL + "class/"+rootClassId+"?token="+ user.token, headers)
      .then((response) => {
        setCurrentFolder(response.data);
       
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((errors) => {
        dispatchSnack({
          type: "SET_SNACK",
          snack: {
            flag: true,
            message: t("MSG_LOAD_DATA_ERROR"),
          },
        });
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      });
    }
    
    if(folderId){
    
      setOpenedFolderId(folderId);
    dispatchSetLoading({
      type: "SET_LOADING",
      loading: true,
    });
    axios
      .get(API_URL + "folder/"+folderId, headersWithToken)
      .then((response) => {
        setParentFolderId(response.data.parentFolderId);
        setCurrentFolder(response.data);
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((errors) => {
        dispatchSnack({
          type: "SET_SNACK",
          snack: {
            flag: true,
            message: t("MSG_LOAD_DATA_ERROR"),
          },
        });
        dispatchSetLoading({
          type: "SET_LOADING",
          loading: false,
        });
      });

    }
  };

//   const openFolder = (parentFolder) => {
//         setFolderArray([
//             ...folderArray,
//             parentFolder
//         ]);
//         setCurrentFolder(parentFolder);
//   }

  // api to load edit data on the basis of id
  const handleEditFolderOpen = () => {
    setEditFolderOpen(true);
  };
  const handleEditFolderClose = () => {
    setEditFolderOpen(false);
  };
  const handleAddFolderOpen = () => {
    setAddFolderOpen(true);
  };
  const handleAddFolderClose = () => {
    setAddFolderOpen(false);
  };
  //delete note modal
  const handleAddNoteOpen = () => {
    setAddNoteOpen(true);
  };
  const handleAddNoteClose = () => {
    setAddNoteOpen(false);
  };
  //delete folder modal
  const handleDeleteModalOpen = (id) => {
    setDeleteFolderOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteFolderOpen(false);
  };





  const goBack = () => {
    //if in child folders
    if(parentFolderId){
      fetchFolder(parentFolderId)
    }
    else{
      //if at root
      fetchFolder()
      setOpenedFolderId('')
    }
  }

  return (
        <Grid container spacing={3}>
            <Grid spacing={3} container justify="space-between" alignItems="center">
                <Grid item>
                <Hidden only={["sm","md","lg","xl"]}>
                    <div className="contentContainer">
                    <Grid container justify="space-between" className="responsiveBackTitle"  alignItems="center">
                      {openedFolderId  && <>
                       <Grid item>
                       <Tooltip title={t('FOLDER_BACK')} arrow>
                           <IconButton
                               className="headerIconButton"
                               onClick={() => goBack()}  >
                               <FontAwesomeIcon
                               className="backIcon"
                               icon={faArrowCircleLeft}
                            />
                           </IconButton>
                       </Tooltip>
                   </Grid>
                    </>}
                     <Grid item className="ml-2">
                            {currentFolder && openedFolderId &&
                                <div className="folderTitle">{currentFolder.name}</div>
                            }
                        </Grid>
                    </Grid>
                    </div>
                    </Hidden>
                    <Hidden only={["xs"]}>
                    <div className="contentContainer" >
                    <Grid container justify="space-between"  alignItems="center">
                      {openedFolderId  && <>
                       <Grid item>
                       <Tooltip title={t('FOLDER_BACK')} arrow>
                           <IconButton
                               className="headerIconButton"
                               onClick={() => goBack()}  >
                               <FontAwesomeIcon
                               className="backIcon"
                               icon={faArrowCircleLeft}
                              //  icon={faBackward}
                               
                               />
                           </IconButton>
                       </Tooltip>
                   </Grid>
                    </>}
                     <Grid item className="ml-2">
                            {currentFolder && openedFolderId &&
                                <div className="folderTitle">{currentFolder.name}</div>
                            }
                        </Grid>
                    </Grid>
                    </div>
                    </Hidden>
                </Grid>
                <Grid item className="firstAddNoteFolder">
                  <Hidden only={["sm","md","lg", "xl"]}>
                <div className="contentContainer forResponsiveTop">
                <Tooltip title={t('NOTE_ADD')} arrow>
                        <IconButton 
                            className="headerIconButton headertrashButton fileUpload"
                            onClick={() => handleAddNoteOpen()}
                            >
                            <FontAwesomeIcon
                            className="fileUploadIcon "
                            icon={faFileUpload}
                            />
                        </IconButton>
                    </Tooltip>
                    {/* only professors can add a folder and students are not allowed start */}
                        {user.professor ===true && <>
                        <Tooltip title={t('FOLDER_ADD')} arrow>
                        <IconButton
                            className="headerIconButton headerFolderPlushButton"
                            onClick={() => handleAddFolderOpen()}  >
                            <FontAwesomeIcon
                            className="folderPlusIcon"
                            icon={faFolderPlus}
                            />
                        </IconButton>
                        </Tooltip>
                        {/* delete edit folder buttons only available to creator start */}
                        {openedFolderId   && <>
                        {/* {openedFolderId  && currentFolder && currentFolder.creator && currentFolder.creator.id==user.id && user.role=="USER" && <> */}
                      <Tooltip title={t('EDIT_FOLDER')}  arrow>
                            <IconButton
                            className="headerIconButton"
                            onClick={
                            () => handleEditFolderOpen(folderEditDeleteId)
                            }
                        >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('DELETE_FOLDER')}  arrow>
                        <IconButton
                            className="headerIconButton headertrashButton"
                            onClick={() =>
                            handleDeleteModalOpen(folderEditDeleteId)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                    </Tooltip>
                    </>
                    }
                        {/* delete edit folder buttons only available to creator end */}
                        {/* delete edit folder buttons also available to admin start */}
                        {/* {openedFolderId  &&  user.role!=="USER" && <>
                      <Tooltip title={t('EDIT_FOLDER')}  arrow>
                            <IconButton
                            className="headerIconButton"
                            onClick={
                            () => handleEditFolderOpen(folderEditDeleteId)
                            }
                        >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('DELETE_FOLDER')}  arrow>
                        <IconButton
                            className="headerIconButton headertrashButton"
                            onClick={() =>
                            handleDeleteModalOpen(folderEditDeleteId)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                    </Tooltip>
                    </>
                    } */}
                    {/* delete edit folder buttons also available to admin start */}


                    </>}
                    {/* only professors can add a folder and students are not allowed end */}
                    </div>
                    </Hidden>
                    <Hidden  only={["xs"]}>
                   <div className="contentContainer">
                   <Tooltip title={t('NOTE_ADD')} arrow>
                        <IconButton 
                            className="headerIconButton headertrashButton fileUpload"
                            onClick={() => handleAddNoteOpen()}
                            >
                            <FontAwesomeIcon
                            className="editIcon "
                            icon={faFileUpload}
                            />
                        </IconButton>
                    </Tooltip>
                    {/* only professors can add a folder and students are not allowed start */}
                    {user.professor ===true && <>
                        <Tooltip title={t('FOLDER_ADD')} arrow>
                        <IconButton
                            className="headerIconButton headerFolderPlushButton"
                            onClick={() => handleAddFolderOpen()}  >
                            <FontAwesomeIcon
                            className="folderPlusIcon"
                            icon={faFolderPlus}
                            />
                        </IconButton>
                        </Tooltip>
                    {openedFolderId  && <>
                    {/* delete edit folder buttons only available to creator start */}
                      {/* {openedFolderId  && currentFolder && currentFolder.creator && currentFolder.creator.id==user.id && user.role=="USER" && <> */}
                      <Tooltip title={t('EDIT_FOLDER')}  arrow>
                            <IconButton
                            className="headerIconButton"
                            onClick={
                            () => handleEditFolderOpen(folderEditDeleteId)
                            }
                        >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('DELETE_FOLDER')}  arrow>
                        <IconButton
                            className="headerIconButton headertrashButton"
                            onClick={() =>
                            handleDeleteModalOpen(folderEditDeleteId)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                    </Tooltip>
                    </>
                    }
                    {/* delete edit folder buttons only available to creator end */}
                    {/* delete edit folder buttons also available to admin start */}

                    {/* {openedFolderId  &&  user.role!=="USER" && <>
                      <Tooltip title={t('EDIT_FOLDER')}  arrow>
                            <IconButton
                            className="headerIconButton"
                            onClick={
                            () => handleEditFolderOpen(folderEditDeleteId)
                            }
                        >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faPen}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('DELETE_FOLDER')}  arrow>
                        <IconButton
                            className="headerIconButton headertrashButton"
                            onClick={() =>
                            handleDeleteModalOpen(folderEditDeleteId)
                            } >
                            <FontAwesomeIcon
                            className="editIcon"
                            icon={faTrash}
                            />
                        </IconButton>
                    </Tooltip>
                    </>
                    } */}
                    {/* delete edit folder buttons also available to admin start */}
                    </>} 
                    {/* only professors can add a folder and students are not allowed end */}
                    </div>
                    </Hidden>
                    {/* buttons inside folder end */}
                    {/* Folder and Note Component called start */}
                    <AddFolder
                        open={addFolderOpen}
                        onClose={handleAddFolderClose}
                        parentFolder={currentFolder}
                        reloadFolders={fetchFolder}
                        classId={notesData.id}
                        fetchNotesData={fetchNotesData}
                        openedFolderId={openedFolderId}
                    />
                    <AddNote
                        open={addNoteOpen}
                        onClose={handleAddNoteClose}
                        currentFolder={currentFolder}
                        reloadNotes={fetchFolder}
                        classId={notesData.id}
                        openedFolderId={openedFolderId}
                    />
                    <EditFolder
                        open={editFolderOpen}
                        onClose={handleEditFolderClose}
                        currentFolder={currentFolder}
                        reloadNotes={fetchFolder}
                        classId={notesData.id}
                        classId={notesData.id}
                        openedFolderId={openedFolderId}
                        goBack={goBack}
                    />
                    <DeleteFolder
                     open={deleteFolderOpen}
                     onClose={handleDeleteModalClose}
                     currentFolder={currentFolder}
                     reloadFolders={fetchFolder}
                    classId={notesData.id}
                    openedFolderId={openedFolderId}
                    goBack={goBack}
                    />
                    {/* Folder and Note Component called end */}
                </Grid>
            </Grid>
            <Grid container>
              {currentFolder && currentFolder.folders && currentFolder.folders.length>0 && 
                    currentFolder.folders.map((folder, i) => (
                      <Grid item lg={3} sm={6} xs={12} md={4} xl={2} >
                        <DataFolder
                            folderData={folder}
                            openFolder={fetchFolder}
                            classId={notesData.id}
                            currentFolder={currentFolder}
                         
                             />
                     </Grid>

                    ))
                }
            </Grid>
            <Grid container spacing={3} className="contentContainer noteFiles">
                 {currentFolder && currentFolder.notes && currentFolder.notes.length>0 && 
            !openedFolderId ?
                (currentFolder.notes.map((note, i) => (
                  !note.folder && 
                    <Note data={note} 
                    openedFolderId={openedFolderId} 
                    currentFolder={currentFolder}
                    reloadNotes={fetchFolder} 
                    classId={notesData.id}
                    parentFolder={currentFolder}
                    rootClassId={rootClassId}/>
                )))
                :
                (currentFolder.notes.map((note, i) => (
                  note.folder && 
                    <Note data={note} 
                    openedFolderId={openedFolderId} 
                    currentFolder={currentFolder}
                    reloadNotes={fetchFolder} 
                    classId={notesData.id}
                    parentFolder={currentFolder}
                    rootClassId={rootClassId}/>
                )))
            }
             </Grid>
        </Grid>
  );
};

export default NoteBrowser;
