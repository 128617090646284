import React, {useState,useEffect} from 'react';
import { Grid,TextField, Button, Dialog, DialogTitle,Checkbox, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText,
  FormLabel } from '@material-ui/core';
import { Alert,Autocomplete } from '@material-ui/lab'; 
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfig} from '../../api/index';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    } from '@material-ui/pickers';
import axios from 'axios';
import * as moment from 'moment';





const EditUser = ({onClose, open,userEditData,addUserUniversityId,reloadTableData,reloadSuperAdminTableData,universityIdForAdd,universityDatum,errors,setErrors}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const [serverError, setServerError] = useState('');

    const [{loading}, dispatchSetLoading] = useStateValue();
    const [{snack}, dispatchSnack] = useStateValue();
    const [{user}] = useStateValue();
    const headersWithToken = axiosConfig(user.token);
    const headers = axiosConfig(user.token);
    const[address,setAddress] = useState('');
    const[dob,setDob] = useState('');
    const[email,setEmail] = useState('');
    const[firstName,setFirstName] = useState('');
    const[lastName,setLastName] = useState('');
    const[parent,setParent] = useState(false);
    const[password,setPassword] = useState('');
    const[phoneNumbers,setPhoneNumbers] = useState('')
    const[professor,setProfessor] = useState(false);
    const[semesterId,setSemesterId] = useState('')
    const [gender,setGender] = useState("MALE");
    const[confirmPassword,setConfirmPassword] = useState('');
    const [universityId,setUniversityId] = useState('');
    const [semesterData,setSemesterData] = useState([]);
    const [universityIdForSemester,setUniversityIdForSemester]= useState();
    const [professorParent,setProfessorParent] = useState('');


  // function to get id from university object start
  function GetPropertyValue(obj1, dataToRetrieve) {
    return dataToRetrieve
      .split('.') // split string based on `.`
      .reduce(function(o, k) {
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
      }, obj1) // set initial value as object
  }
      // function to get id from university object end
   

useEffect(()=>{
  if(universityIdForAdd){
    setUniversityId(universityIdForAdd)
  }if(addUserUniversityId){
    setUniversityId(addUserUniversityId)
  }

},[addUserUniversityId,universityIdForAdd])

useEffect(()=>{
  semesterLoad();
},[universityIdForSemester])

useEffect(()=>{
  setUniversityIdForSemester(GetPropertyValue(universityDatum, "id"))
},[universityDatum])


const semesterLoad = () =>{
  axios.get(API_URL+'semester/university?universityId='+universityIdForSemester, headersWithToken)
    .then(response => {
      setSemesterData(response.data);
    }).catch((error)=>{

    })
}

const handleSubmit = (e) =>{
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      let userData =  {
        "address":address,
        "dateOfBirth":dob,
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "parent": parent,
        "password": password,
        "phoneNumbers": [
          phoneNumbers
        ],
        "professor": professor,
        "semesterId": semesterId,
        "sex": gender,
        "universityId": universityIdForSemester,
        }
      
        axios.post(API_URL+'administrator/user', userData, headers)
      .then(response => {
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        setAddress('');
        setDob('');
        setEmail('');
        setFirstName('');
        setLastName('');
        setParent(false);
        setPassword('');
        setPhoneNumbers('');
        setConfirmPassword('');
        setProfessor(false);
        setSemesterId('')
        setGender('MALE');
        setUniversityId('');
        onClose();
        if(universityIdForAdd){
          reloadSuperAdminTableData()
        }else{
          reloadTableData();
        }
       
        dispatchSnack({
          type: 'SET_SNACK',
          snack: {
            flag: true,
            message: t('USER_ADDED_SUCCESS')
          }
        });
      }).catch((error)=>{
        console.log(error);
        dispatchSetLoading({
          type: 'SET_LOADING',
          loading: false
        });
        if(error.message.includes("507")){
          setServerError(t('NOTE_ADD_ERROR_DISK_QUOTA_1' + " " + t('NOTE_ADD_ERROR_DISK_QUOTA_2')));
        }else if(error.message.includes("415")){
          setServerError(t('NOTE_ADD_INCORRECT_FORMAT'));
        }else if(error.message.includes("503")){
          setServerError(t('NOTE_ADD_SERVICE_UNAVAILABLE_ERROR'));
        }else if(error.message.includes("500")){
          setServerError(t('NOTE_ADD_INTERNAL_SERVER_ERROR'));
        }else{
          setServerError(t('MSG_PROCESS_ERROR'));
        }
      })
    }
}


const validateForm = () => {
  let errorsData = {};
  let formIsValid = true;

  if(email==''){
    formIsValid = false;
    errorsData["email"] = t('MSG_EMPTY_EMAIL');
  }

  if(email!=''){
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(reg.test(email.toLowerCase())==false){
      formIsValid = false;
      errorsData["email"] = t('MSG_INVALID_EMAIL');
    }
  }



  if(firstName==''){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_EMPTY_FIRST_NAME');
  }
  if(firstName!=''&&firstName.length<2){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_SHORT_FIRST_NAME');
  }

  if(firstName!=''&&firstName.length>50){
    formIsValid = false;
    errorsData["firstName"] = t('MSG_LONG_FIRST_NAME');
  }

  if(lastName==''){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_EMPTY_LAST_NAME');
  }

  
  if(lastName!=''&&lastName.length<2){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_SHORT_LAST_NAME');
  }

  if(lastName!=''&&lastName.length>50){
    formIsValid = false;
    errorsData["lastName"] = t('MSG_LONG_LAST_NAME');
  }

  if(password==''){
    formIsValid = false;
    errorsData["password"] = t('MSG_EMPTY_PASSWORD');
  }

  if(confirmPassword==''){
    formIsValid = false;
    errorsData["confirmPassword"] = t('MSG_EMPTY_CONFIRM_PASSWORD');
  }

  
  if((password!='')&&(confirmPassword!='')&&(confirmPassword!=password)){
    formIsValid = false;
    errorsData["confirmPassword"] = t('MSG_PASSWORD_NOT_MATCH');
  }

  setErrors(errorsData);
    return formIsValid;

}

const handleDateOfBirth = (date) =>{
  var momentDate = moment(date).format(t('DATE_USER_EDIT'));
    setDob(momentDate);
  }
  
  const genderDeal =(e) =>{
    setGender(e.target.value);
  }

  useEffect(()=>{
    setProfessorParent('student');
  },[])

  const  parentProfessor = (e) =>{
    if(e.target.value=="professor"){
      setProfessorParent("professor")
      setProfessor(true)
      setParent(false)

    }
    if(e.target.value=="student"){
      setProfessorParent("student")
      setProfessor(false)
      setParent(false)

    }
    if(e.target.value=="parent"){
      setProfessorParent("parent")
      setParent(true)
      setProfessor(false)
    }
    
  }


    return (
       <>
       <Dialog  fullWidth onClose={onClose} aria-labelledby="add-note" open={open}>
      <DialogTitle>{t('USER_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
        </div>
        <div>
         <form className={classes.form} noValidate onSubmit={handleSubmit}>
         <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
        <TextField
                    value={firstName}
                    onChange={(e)=> setFirstName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t('USER_FIRST_NAME')}
                    placeholder={t('USER_FIRST_NAME')}
                    name="firstName"
                    autoComplete="firstName"
                    error={errors["firstName"]}
                    helperText={errors["firstName"]}
                    autoFocus
                    className="mb-2"
                  />
                   </FormControl>
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                  <TextField
                    value={lastName}
                    onChange={(e)=> setLastName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={t('USER_LAST_NAME')}
                    placeholder={t('USER_LAST_NAME')}
                    name="lastName"
                    autoComplete="lastName"
                    error={errors["lastName"]}
                    helperText={errors["lastName"]}
                    className="mb-2"
                  />
                   </FormControl> 
                   {/* material ui gender */}
            <FormControl component="fieldset" className={classes.formControlEditUser}>
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                  aria-label="Gender"
                  name="gender"
                  className={classes.groupEditUser}
                  value={gender}
                  onChange={(e)=>genderDeal(e)}
                >
                <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
           {/* material ui gender */} 
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  margin="normal"
                  error={errors["dob"]}
                  helperText={errors["dob"]}
                  id="dob"
                  name="dob"
                  placeholder={t('USER_DATE_OF_BIRTH')}
                  format="MMMM dd, yyyy"
                  value={dob}
                  onChange={handleDateOfBirth}
              />
              </MuiPickersUtilsProvider>
                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                   <TextField
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('USER_EMAIL')}
                    placeholder={t('USER_EMAIL')}
                    name="email"
                    autoComplete="email"
                    error={errors["email"]}
                    helperText={errors["email"]}
                    className="mb-2"
                  />
          </FormControl>
          <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                 <TextField
                    value={phoneNumbers}
                    onChange={(e)=> setPhoneNumbers(e.target.value)}
                    variant="outlined"
                    margin="normal"
                     type="number"
                    fullWidth
                    id="phoneNumbers"
                    label={t('USER_PHONE_NUMBER')}
                    placeholder={t('USER_PHONE_NUMBER')}
                    name="phoneNumbers"
                    autoComplete="phoneNumbers"
                    error={errors["phoneNumbers"]}
                    helperText={errors["phoneNumbers"]}
                    className="mb-2"
                  />
                   </FormControl>
                  <FormControl  fullWidth variant="outlined" className={classes.formControl}>
                        <TextField
                    value={address}
                    rows={4}
                    multiline
                    onChange={(e)=> setAddress(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="address"
                    label={t('USER_ADDRESS')}
                    placeholder={t('USER_ADDRESS')}
                    name="address"
                    autoComplete="address"
                    error={errors["address"]}
                    helperText={errors["address"]}
                    className="mb-4"
                  />
                   </FormControl>

                   <Autocomplete className="auto-cross"
              id="combo-box-demo"
              options={semesterData}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSemesterId(newValue.id);
              }}
              renderInput={(params) => <TextField onChange={(e)=>setSemesterId(e.target.value)} 
              error={errors["semesterId"]}
              fullWidth
              helperText={errors["semesterId"]} {...params} label={t('SEMESTER_SELECT')} variant="outlined" />}
            />
            <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                  <TextField
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t('PASSWORD')}
                    placeholder={t('PASSWORD')}
                    name="password"
                    type="password"
                    autoComplete="password"
                    error={errors["password"]}
                    helperText={errors["password"]}
                    className="mb-2"
                  />
                   </FormControl>

                   <FormControl  fullWidth variant="outlined" className={classes.formControl}> 
                  <TextField
                    value={confirmPassword}
                    onChange={(e)=> setConfirmPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmPassword"
                    label={t('PROFILE_CONFIRM_PASSWORD')}
                    placeholder={t('PROFILE_CONFIRM_PASSWORD')}
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    error={errors["confirmPassword"]}
                    helperText={errors["confirmPassword"]}
                    className="mb-2"
                  />
                   </FormControl>


                   {/* radio button professor/student/parent start */}
                   <FormControl component="fieldset" className={classes.formControlEditUser}>
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                  aria-label="Gender"
                  name="professor"
                  className={classes.groupEditUser}
                  value={professorParent}
                  onChange={(e)=>parentProfessor(e)}
                >
                <FormControlLabel value="student" control={<Radio />} label="Student" />
                <FormControlLabel value="professor" control={<Radio />} label="Teacher" />
                <FormControlLabel value="parent" control={<Radio />} label="Parent" />
                </RadioGroup>
                </FormControl>
                {/* radio button professor/student/parent end */}
                {(professor=="" && parent==false)?
                  <Alert severity="error" className="alertBox align-left">
                  <div>{t('USER_PROFESSOR_MSG_1')}</div>
                  </Alert>
                  :null}
                  {(professor!="" && parent==false)?
                  <Alert severity="error" className="alertBox align-left">
                  <div>{t('USER_PROFESSOR_MSG_2')}</div>
                  </Alert>
                  :null}
                 
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
              
            >
              {t('USER_ADD_NEW')}
            </Button>
          </form> 
        </div>
      </DialogContent>
    </Dialog>

       </>
    )
}

export default EditUser
