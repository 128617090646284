import React, { useState, useEffect, useContext } from 'react';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, makeStyles, Container, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import Footer from '../Includes/Footer/Footer';
import Loader from '../Tools/Loader';
import { useTranslation } from "react-i18next";
import { useStateValue } from '../../store/StateProvider';
import { useHistory } from "react-router-dom";
import * as moment from 'moment';
import {SIGNIN_URL} from '../../api/index';
import axios from 'axios';
import background from '../../assets/images/background.jpg';
import {
  useParams
} from "react-router-dom";
import Login from './Login';
import Registration from './Registration';

const useStyles = makeStyles((theme) => ({
  superContainer: {
    position: "fixed",
    width: "100%",
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  main: {
    overflowY: 'auto'
  },
  logo: {
    width: 60,
    marginBottom: 10
  },
  paper: {
    padding: 20,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorAlert: {
    marginTop: 10
  }
}));

const Auth = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  //Set States
  const [loading, setLoading] = useState(false);
  const [{user}, dispatch] = useStateValue();
  const [page, setPage]=useState("login");

  let history = useHistory();

  useEffect(()=>{
    if(user){
      history.push('/dashboard');
    }else if((window.location.pathname).includes("registration")){
      setPage("registration");
    }else{
      setPage("login");
    }
  }, []);

  const showRegister = (e) => {
    e.preventDefault();
    setPage("registration");
  }

  const showLogin = (e) => {
    e.preventDefault();
    setPage("login");
  }
  
  return (
    <Box className={classes.superContainer} height="100vh" display="flex" flexDirection="column" justifyContent="center" textAlign="center">
      <Container component="main" maxWidth="xs" className="loginContent">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={require('../../assets/images/logo.png')} alt="Logo" className={classes.logo} />
          <Typography component="h1" variant="h5">
            {t('SIGN_IN')}
          </Typography>
          {(page=="login") &&
          <Login showRegister={showRegister} page={page} setPage={setPage} />
          }
          {(page=="registration") &&
          <Registration showLogin={showLogin} page={page} setPage={setPage} />
          }
        </div>
      </Container>
      {loading ?
      <Loader active={true} />
      :
      null}
    </Box>
  );
}

export default Auth;