import React, {useEffect, useState} from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Select, FormHelperText, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  } from '@material-ui/pickers';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import {API_URL, axiosConfigWithoutToken} from '../../api/index';
import axios from 'axios';  

const AddNewSchool = ({ onClose, open }) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const [{loading}, dispatchSetLoading] = useStateValue();
  const [{snack}, dispatchSnack] = useStateValue();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const headers = axiosConfigWithoutToken();
  const [schoolName, setSchoolName] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');
  const [schoolWebsite, setSchoolWebsite] = useState('');
  const [schoolEmail, setSchoolEmail] = useState('');
  const [isClosedSchool, setIsClosedSchool] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateForm()){
      dispatchSetLoading({
        type: 'SET_LOADING',
        loading: true
      });
      setErrors({});
        let universityData={
          name: schoolName,
          location: schoolAddress,
          emailDomain: schoolWebsite,
          userEmail: schoolEmail,
          closedUniversity: isClosedSchool
        }
        axios.post(API_URL+'university', universityData, headers)
        .then(response => {
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError('');
          //onClose();
          setSuccess(true);
          dispatchSnack({
            type: 'SET_SNACK',
            snack: {
              flag: true,
              message: t('RECORD_PROCESSED_SUCCESS')
            }
          });
        })
        .catch((error) => {
          console.log(error);
          dispatchSetLoading({
            type: 'SET_LOADING',
            loading: false
          });
          setServerError(t('MSG_PROCESS_ERROR'));
        });
    }
  }
  
  const validateForm = () => {
    let errorsData = {};
    let formIsValid = true;

    if(schoolName==''){
      formIsValid = false;
      errorsData["schoolName"] = t('MSG_EMPTY_SCHOOL_NAME');
    }

    if(schoolName!=''&&schoolName.length<3){
      formIsValid = false;
      errorsData["schoolName"] = t('MSG_SHORT_SCHOOL_NAME');
    }

    if(schoolName!=''&&schoolName.length>50){
      formIsValid = false;
      errorsData["schoolName"] = t('MSG_LONG_SCHOOL_NAME');
    }

    if(schoolAddress==''){
      formIsValid = false;
      errorsData["schoolAddress"] = t('MSG_EMPTY_SCHOOL_ADDRESS');
    }

    if(schoolAddress!=''&&schoolAddress.length<3){
      formIsValid = false;
      errorsData["schoolAddress"] = t('MSG_SHORT_SCHOOL_ADDRESS');
    }

    if(schoolAddress!=''&&schoolAddress.length>50){
      formIsValid = false;
      errorsData["schoolAddress"] = t('MSG_LONG_SCHOOL_ADDRESS');
    }

    if(schoolEmail==''){
        formIsValid = false;
        errorsData["schoolEmail"] = t('MSG_EMPTY_EMAIL');
      }
  
      if(schoolEmail!=''){
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(reg.test(schoolEmail.toLowerCase())==false){
          formIsValid = false;
          errorsData["schoolEmail"] = t('MSG_INVALID_EMAIL');
        }
      }

    setErrors(errorsData);
    return formIsValid;
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="add-term" open={open}>
      <DialogTitle>{t('SCHOOL_ADD')}</DialogTitle>
      <DialogContent className="modalContent">
        <div>
          {serverError!=""?
          <Alert severity="error" className="alertBox">
            {serverError}
          </Alert>
          :null}
          {success?
          <Alert severity="success" className="alertBox">
            {t('SCHOOL_CHECK')}
          </Alert>
          :null}
        </div>
        <div>
              <TextField
                value={schoolName}
                onChange={(e)=>setSchoolName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="schoolName"
                placeholder={t('SCHOOL_NAME')}
                name="schoolName"
                autoComplete="schoolName"
                error={errors["schoolName"]}
                helperText={errors["schoolName"]}
                autoFocus
                className="mb-2"
              />
              <TextField
                value={schoolAddress}
                onChange={(e)=>setSchoolAddress(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="schoolAddress"
                placeholder={t('SCHOOL_ADDRESS')}
                name="schoolAddress"
                autoComplete="schoolAddress"
                error={errors["schoolAddress"]}
                helperText={errors["schoolAddress"]}
                className="mb-2"
              />
              <TextField
                value={schoolWebsite}
                onChange={(e)=>setSchoolWebsite(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="schoolWebsite"
                placeholder={t('SCHOOL_WEBSITE')}
                name="schoolWebsite"
                autoComplete="schoolWebsite"
                error={errors["schoolWebsite"]}
                helperText={errors["schoolWebsite"]}
                className="mb-2"
              />
              <TextField
                value={schoolEmail}
                onChange={(e)=>setSchoolEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="schoolEmail"
                placeholder={t('SCHOOL_EMAIL')}
                name="schoolEmail"
                autoComplete="schoolEmail"
                error={errors["schoolEmail"]}
                helperText={errors["schoolEmail"]}
                className="mb-2"
              />
                <div className="flexStart">
                    <FormControlLabel
                    control={<Checkbox onChange={(e)=>{setIsClosedSchool(e.target.checked)}} name="isClosedSchool" />} checked={isClosedSchool} />
                    <span className="label text-left">{t('SCHOOL_CLOSED')}</span>
                </div>
                {isClosedSchool!=""?
                <Alert severity="error" className="alertBox align-left">
                    <div>{t('SCHOOL_CLOSED_NOTE')}</div>
                </Alert>
                :null}
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              className="mt-3"
            >
              {t('BTN_SEND_REQUEST')}
            </Button>
        </div>
      </DialogContent>
    </Dialog>
);
}

export default AddNewSchool;