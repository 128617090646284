import React, {useEffect, useState} from 'react';
import '../../assets/styles/App.scss';
import { makeStyles, Paper, Grid, Typography, List, ListItem, ListItemIcon,  Button, Dialog, DialogTitle, DialogContent, DialogActions,Tooltip,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import {
  Link,useHistory
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faStickyNote, faComment } from '@fortawesome/free-solid-svg-icons';
import {SOCKET_API} from "../../api/index";
import Stomp from 'stompjs';
import * as moment from 'moment';
import {useStyles} from '../../assets/styles/materialStyles';
import {useStateValue} from '../../store/StateProvider';
import { useTranslation } from "react-i18next";
import Iframe from 'react-iframe';
import ReactPlayer from 'react-player';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


const DashboardWhileYouWereOut = ({unreadProfessorPosts, unreadNotes, unreadGroupChats, unreadUserChats}) => {
  const classes = useStyles();
  const [{user}] = useStateValue();
  const { t } = useTranslation();
  const history = useHistory();
  const [iframeOpen, setiframeOpen] = useState(false);
  const [noteId,setNoteId] = useState();
  const [{chatEventsCount}, dispatchChatEventsCount] = useStateValue();
  const [{shareNoteEventsCount}, dispatchShareNoteEventsCount] = useStateValue();
  const [{addPostEventsCount}, dispatchAddPostEventsCount] = useStateValue();



  
  const windowOpen = (id) => {
    setNoteId(id)
    setiframeOpen(true);
   
  };

  const windowClose = () => {
    setiframeOpen(false);
  };

  const [{chatWithParam}, dispatchSetChatWithParam] = useStateValue();
  const [{singleChatLoader}, dispatchSetChatsingleChatLoader] = useStateValue();

 const redirectToNotes = () =>{
   history.push("/notes");
 }



  const gotoSpecificChat = (id=null) =>{
    
    dispatchSetChatWithParam
    ({
      type: 'CHAT_WITH_PARAM', 
      chatWithParam
      : id
    });
    
    dispatchSetChatsingleChatLoader
    ({
      type: 'SINGLE_CHAT_LOADER', 
      singleChatLoader
      : true
    });
    
    history.push('/chats');
  }

  useEffect(()=>{
    if((unreadGroupChats && unreadGroupChats.length>0) || (unreadUserChats && unreadUserChats.length>0)){
      dispatchChatEventsCount(
        {
          type: 'CHAT_EVENTS_COUNT',
          count: chatEventsCount+1
        });
    }
    if(unreadNotes && unreadNotes.length>0){
      dispatchShareNoteEventsCount(
        {
          type: 'SHARE_NOTE_EVENTS_COUNT',
          count: shareNoteEventsCount+1
        });
    }
    if(unreadProfessorPosts && unreadProfessorPosts.length>0){
      dispatchAddPostEventsCount(
        {
          type: 'ADD_POST_EVENTS_COUNT',
          count: addPostEventsCount+1
        });
    }
  }, []);
  return (
    <>
    <Paper className={classes.paper}>
      <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        <Card>
          <div className="header">
            <Typography variant="h6" className={classes.bigFont}>
              {t('HOME_SD_WHILE_YOU_WERE_OUT')}
            </Typography>
          </div>
          <List className="iconAllign">
            {unreadProfessorPosts.length>0 &&
            <ListItem divider className={classes.iconList}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faGraduationCap} className={classes.listIcon}/>
              </ListItemIcon>
              <div className="upcomingContainer">
                <div className="upcomingInnerHeading">{t('HOME_SD_PROFESSOR_POSTS')}</div>
                <ul>
                {unreadProfessorPosts.map((unreadProfessorPost, i)=>(
                  <li key={i}>
                    <div className="upcomingInnerHeadingLight">
                      {unreadProfessorPost.text}
                    </div>
                    <div>
                      {t('HOME_POSTED_BY')+" "+t('PROFESSOR')} <strong>{unreadProfessorPost.creator.firstName} {unreadProfessorPost.creator.lastName}</strong> {t('ON')} {moment(unreadProfessorPost.createdDate).format(t('DATE_POSTED'))}
                    </div>
                  </li>
                ))}
                </ul>
              </div>
            </ListItem>
            }
            {unreadNotes.length>0 &&
            <ListItem divider className={classes.iconList}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faStickyNote} className={classes.listIcon}/>
              </ListItemIcon>
              <div className="upcomingContainer">
                <div className="upcomingInnerHeading">{t('HOME_SD_NOTES')}</div>
                <ul>
                {unreadNotes.map((unreadNote, i)=>(
                  <li key={i}>
                   
                     <div className="upcomingInnerHeadingLight">
                     {
                   (unreadNote.type == 'DOCUMENT' && unreadNote.fileThumbnailPath) &&
                   <Link onClick={()=>windowOpen(unreadNote.id)} >{unreadNote.noteName}</Link>
                    //  <Link to={"/notes/view-note/"+unreadNote.id}>{unreadNote.noteName}</Link>
                   }
                   {
                     (unreadNote.type == 'VIDEO' && unreadNote.fileUrl) &&
                     <> <div className="note-heading">{unreadNote.noteName}</div>
                     <Iframe url={unreadNote.fileUrl} width="100%" height="auto" className="classBoardVideo" /></>
                     
                   }
                   {
                     (unreadNote.type == 'VIDEO' && !unreadNote.fileUrl) &&
                     <><div className="note-heading">{unreadNote.noteName}</div>
                     <ReactPlayer url={unreadNote.filePath} controls={true} width="100%" height="auto" className="classBoardVideo"/></>
                     
                   }
                    </div>
                    <div>
                      {t('HOME_POSTED_BY')+" "+t('PROFESSOR')} <strong>{unreadNote.creator.firstName} {unreadNote.creator.lastName}</strong> {t('ON')} {moment(unreadNote.creationDate).format(t('DATE_POSTED'))}
                    </div>
                  </li>
                ))}
                </ul>
                {unreadNotes.length>2 &&
                <Button className="mt-2 mb-2" size="small" variant="contained" color="primary" onClick={redirectToNotes}>
                  {t('HOME_SD_VIEW_ALL')}
                </Button>
                }
              </div>
            </ListItem>
            }
            {(unreadGroupChats.length>0 || unreadUserChats.length>0) &&
            <ListItem className={classes.iconList}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faComment} className={classes.listIcon}/>
              </ListItemIcon>
              <div className="upcomingContainer">
                {(unreadGroupChats.length>0) &&
                <>
                <div className="upcomingInnerHeading">{t('HOME_SD_UNREAD_GROUP_CONVERSATIONS')}</div>
                  <ul>
                    <li className="innerList">
                    {unreadGroupChats.map((groupChat, i)=>(
                      <ul key={i}>
                        <li>
                          <div className="upcomingInnerHeadingLight">
                            <Link to="#" onClick={()=>gotoSpecificChat(groupChat.id)}>{groupChat.name} ({groupChat.countNewMessages})</Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                    </li>
                  </ul>
                </>
                }
                {(unreadUserChats.length>0) &&
                <>
                <div className="upcomingInnerHeading">{t('HOME_SD_UNREAD_CHATS')}</div>
                  <ul>
                    <li className="innerList">
                    {unreadUserChats.map((userChat, i)=>(
                      <ul key={i}>
                        <li>
                          <div className="upcomingInnerHeadingLight">
                            <Link to="#" onClick={()=>gotoSpecificChat(userChat.id)}>{userChat.firstName + " " + userChat.lastName} ({userChat.userChatDataOutPayload.countNewMessages})</Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                    </li>
                  </ul>
                  </>
                  }
                  <div>
                  {(unreadGroupChats.length>2 || unreadUserChats.length>2) &&
                  <Button onClick={()=>gotoSpecificChat()} className="mt-2 mb-2" size="small" variant="contained" color="primary">
                    {t('HOME_SD_VIEW_ALL')}
                  </Button>
                  }
                  </div>
                </div>
            </ListItem>
            }
          </List>
        </Card>
      </Grid>
    </Grid>
  </Paper>
  <Dialog
        fullScreen 
                    className="forWidth"
                    contentStyle={{
                      width: "80%",
                      maxWidth: "none",
                    }}
                    onClose={windowClose}
                    open={iframeOpen}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                  >
                     
                     <DialogActions className="draggable">
                    <Tooltip title={t('CLOSE_NOTE')}  arrow>
                    <IconButton className={"MyCustomButton"} onClick={()=>windowClose()}>
                      <CloseIcon />
                    </IconButton>
                    </Tooltip>
                  </DialogActions>
                  <DialogActions className="actionIframe">
                
                  </DialogActions>
                    <DialogContent className="DialogIframe">
                      <Iframe
                        url={
                          "https://rc.jot.academy/flowpaper/#/view-note/" +
                          noteId +
                          "/" +
                          user.token
                        }
                        width="100%"
                        height="520px"
                        id="myId"
                        className="iFrameDesign"
                        display="initial"
                        position="relative"
                      />
                    </DialogContent>
                  </Dialog>
  </>
  );
}

export default DashboardWhileYouWereOut;