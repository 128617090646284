import React from 'react';
import {Grid, Paper, Box, CircularProgress,Dialog} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay'
import {useStateValue} from '../../store/StateProvider';

export default function Loader({ active }) {
    const [{loading}] = useStateValue();
    return (
    <Dialog open={loading}>
        <Paper style={{padding: 10}}>
            <CircularProgress />
        </Paper>
    </Dialog>
    )
  }


{/* <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255, 0.5)"}}>
    <LoadingOverlay
        active={loading}
        spinner={<CircularProgress />}
    >
    </LoadingOverlay>
</div> */}